import {stringToDate} from '../functions/Basic';

// Clase del objeto SysAdmin.
class DashboardDay {
    // Constructor del objeto SysAdmin.
    constructor(props) {
        this.type = "DdD";
        this.date = props.date;
        this.value = props.value;
    }

    areaChartLabel = () =>{
        return this.date;
    };

    areaChartData = () =>{
        return {
            x: stringToDate(this.date),
            y: this.value
        };
    };
}

export default DashboardDay;