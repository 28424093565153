import React, {Component} from 'react';

// Crea un componente footer.
class Footer extends Component {
    // Dibuja el footer.
    render() {
        return (
            <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span id="footer-copyright"/>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;