// Clase del objeto Globals.
class Globals {
    // Constructor del objeto Globals.
    constructor() {
        this.MIN_TIMEOUT = 2000;
        this.MAX_TIMEOUT = 16000;
        this.ASYNC_DELAY = 750;
        this.MODAL_SHOW_DELAY = 250;
    }

    // Devuelve el tiempo mínimo de espera en peticiones de JobAsync.
    getMinTimeout = () => {
        return this.MIN_TIMEOUT;
    }

    // Devuelve el tiempo máximo de espera en peticiones de JobAsync.
    getMaxTimeout = () => {
        return this.MAX_TIMEOUT;
    }

    // Devuelve el tiempo de espera en los typeahead async.
    getAsyncDelay = () => {
        return this.ASYNC_DELAY;
    }

    // Devuelve el tiempo de espera para escribir al mostrar un modal.
    getModalShowDelay = () => {
        return this.MODAL_SHOW_DELAY;
    }
}

export default Globals;