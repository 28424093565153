import React, {Component} from "react";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import AsyncTypeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import CustomButton from "../../component/CustomButton";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import {
    deleteTPCI,
    getCompanyNoPag, getCropListFilter, getMeasure,
    getPhytoTPCI, getPhytoTPCIFiltered, getProblemListFilter,
    getSubstanceListFilter, getTypePhytosanitary, getTypeProtection, updateTPCI, updateTypephytosanitary,
} from "../../../logic/functions/ServerPetitions";
import TypePhytosanitary from "../../../logic/objects/TypePhytosanitary";
import Input from "../../component/Input";
import Flatpickr from "react-flatpickr";
// noinspection NpmUsedModulesInstalled
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import Company from "../../../logic/objects/Company";
import Substance from "../../../logic/objects/Substance";
import MultipleValueTextInput from 'react-multivalue-text-input';
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ReactAsyncTable from "react-async-table";
import TypeCrop from "../../../logic/objects/TypeCrop";
import Infection from "../../../logic/objects/Infection";
import Measure from "../../../logic/objects/Measure";
import TypePhytosanitaryCropInfection from "../../../logic/objects/TypePhytosanitaryCropInfection";
import TypeProtection from "../../../logic/objects/TypeProtection";

class PhytosanitaryManagement extends Component {
    // Constructor de clase PhytosanitaryManagement.
    constructor(props){
        super(props);
        this.ciHeader = [{
            text: this.props.strings.tableId,
            dataField: 'id',
            columnHeaderClass: 'd-none',
            columnDataClass: 'd-none'
        },{
            text: this.props.strings.phytosanitaryTableCrop,
            dataField: 'crop'
        },{
            text: this.props.strings.phytosanitaryTableProblem,
            dataField: 'problem'
        },{
            text: this.props.strings.phytosanitaryTableTP,
            dataField: 'tp'
        },{
            text: this.props.strings.phytosanitaryTableMND,
            dataField: 'mnd'
        },{
            text: this.props.strings.phytosanitaryTableMXD,
            dataField: 'mxd'
        },{
            text: this.props.strings.phytosanitaryTableMD,
            dataField: 'md'
        },{
            text: this.props.strings.phytosanitaryTableMNS,
            dataField: 'mns'
        },{
            text: this.props.strings.phytosanitaryTableMXS,
            dataField: 'mxs'
        },{
            text: this.props.strings.phytosanitaryTableMS,
            dataField: 'ms'
        },{
            text: this.props.strings.phytosanitaryTableS,
            dataField: 's'
        }];
        this.listaFitos = [];
        this.subsList = [];
        this.compList = [];
        this.cropList = [];
        this.problemList = [];
        this.delayedPet = undefined;
        this.state = {isPhytoLoading: false, phytoOptions: [], selectedPhyto: "", isCompLoading: false, compOptions: [],
            isSubsLoading: false, subsOptions: [], phytoDateE: undefined, phytoDateC: undefined, phytoDateL: undefined,
            phytoMix: 0, phytoNames: [], showEditPhytoCI: false,  ciTableBody: [], maxCIAmount: 0, page: 0, page_size: 50,
            isCILoading: false, allCrops: [], allProblem: [], allDoseMeasures: [], allSoupMeasures: [], allProtection: [],
            paginatedTPCI: {}, isCropLoading: false, isProblemLoading: false, cropOptions: [], problemOptions: [],
            selectedCrop: "", selectedProblem: "", showEditPhytoCIFields: false, editedCI: [], isCICropLoading: false,
            cropCIOptions: [], selectedCICrop: "", isCIProblemLoading: false, problemCIOptions: [], selectedCIProblem: "",
            showModalConfirmation: false, isCreation: false};

        this.headerAction = ({onHeaderAction}) => (
            <div className="d-inline">
                <span>
                    <CustomButton additionalClass={"btn-primary btn-icon-split mr-2"} parentFunction={this.addPhytoCI}>
                                    <span className="icon">
                                        <i className="fas fa-plus"/>
                                    </span>
                        <span className="text">{this.props.strings.phytosanitaryAddCI}</span>
                    </CustomButton>
                </span>
                <span>
                    <button type={"button"} className={"btn btn-primary mr-2"} data-html="true" data-toggle="tooltip" title="Editar" onClick={() => onHeaderAction('EDIT')}>
                        <i className="fas fa-pencil-alt text-white" />
                    </button>
                </span>
            </div>
        );
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.phytosanitaryTitle;
        Promise.all([
            this.getTypecropsPromise(),
            this.getProblemsPromise(),
            this.getMeasurePromise(),
            this.getProtectionPromise()
        ]).then(() => {
            endPreLoad();
        });
    }

    // Dibuja el contenido de la página PhytosanitaryManagement.
    render = () => {
        let multiInput;
        if (this.state.selectedPhyto !== "") {
            multiInput = <MultipleValueTextInput className="form-control"
                                 label={this.props.strings.phytosanitaryNames}
                                 name="phytoNames"
                                 placeholder={this.props.strings.phytosanitaryNames}
                                 values={this.state.selectedPhyto.getNames()}
                                 onItemAdded={this.addNameToPhyto}
                                 onItemDeleted={this.removeNameToPhyto}/>
        }

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.phytosanitaryManagementPageTitle}/>

                <div className="row">
                    <div className="col-lg-12">
                        {/* Collapsible de editar fitosanitario */}
                        <Collapsible triggerId={"collapsePhytoCardTrigger"} collapseId={"collapsePhytoCard"} cardTitle={this.props.strings.phytosanitaryEditInfo} >
                            <div id="phytoFormLoader" className="form-loader d-none"/>
                            <form id="phytoForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.phytosanitaryTablePhyto}</FormLabel>
                                            <AsyncTypeahead id="phyto-input" placeholder={this.props.strings.phytosanitarySearchName} useCache={false} isLoading={this.state.isPhytoLoading}
                                                            ref={(ref) => this._typeaheadPhyto = ref}
                                                            minLength={3} onSearch={this.getAutocompletePhyto} onChange={this.setPhytoFormFill} onInputChange={this.resetPhytoFields}
                                                            delay={this.props.globals.getAsyncDelay()} options={this.state.phytoOptions} labelKey="customId"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{option.code + " - " + option.name}</span>
                                                            )} />
                                        </FormGroup>
                                        <div id="phytoGeneralInfo" className="d-none">
                                            <div className="row">
                                                <FormGroup className="col-4">
                                                    <FormLabel>{this.props.strings.phytosanitaryTableCode}</FormLabel>
                                                    <Input id={"phytoCode"} type={"text"} ph={this.props.strings.phytosanitaryTableCode} />
                                                </FormGroup>
                                                <FormGroup className="col-2">
                                                    <FormLabel>{this.props.strings.phytosanitaryTablePDF}</FormLabel>
                                                    <Input id={"phytoPDF"} type={"text"} ph={this.props.strings.phytosanitaryTablePDF} />
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <FormLabel>{this.props.strings.tableName}</FormLabel>
                                                    <Input id={"phytoName"} type={"text"} ph={this.props.strings.tableName} disabled={true} />
                                                </FormGroup>
                                            </div>
                                            <div className="row">
                                                <FormGroup className="col-6">
                                                    <label>{this.props.strings.phytosanitaryFilterComp}</label>
                                                    <AsyncTypeahead id="phyto-comp" placeholder={this.props.strings.phytosanitaryFilterComp} useCache={false} onInputChange={this.resetCompOptions}
                                                                    ref={(ref) => this._typeaheadComp = ref}
                                                                    inputProps={{
                                                                        id: 'phytoComp',
                                                                    }}
                                                                    isLoading={this.state.isCompLoading} minLength={3} onSearch={this.getAutocompleteComp} onChange={this.setCompFormFill}
                                                                    delay={this.props.globals.getAsyncDelay()} options={this.state.compOptions} labelKey="name"
                                                                    renderMenuItemChildren={(option) => (
                                                                        <span>{option.name}</span>
                                                                    )} />
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <label>{this.props.strings.phytosanitaryFilterSubs}</label>
                                                    <AsyncTypeahead id="phyto-subs" placeholder={this.props.strings.phytosanitaryFilterSubs} useCache={false} onInputChange={this.resetSubsOptions}
                                                                    ref={(ref) => this._typeaheadSubs = ref}
                                                                    inputProps={{
                                                                        id: 'phytoSubs',
                                                                    }}
                                                                    isLoading={this.state.isSubsLoading} minLength={3} onSearch={this.getAutocompleteSubs} onChange={this.setSubsFormFill}
                                                                    delay={this.props.globals.getAsyncDelay()} options={this.state.subsOptions} labelKey="name"
                                                                    renderMenuItemChildren={(option) => (
                                                                        <span>{option.name}</span>
                                                                    )} />
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <div className="row pl-3">
                                                        <label>{this.props.strings.phytosanitaryExpirationDate}</label>
                                                        <Flatpickr id="phytoDateE" className="form-control col-10" placeholder={this.props.strings.selectDatePh}
                                                                   value={this.state.phytoDateE} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                                   onChange={date => {
                                                                       this.setState({phytoDateE: date});
                                                                   }} />
                                                        <div className={"col-1 p-0"}>
                                                            <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearPhytoDateE}>
                                                                <i className="fas fa-trash"/>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <div className="row pl-3">
                                                        <label>{this.props.strings.phytosanitaryCancelDate}</label>
                                                        <Flatpickr id="phytoDateC" className="form-control col-10" placeholder={this.props.strings.selectDatePh}
                                                                   value={this.state.phytoDateC} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                                   onChange={date => {
                                                                       this.setState({phytoDateC: date});
                                                                   }} />
                                                        <div className={"col-1 p-0"}>
                                                            <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearPhytoDateC}>
                                                                <i className="fas fa-trash"/>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <div className="row pl-3">
                                                        <label>{this.props.strings.phytosanitaryLimitDate}</label>
                                                        <Flatpickr id="phytoDateL" className="form-control col-10" placeholder={this.props.strings.selectDatePh}
                                                                   value={this.state.phytoDateL} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                                   onChange={date => {
                                                                       this.setState({phytoDateL: date});
                                                                   }} />
                                                        <div className={"col-1 p-0"}>
                                                            <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearPhytoDateL}>
                                                                <i className="fas fa-trash"/>
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <FormGroup className="col-6">
                                                    <label>{this.props.strings.phytosanitaryMixable}</label>
                                                    <select id="phytoMixable" className="form-control" onChange={this.changePhytoMix}>
                                                        <option value={0}>No</option>
                                                        <option value={1}>Sí</option>
                                                    </select>
                                                </FormGroup>
                                                <FormGroup className="col-12 mb-0">
                                                    {multiInput}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"phytoBtn"} className="d-none col-lg-4 col-md-12 flex-column-reverse mb-2">
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-end">
                                                <CustomButton additionalClass={"btn-primary btn-icon-split ml-auto mr-2"} parentFunction={this.savePhytoChanges}>
                                                    <span className="icon">
                                                        <i className="fas fa-save"/>
                                                    </span>
                                                    <span className="text">{this.props.strings.saveButton}</span>
                                                </CustomButton>
                                                <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.showEditPhytoCIModal}>
                                                    <span className="icon">
                                                        <i className="fas fa-pencil-alt"/>
                                                    </span>
                                                    <span className="text">{this.props.strings.phytosanitaryEditCIButton}</span>
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                {/* Modal Edit Phyto CI */}
                <Modal show={this.state.showEditPhytoCI} backdrop="static" onHide={this.hideEditPhytoCIModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {this.props.strings.phytosanitaryEditCITitle + (this.state.selectedPhyto !== "" ? " " + this.state.selectedPhyto.getCode() + " - " + this.state.selectedPhyto.getName() : "")}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row m-0 mb-3">
                            <FormGroup className="col-6 mb-0">
                                <AsyncTypeahead id="filter-crop" placeholder={this.props.strings.phytosanitaryFilterCrop}
                                                inputProps={{
                                                    id: 'filter-crop-input',
                                                }}
                                                isLoading={this.state.isCropLoading} minLength={3} onSearch={this.getAutocompleteCrop} onChange={this.setCropFormFill}
                                                delay={this.props.globals.getAsyncDelay()} onInputChange={this.inputChangeCrop} options={this.state.cropOptions} labelKey="name"
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.name}</span>
                                                )} />
                            </FormGroup>
                            <FormGroup className="col-6 mb-0">
                                <AsyncTypeahead id="filter-problem" placeholder={this.props.strings.phytosanitaryFilterProblem}
                                                inputProps={{
                                                    id: 'filter-problem-input',
                                                }}
                                                isLoading={this.state.isProblemLoading} minLength={3} onSearch={this.getAutocompleteProblem} onChange={this.setProblemFormFill}
                                                delay={this.props.globals.getAsyncDelay()} onInputChange={this.inputChangeProblem} options={this.state.problemOptions} labelKey="name"
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.name}</span>
                                                )} />
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <ReactAsyncTable keyField={"id"} columns={this.ciHeader} items={this.state.ciTableBody} isLoading={this.state.isCILoading}
                                             loader={this.tableLoader} onChangePage={this.onChangePage} delay={this.props.globals.getAsyncDelay()}
                                             currentPage={this.state.page+1} itemsPerPage={this.state.page_size} totalItems={this.state.maxCIAmount}
                                             onMultipleDelete={this.onMultipleDeletePhyto} headerActions={this.headerAction} onHeaderAction={this.onHeaderActionPhyto} options={{
                                                 multipleSelect: true,
                                                 searchBox: false
                                             }}
                                             bootstrapCheckbox={true} translations={{
                                                 noDataText: this.props.strings.phytosanitaryCITableNotFound,
                                                 requestFailedText: this.props.strings.tableSearchError,
                                                 paginationFirst: this.props.strings.tablePaginationFirst,
                                                 paginationLast: this.props.strings.tablePaginationLast,
                                                 actionsColumnTitle: "",
                                             }}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideEditPhytoCIModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Edit Phyto CI */}

                {/* Modal Edit Phyto CI fields */}
                <Modal show={this.state.showEditPhytoCIFields} backdrop="static" onHide={this.hideEditPhytoCIFieldsModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {this.props.strings.phytosanitaryEditCITitle + (this.state.selectedPhyto !== "" ? " " + this.state.selectedPhyto.getCode() + " - " + this.state.selectedPhyto.getName() : "")}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div id="ci_crop_problem_div" className="row m-0">
                            <div id={"ci_cb_typecrops_div"} className={"d-none col-6"} >
                                <FormLabel>{this.props.strings.phytosanitaryFilterCrop}</FormLabel>
                                <FormGroup id={"ci_cb_typecrops"} className={"ul-scrollable"} />
                            </div>
                            <FormGroup id={"ci_crop_div"} className="col-6">
                                <FormLabel>{this.props.strings.phytosanitaryFilterCrop}</FormLabel>
                                <AsyncTypeahead id="ci_crop" placeholder={this.props.strings.phytosanitaryFilterCrop} useCache={false} onInputChange={this.resetCropCIOptions}
                                            ref={(ref) => this._typeaheadCICrop = ref}
                                                inputProps={{
                                                id: 'ci_crop_input',
                                            }}
                                            isLoading={this.state.isCICropLoading} minLength={3} onSearch={this.getAutocompleteCICrop} onChange={this.setCICropFormFill}
                                            delay={this.props.globals.getAsyncDelay()} options={this.state.cropCIOptions} labelKey="name"
                                            renderMenuItemChildren={(option) => (
                                                <span>{option.name}</span>
                                            )} />
                            </FormGroup>
                            <FormGroup className="col-6">
                                <FormLabel>{this.props.strings.phytosanitaryFilterProblem}</FormLabel>
                                <AsyncTypeahead id="ci_problem" placeholder={this.props.strings.phytosanitaryFilterProblem} useCache={false} onInputChange={this.resetProblemCIOptions}
                                                ref={(ref) => this._typeaheadCIProblem = ref}
                                                inputProps={{
                                                    id: 'ci_problem_input',
                                                }}
                                                isLoading={this.state.isCIProblemLoading} minLength={3} onSearch={this.getAutocompleteCIProblem} onChange={this.setCIProblemFormFill}
                                                delay={this.props.globals.getAsyncDelay()} options={this.state.problemCIOptions} labelKey="name"
                                                renderMenuItemChildren={(option) => (
                                                    <span>{option.name}</span>
                                                )} />
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMND}</FormLabel>
                                <Input id={"ci_mnd"} type={"number"} ph={this.props.strings.phytosanitaryTableMND} min={0} />
                            </FormGroup>
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMXD}</FormLabel>
                                <Input id={"ci_mxd"} type={"number"} ph={this.props.strings.phytosanitaryTableMXD} min={0} />
                            </FormGroup>
                            <FormGroup className="col-4">
                                <FormLabel>{this.props.strings.phytosanitaryTableMD}</FormLabel>
                                <select id="ci_md" className="form-control w-100"></select>
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <FormGroup className={"col-3"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMNA}</FormLabel>
                                <Input id={"ci_mna"} type={"number"} ph={this.props.strings.phytosanitaryTableMNA} min={0} pattern={"[0-9]+"} />
                            </FormGroup>
                            <FormGroup className={"col-3"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMXA}</FormLabel>
                                <Input id={"ci_mxa"} type={"number"} ph={this.props.strings.phytosanitaryTableMXA} min={0} pattern={"[0-9]+"} />
                            </FormGroup>
                            <FormGroup className={"col-3"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMNI}</FormLabel>
                                <Input id={"ci_mni"} type={"number"} ph={this.props.strings.phytosanitaryTableMNI} min={0} pattern={"[0-9]+"} />
                            </FormGroup>
                            <FormGroup className={"col-3"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMXI}</FormLabel>
                                <Input id={"ci_mxi"} type={"number"} ph={this.props.strings.phytosanitaryTableMXI} min={0} pattern={"[0-9]+"} />
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMNS}</FormLabel>
                                <Input id={"ci_mns"} type={"number"} ph={this.props.strings.phytosanitaryTableMNS} min={0} pattern={"[0-9]+.?[0-9]+"} />
                            </FormGroup>
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMXS}</FormLabel>
                                <Input id={"ci_mxs"} type={"number"} ph={this.props.strings.phytosanitaryTableMXS} min={0} pattern={"[0-9]+.?[0-9]+"} />
                            </FormGroup>
                            <FormGroup className="col-4">
                                <FormLabel>{this.props.strings.phytosanitaryTableMS}</FormLabel>
                                <select id="ci_ms" className="form-control w-100"></select>
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMNDA}</FormLabel>
                                <Input id={"ci_mnda"} type={"number"} ph={this.props.strings.phytosanitaryTableMNDA} min={0} pattern={"[0-9]+.?[0-9]+"} />
                            </FormGroup>
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableMXDA}</FormLabel>
                                <Input id={"ci_mxda"} type={"number"} ph={this.props.strings.phytosanitaryTableMXDA} min={0} pattern={"[0-9]+.?[0-9]+"} />
                            </FormGroup>
                            <FormGroup className="col-4">
                                <FormLabel>{this.props.strings.phytosanitaryTableMDA}</FormLabel>
                                <select id="ci_mda" className="form-control w-100"></select>
                            </FormGroup>
                        </div>
                        <div className="row m-0">
                            <FormGroup className={"col-4"}>
                                <FormLabel>{this.props.strings.phytosanitaryTableS}</FormLabel>
                                <Input id={"ci_s"} type={"number"} ph={this.props.strings.phytosanitaryTableS} min={0} pattern={"[0-9]+"} />
                            </FormGroup>
                            <FormGroup id="ci_tp_form" className="col-6">
                                <FormLabel>{this.props.strings.phytosanitaryTableTP}</FormLabel>
                                <select id="ci_tp" className="form-control w-100"></select>
                            </FormGroup>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.saveButton} parentFunction={this.saveCIChanges}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Edit Phyto CI fields */}

                {/* Modal confirmación */}
                <Modal show={this.state.showModalConfirmation} backdrop="static" onHide={this.resetConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal confirmación */}
            </div>
        );
    }

    // Realiza la petición de fitosanitarios con el texto introducido en el input de búsqueda de fitosanitarios.
    getAutocompletePhyto = (query) => {
        getTypePhytosanitary(query).then(async phytoList => {
            this.setState({isPhytoLoading: true});
            const phytoListJson = await phytoList.json();

            if (!phytoList.ok){
                const error = phytoList.status;
                return Promise.reject(error);
            }

            if (phytoListJson.hasOwnProperty('data') && phytoListJson.data.length > 0){
                let options = [];
                this.listaFitos = [];
                for (let i in phytoListJson.data){
                    const phyto = new TypePhytosanitary(phytoListJson.data[i]);
                    this.listaFitos.push(phyto);
                    options.push({customId: phyto.getCode() + " - " + phyto.getName(), id: phyto.getId(),
                        code: phyto.getCode(), name: phyto.getName()});
                }
                this.setState({phytoOptions: options});
            } else {
                refreshIfNeeded(phytoListJson, null, this.props.showInfoModal);
            }
            this.setState({isPhytoLoading: false});
        }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.setState({isPhytoLoading: false});
            }
        );
    }

    // Establece el fitosanitario a editar o eliminar.
    setPhytoFormFill = (items) => {
        for (let i in this.listaFitos){
            if (items.length > 0) {
                if (items[0].id === this.listaFitos[i].id && items[0].name === this.listaFitos[i].name) {
                    let newPhyto = new TypePhytosanitary(this.listaFitos[i]);
                    this.setState({selectedPhyto: newPhyto, phytoDateE: newPhyto.getEDate(), phytoDateC: newPhyto.getCDate(),
                    phytoDateL: newPhyto.getLDate(), phytoMix: newPhyto.getMixable(), phytoNames: newPhyto.getNames()}, () => {
                        document.getElementById("phytoBtn").classList.remove("d-none");
                        document.getElementById("phytoBtn").classList.add("d-flex");
                        document.getElementById("phytoCode").value = this.state.selectedPhyto.getCode();
                        document.getElementById("phytoName").value = this.state.selectedPhyto.getName();
                        document.getElementById("phytoPDF").value = this.state.selectedPhyto.getIDPDF();
                        document.getElementById("phytoComp").value = this.state.selectedPhyto.getCompName();
                        this._typeaheadComp.state.selected = [{id: newPhyto.getComp().getId(), name: newPhyto.getComp().getName()}];
                        document.getElementById("phytoSubs").value = this.state.selectedPhyto.getSubsName();
                        this._typeaheadSubs.state.selected = [{id: newPhyto.getSubs().getId(), name: newPhyto.getSubs().getName()}];
                        document.getElementById("phytoGeneralInfo").classList.remove("d-none");
                        document.getElementById("phytoMixable").value = newPhyto.getMixable();
                    });
                    break;
                }
            }
        }
    }

    // Realiza la petición de casa comercial con el texto introducido en el input de casa comercial.
    getAutocompleteComp = (query) => {
        getCompanyNoPag(query).then(async compList => {
            this.setState({isCompLoading: true});
            const compListJson = await compList.json();

            if (!compList.ok){
                const error = compList.status;
                return Promise.reject(error);
            }

            if (compListJson.hasOwnProperty('data') && compListJson.data.length > 0){
                let options = [];
                this.compList = [];
                for (let i in compListJson.data){
                    const comp = new Company(compListJson.data[i]);
                    this.compList.push(comp);
                    options.push({id: comp.getId(), name: comp.getName()});
                }
                this.setState({compOptions: options});
            } else {
                refreshIfNeeded(compListJson, null, this.props.showInfoModal);
            }
            this.setState({isCompLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCompLoading: false});
        });
    }

    // Establece la casa comercial seleccionada.
    setCompFormFill = (items) => {
        for (let i in this.compList){
            if (items.length > 0) {
                if (items[0].id === this.compList[i].getId()) {
                    let auxPhyto = new TypePhytosanitary(this.state.selectedPhyto);
                    auxPhyto.setComp(this.compList[i]);
                    this.setState({selectedPhyto: auxPhyto});
                    break;
                }
            }
        }
    }

    // Resetea las opciones de la casa comercial.
    resetCompOptions = () => {
        this.setState({compOptions: []});
    }

    // Realiza la petición de materia activa con el texto introducido en el input de materia activa.
    getAutocompleteSubs = (query) => {
        getSubstanceListFilter(query).then(async subsList => {
            this.setState({isSubsLoading: true});
            const subsListJson = await subsList.json();

            if (!subsList.ok){
                const error = subsList.status;
                return Promise.reject(error);
            }

            if (subsListJson.hasOwnProperty('data') && subsListJson.data.length > 0){
                let options = [];
                this.subsList = [];
                for (let i in subsListJson.data){
                    const subs = new Substance(subsListJson.data[i]);
                    this.subsList.push(subs);
                    options.push({id: subs.getId(), name: subs.getName()});
                }
                this.setState({subsOptions: options});
            } else {
                refreshIfNeeded(subsListJson, null, this.props.showInfoModal);
            }
            this.setState({isSubsLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isSubsLoading: false});
        });
    }

    // Establece la materia activa seleccionada.
    setSubsFormFill = (items) => {
        for (let i in this.subsList){
            if (items.length > 0) {
                if (items[0].id === this.subsList[i].getId()) {
                    let auxPhyto = new TypePhytosanitary(this.state.selectedPhyto);
                    auxPhyto.setSubs(this.subsList[i]);
                    this.setState({selectedPhyto: auxPhyto});
                    break;
                }
            }
        }
    }

    // Resetea las opciones de materia activa.
    resetSubsOptions = () => {
        this.setState({subsOptions: []});
    }

    // Limpia la fecha de caducidad del tratamiento.
    clearPhytoDateE = () => {
        this.setState({phytoDateE: undefined});
    }

    // Limpia la fecha de cancelación del tratamiento.
    clearPhytoDateC = () => {
        this.setState({phytoDateC: undefined});
    }

    // Limpia la fecha límite del tratamiento.
    clearPhytoDateL = () => {
        this.setState({phytoDateL: undefined});
    }

    // Cambia el valor de mezclable del tratamiento.
    changePhytoMix = () => {
        this.setState({phytoMix: document.getElementById("phytoMixable").value});
    }

    // Añade un nombre a la lista de denominaciones comunes del tratamiento.
    addNameToPhyto = (item) => {
        if (!this.state.phytoNames.includes(item)){
            let auxNames = this.state.phytoNames;
            auxNames.push(item);
            this.setState({phytoNames: auxNames});
        }
    }

    // Elimina un nombre de la lista de denominaciones comunes del tratamiento.
    removeNameToPhyto = (item) => {
        if (this.state.phytoNames.includes(item)){
            let auxNames = this.state.phytoNames;
            for (let i in auxNames){
                if (auxNames[i] === item){
                    auxNames.splice(parseInt(i), 1);
                    break;
                }
            }
            this.setState({phytoNames: auxNames});
        }
    }

    // Resetea los campos del fitosanitario seleccionado.
    resetPhytoFields = () => {
        this.setState({selectedPhyto: "", phytoOptions:[]}, () => {
            document.getElementById("phytoBtn").classList.remove("d-flex");
            document.getElementById("phytoBtn").classList.add("d-none");
            document.getElementById("phytoCode").value = "";
            document.getElementById("phytoName").value = "";
            document.getElementById("phytoComp").value = "";
            this._typeaheadComp.state.selected = [];
            document.getElementById("phytoSubs").value = "";
            this._typeaheadSubs.state.selected = [];
            document.getElementById("phytoGeneralInfo").classList.add("d-none");
            document.getElementById("phytoMixable").value = 0;
        });
    }

    // Establece los campos en el tratamiento y lo devuelve.
    setPhytoFields = () => {
        let auxPhyto = new TypePhytosanitary(this.state.selectedPhyto);
        auxPhyto.setCode(document.getElementById("phytoCode").value);
        auxPhyto.setIDPDF(document.getElementById("phytoPDF").value);
        auxPhyto.setName(document.getElementById("phytoName").value);
        auxPhyto.setNames(this.state.phytoNames);
        if (!auxPhyto.getNames().includes(auxPhyto.getName())){
            auxPhyto.addName(auxPhyto.getName());
        }
        auxPhyto.setEDate(document.getElementById("phytoDateE").value + " 00:00:00");
        auxPhyto.setCDate(document.getElementById("phytoDateC").value + " 00:00:00");
        auxPhyto.setLDate(document.getElementById("phytoDateL").value + " 00:00:00");
        auxPhyto.setMixable(parseInt(document.getElementById("phytoMixable").value));

        return auxPhyto;
    }

    // Guarda los cambios realizados en la información del tratamiento.
    savePhytoChanges = () => {
        this.props.showLoaderModal(this.props.strings.updatingPhytoInfo);
        getPhytoTPCI(this.state.selectedPhyto.getId()).then(async phytoInfoList => {
            const phytoInfoJson = await phytoInfoList.json();

            if (!phytoInfoList.ok) {
                const error = phytoInfoList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (phytoInfoJson.hasOwnProperty('data')) {
                let auxTPCI = [];
                let auxCI;
                for (let i in phytoInfoJson.data){
                    auxCI = new TypePhytosanitaryCropInfection(phytoInfoJson.data[i]);
                    auxTPCI.push(auxCI);
                }

                let auxPhyto = this.setPhytoFields();
                auxPhyto.setCI(auxTPCI);

                let json = {};
                json.data = auxPhyto;
                updateTypephytosanitary(json).then(async asyncResponse => {
                    const asyncResponseJson = await asyncResponse.json();

                    if (asyncResponseJson.hasOwnProperty('data')) {
                        this.resetPhytoFields();
                        this._typeaheadPhyto.clear();
                        this.props.hideLoaderModal();
                        this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                    } else {
                        refreshIfNeeded(asyncResponseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                    }
                }).catch(error => {
                    this.props.hideLoaderModal();
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            } else {
                refreshIfNeeded(phytoInfoJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.hideLoaderModal();
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
        });
    }

    // Muestra el modal de editar CI del tratamiento.
    showEditPhytoCIModal = () => {
        if (this.state.selectedPhyto !== "") {
            this.props.showLoaderModal(this.props.strings.downloadingPhytoInfo);
            this.setState({isCILoading: true}, () => {
                getPhytoTPCI(this.state.selectedPhyto.getId()).then(async phytoInfoList => {
                    this.setState({maxCIAmount: parseInt(phytoInfoList.headers.get("content-range").split(" ")[2])});
                    const phytoInfoJson = await phytoInfoList.json();

                    if (!phytoInfoList.ok) {
                        const error = phytoInfoList.status;
                        window.location.href = '/';
                        return Promise.reject(error);
                    }

                    if (phytoInfoJson.hasOwnProperty('data')) {
                        let auxTPCI = [];
                        let auxCI;
                        for (let i in phytoInfoJson.data){
                            auxCI = new TypePhytosanitaryCropInfection(phytoInfoJson.data[i]);
                            auxTPCI.push(auxCI);
                        }

                        let auxPaginated = this.fillPaginatedTPCI(auxTPCI);
                        let auxPhyto = new TypePhytosanitary(this.state.selectedPhyto);
                        auxPhyto.setCI(auxTPCI);
                        this.setState({selectedPhyto: auxPhyto, paginatedTPCI: auxPaginated}, () => {
                            let tablebody = this.fillCITableContent();
                            this.setState({ciTableBody: tablebody, showEditPhytoCI: true, isCILoading: false});
                        });
                        this.props.hideInfoModal();
                    } else {
                        refreshIfNeeded(phytoInfoJson, this.props.hideLoaderModal, this.props.showInfoModal);
                    }
                    this.props.hideLoaderModal();
                }).catch(error => {
                    this.props.hideLoaderModal();
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Oculta el modal de editar CI del tratamiento.
    hideEditPhytoCIModal = () => {
        this.setState({showEditPhytoCI: false});
    }

    // Muestra el mensaje de cargando CI cuando se piden nuevos datos en la tabla de CI.
    tableLoader = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.phytosanitaryLoadingCI}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Devuelve la promise de la petición de tipos de cultivo.
    getTypecropsPromise = () => {
        if (this.state.allCrops.length <= 0) {
            return getCropListFilter().then(async cropList => {
                const cropListJson = await cropList.json();

                if (!cropList.ok) {
                    const error = cropList.status;
                    return Promise.reject(error);
                }

                if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0) {
                    let allCropList = [];
                    for (let i in cropListJson.data) {
                        allCropList.push(new TypeCrop(cropListJson.data[i]));
                    }
                    this.setState({allCrops: allCropList});
                } else {
                    refreshIfNeeded(cropListJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de los problemas fitosanitarios.
    getProblemsPromise = () => {
        if (this.state.allProblem.length <= 0) {
            return getProblemListFilter().then(async problemList => {
                const problemListJson = await problemList.json();

                if (!problemList.ok) {
                    const error = problemList.status;
                    return Promise.reject(error);
                }

                if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0) {
                    let problems = [];
                    for (let i in problemListJson.data) {
                        problems.push(new Infection(problemListJson.data[i]));
                    }
                    this.setState({allProblem: problems});
                } else {
                    refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de la petición de medidas.
    getMeasurePromise = () => {
        if (this.state.allDoseMeasures.length <= 0 || this.state.allSoupMeasures.length <= 0) {
            return getMeasure().then(async measureList => {
                const measureListJson = await measureList.json();

                if (!measureList.ok) {
                    const error = measureList.status;
                    return Promise.reject(error);
                }

                if (measureListJson.hasOwnProperty('data') && measureListJson.data.length > 0) {
                    let doseMeasures = [];
                    let soupMeasures = [];
                    for (let i in measureListJson.data) {
                        let measure = new Measure(measureListJson.data[i]);
                        switch (measure.getSubtype().getId()) {
                            case "1":
                                doseMeasures.push(measure);
                                break;
                            case "5":
                                soupMeasures.push(measure);
                                break;
                            default:
                                break;
                        }
                    }
                    this.setState({allDoseMeasures: doseMeasures, allSoupMeasures: soupMeasures});
                } else {
                    refreshIfNeeded(measureListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de los tipos de protección.
    getProtectionPromise = () => {
        if (this.state.allProtection.length <= 0) {
            return getTypeProtection().then(async protectionList => {
                const protectionListJson = await protectionList.json();

                if (!protectionList.ok) {
                    const error = protectionList.status;
                    return Promise.reject(error);
                }

                if (protectionListJson.hasOwnProperty('data') && protectionListJson.data.length > 0) {
                    let protection = [];
                    for (let i in protectionListJson.data) {
                        protection.push(new TypeProtection(protectionListJson.data[i]));
                    }
                    this.setState({allProtection: protection});
                } else {
                    refreshIfNeeded(protectionListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Rellena el objeto con las páginas de los TPCI del fitosanitario.
    fillPaginatedTPCI = (auxTPCI) => {
        let auxPaginated = {}
        for (let i in auxTPCI){
            let page = Math.floor(parseInt(i) / (this.state.page_size));
            if (auxPaginated[page] === undefined){
                auxPaginated[page] = [];
            }
            auxPaginated[page].push(auxTPCI[i]);
        }
        return auxPaginated;
    }

    // Rellena la tabla de CI con los valores de la página actual.
    fillCITableContent = () => {
        let tablebody = [];
        let auxCI, crop, problem, protec, md, ms;

        for ( let i in this.state.paginatedTPCI[this.state.page]) {
            auxCI = new TypePhytosanitaryCropInfection(this.state.paginatedTPCI[this.state.page][i]);
            for (let j in this.state.allCrops) {
                if (auxCI.getIdTc() === this.state.allCrops[j].getId()) {
                    crop = new TypeCrop(this.state.allCrops[j]);
                    break;
                }
            }
            for (let j in this.state.allProblem) {
                if (auxCI.getIdI() === this.state.allProblem[j].getId()) {
                    problem = new Infection(this.state.allProblem[j]);
                    break;
                }
            }
            for (let j in this.state.allProtection) {
                if (auxCI.getTp() === this.state.allProtection[j].getId()) {
                    protec = new TypeProtection(this.state.allProtection[j]);
                    break;
                }
            }
            for (let j in this.state.allDoseMeasures) {
                if (auxCI.getMD() === this.state.allDoseMeasures[j].getId()) {
                    md = new Measure(this.state.allDoseMeasures[j]);
                    break;
                }
            }
            for (let j in this.state.allSoupMeasures) {
                if (auxCI.getMS() === this.state.allSoupMeasures[j].getId()) {
                    ms = new Measure(this.state.allSoupMeasures[j]);
                    break;
                }
            }
            tablebody.push({id: auxCI.getId(), crop: (crop !== undefined ? crop.getName() : ""), problem: (problem !== undefined ? problem.getName() : ""), mnd: auxCI.getMND(),
                mxd: auxCI.getMXD(), md: md.getSymbol(), mns: auxCI.getMNS(), mxs: auxCI.getMXS(), ms: ms.getSymbol(),
                s: auxCI.getS(), tp: (protec !== undefined ? protec.getName() : "")});
        }

        return tablebody;
    }

    // Realiza el cambio de página de la tabla.
    onChangePage = (page) => {
        this.setState({ciTableBody: [], page: page-1}, () => {
            let tablebody = this.fillCITableContent();
            this.setState({ciTableBody: tablebody});
        });
    }

    // Realiza la petición de cultivos con el texto introducido en el input de filtros de cultivo.
    getAutocompleteCrop = (query) => {
        getCropListFilter(query).then(async cropList => {
            this.setState({isCropLoading: true});
            const cropListJson = await cropList.json();

            if (!cropList.ok){
                const error = cropList.status;
                return Promise.reject(error);
            }

            if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0){
                let options = [];
                this.cropList = [];
                for (let i in cropListJson.data){
                    const crop = new TypeCrop(cropListJson.data[i]);
                    options.push({id: crop.getId(), name: crop.getName(), code: crop.getCode()});
                    this.cropList.push(crop);
                }
                this.setState({cropOptions: options});
            } else {
                refreshIfNeeded(cropListJson, null, this.props.showInfoModal);
            }
            this.setState({isCropLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCropLoading: false});
        });
    }

    // Establece el tipo de cultivo seleccionado en el filtro de cultivos.
    setCropFormFill = (items) => {
        for (let i in this.cropList){
            if (items.length > 0) {
                if (items[0].id === this.cropList[i].getId()) {
                    this.setState({selectedCrop: new TypeCrop(this.cropList[i])}, () => {
                        this.getPhytoTPCIWithFilters();
                    });
                    break;
                }
            }
        }
    }

    // Realiza la petición de problemas con el texto introducido en el input de filtros de problemas.
    getAutocompleteProblem = (query) => {
        getProblemListFilter(query).then(async problemList => {
            this.setState({isProblemLoading: true});
            const problemListJson = await problemList.json();

            if (!problemList.ok){
                const error = problemList.status;
                return Promise.reject(error);
            }

            if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0){
                let options = [];
                this.problemList = [];
                for (let i in problemListJson.data){
                    const problem = new Infection(problemListJson.data[i]);
                    options.push({id: problem.getId(), name: problem.getName()});
                    this.problemList.push(problem);
                }
                this.setState({problemOptions: options});
            } else {
                refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
            }
            this.setState({isProblemLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isProblemLoading: false});
        });
    }

    // Establece el problema seleccionado en el filtro de problemas.
    setProblemFormFill = (items) => {
        for (let i in this.problemList){
            if (items.length > 0) {
                if (items[0].id === this.problemList[i].getId()) {
                    this.setState({selectedProblem: new Infection(this.problemList[i])}, () => {
                        this.getPhytoTPCIWithFilters();
                    });
                    break;
                }
            }
        }
    }

    // Pide la lista de CI del tratamiento con filtros aplicados.
    getPhytoTPCIWithFilters = () => {
        this.setState({isCILoading: true, page: 0}, () => {
            let cropId = (this.state.selectedCrop !== "" ? this.state.selectedCrop.getId() : null);
            let problemId = (this.state.selectedProblem !== "" ? this.state.selectedProblem.getId() : null);
            getPhytoTPCIFiltered(this.state.selectedPhyto.getId(), cropId, problemId).then(async phytoInfoList => {
                this.setState({maxCIAmount: parseInt(phytoInfoList.headers.get("content-range").split(" ")[2])});
                const phytoInfoJson = await phytoInfoList.json();

                if (!phytoInfoList.ok) {
                    const error = phytoInfoList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (phytoInfoJson.hasOwnProperty('data')) {
                    let auxTPCI = [];
                    let auxCI;
                    for (let i in phytoInfoJson.data){
                        auxCI = new TypePhytosanitaryCropInfection(phytoInfoJson.data[i]);
                        auxTPCI.push(auxCI);
                    }

                    let auxPaginated = this.fillPaginatedTPCI(auxTPCI);
                    this.setState({paginatedTPCI: auxPaginated}, () => {
                        let tablebody = this.fillCITableContent();
                        this.setState({ciTableBody: tablebody, showEditPhytoCI: true, isCILoading: false});
                    });
                } else {
                    refreshIfNeeded(phytoInfoJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        });
    }

    // Resetea el valor del filtro de cultivos y realiza la petición de los CI del tratamiento.
    inputChangeCrop = (text) => {
        clearTimeout(this.delayedPet);
        this.setState({cropOptions: []}, () => {
            if (text.length < 3) {
                this.delayedPet = setTimeout(() => {
                    this.setState({selectedCrop: ""}, () => {
                        this.getPhytoTPCIWithFilters();
                    });
                }, this.props.globals.getAsyncDelay());
            }
        });
    }

    // Resetea el valor del filtro de problema y realiza la petición de los CI del tratamiento.
    inputChangeProblem = (text) => {
        clearTimeout(this.delayedPet);
        this.setState({problemOptions: []}, () => {
            if (text.length < 3) {
                this.delayedPet = setTimeout(() => {
                    this.setState({selectedProblem: ""}, () => {
                        this.getPhytoTPCIWithFilters();
                    })
                }, this.props.globals.getAsyncDelay());
            }
        });
    }

    // Abre el modal de editar campos del CI con un nuevo CI.
    addPhytoCI = () => {
        this.setState({editedCI: [new TypePhytosanitaryCropInfection()], showEditPhytoCI: false}, () => {
            this.showEditPhytoCIFieldsModal();
        });
    }

    // Muestra el modal de editar campos del CI del tratamiento.
    showEditPhytoCIFieldsModal = () => {
        this.setState({showEditPhytoCIFields: true, isCreation: true}, () => {
            this.fillTypecropList();
            this.setEditPhytoCIFields();
        });
    }

    // Oculta el modal de editar campos del CI del tratamiento y abre el de editar información del tratamiento.
    hideEditPhytoCIFieldsModal = () => {
        document.getElementById("ci_crop_problem_div").classList.remove("d-none");
        document.getElementById("ci_tp_form").classList.remove("d-none");
        this.setState({showEditPhytoCIFields: false, showEditPhytoCI: true, editedCI: [], selectedCICrop: "",
            selectedCIProblem: ""});
    }

    // Establece los valores del CI en los campos del modal.
    setEditPhytoCIFields = () => {
        let auxCI = new TypePhytosanitaryCropInfection(this.state.editedCI[0]);
        if (auxCI.getIdTc() !== 0 && auxCI.getIdI() !== 0) {
            let cropName = "";
            if (!this.state.isCreation) {
                for (let i in this.state.allCrops) {
                    if (this.state.allCrops[i].getId() === auxCI.getIdTc()) {
                        cropName = this.state.allCrops[i].getName();
                        break;
                    }
                }
                this._typeaheadCICrop.state.selected = [{id: auxCI.getIdTc(), name: cropName}];
            }
            let problemName = "";
            for (let i in this.state.allProblem) {
                if (this.state.allProblem[i].getId() === auxCI.getIdI()) {
                    problemName = this.state.allProblem[i].getName();
                    break;
                }
            }
            this._typeaheadCIProblem.state.selected = [{id: auxCI.getIdI(), name: problemName}];
            setTimeout(() => {
                if (!this.state.isCreation) {
                    document.getElementById("ci_cb_typecrops_div").classList.add("d-none");
                    document.getElementById("ci_crop_div").classList.remove("d-none");
                    document.getElementById("ci_crop_input").value = cropName;
                } else {
                    document.getElementById("ci_crop_div").classList.add("d-none");
                }
                document.getElementById("ci_problem_input").value = problemName;
            }, 150);
        }
        document.getElementById("ci_mnd").value = auxCI.getMND();
        document.getElementById("ci_mxd").value = auxCI.getMXD();
        let mdInner = "";
        for (let i in this.state.allDoseMeasures){
            let checked = (this.state.allDoseMeasures[i].getId() === auxCI.getMD() ? 'selected' : '');
            mdInner += '<option value="' + this.state.allDoseMeasures[i].getId() + '" ' + checked + '>' + this.state.allDoseMeasures[i].getSymbol() + '</option>'
        }
        document.getElementById("ci_md").innerHTML = mdInner;
        document.getElementById("ci_mna").value = auxCI.getMNA();
        document.getElementById("ci_mxa").value = auxCI.getMXA();
        document.getElementById("ci_mni").value = auxCI.getMNI();
        document.getElementById("ci_mxi").value = auxCI.getMXI();
        document.getElementById("ci_mns").value = auxCI.getMNS();
        document.getElementById("ci_mxs").value = auxCI.getMXS();
        let msInner = "";
        for (let i in this.state.allSoupMeasures){
            let checked = (this.state.allSoupMeasures[i].getId() === auxCI.getMS() ? 'selected' : '');
            msInner += '<option value="' + this.state.allSoupMeasures[i].getId() + '" ' + checked + '>' + this.state.allSoupMeasures[i].getSymbol() + '</option>'
        }
        document.getElementById("ci_ms").innerHTML = msInner;
        document.getElementById("ci_mnda").value = auxCI.getMNDA();
        document.getElementById("ci_mxda").value = auxCI.getMXDA();
        let mdaInner = "";
        for (let i in this.state.allDoseMeasures){
            let checked = (this.state.allDoseMeasures[i].getId() === auxCI.getMDA() ? 'selected' : '');
            mdaInner += '<option value="' + this.state.allDoseMeasures[i].getId() + '" ' + checked + '>' + this.state.allDoseMeasures[i].getSymbol() + '</option>'
        }
        document.getElementById("ci_mda").innerHTML = mdaInner;
        document.getElementById("ci_s").value = auxCI.getS();
        let tpInner = "";
        for (let i in this.state.allProtection){
            let checked = '';
            if (auxCI.getTp() !== null) {
                checked = (this.state.allProtection[i].getId() === auxCI.getTp() ? 'selected' : '');
            }
            tpInner += '<option value="' + this.state.allProtection[i].getId() + '" ' + checked + '>' + this.state.allProtection[i].getName() + '</option>'
        }
        document.getElementById("ci_tp").innerHTML = tpInner;

        if (auxCI.getId() > 0){
            document.getElementById("ci_crop_input").disabled = true;
            document.getElementById("ci_problem_input").disabled = true;
            document.getElementById("ci_tp").disabled = true;
        } else {
            document.getElementById("ci_crop_input").disabled = false;
            document.getElementById("ci_problem_input").disabled = false;
            document.getElementById("ci_tp").disabled = false;
        }
    }

    // Realiza la petición de cultivos con el texto introducido en el input de cultivo de CI.
    getAutocompleteCICrop = (query) => {
        getCropListFilter(query).then(async cropList => {
            this.setState({isCroCIpLoading: true});
            const cropListJson = await cropList.json();

            if (!cropList.ok){
                const error = cropList.status;
                return Promise.reject(error);
            }

            if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0){
                let options = [];
                this.cropList = [];
                for (let i in cropListJson.data){
                    const crop = new TypeCrop(cropListJson.data[i]);
                    options.push({id: crop.getId(), name: crop.getName(), code: crop.getCode()});
                    this.cropList.push(crop);
                }
                this.setState({cropCIOptions: options});
            } else {
                refreshIfNeeded(cropListJson, null, this.props.showInfoModal);
            }
            this.setState({isCroCIpLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCroCIpLoading: false});
        });
    }

    // Establece el tipo de cultivo seleccionado en cultivo de CI.
    setCICropFormFill = (items) => {
        for (let i in this.cropList){
            if (items.length > 0) {
                if (items[0].id === this.cropList[i].getId()) {
                    this.setState({selectedCICrop: new TypeCrop(this.cropList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de cultivo del CI.
    resetCropCIOptions = () => {
        this.setState({cropCIOptions: []});
    }

    // Realiza la petición de problemas con el texto introducido en el input de problemas de CI.
    getAutocompleteCIProblem = (query) => {
        getProblemListFilter(query).then(async problemList => {
            this.setState({isCIProblemLoading: true});
            const problemListJson = await problemList.json();

            if (!problemList.ok){
                const error = problemList.status;
                return Promise.reject(error);
            }

            if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0){
                let options = [];
                this.problemList = [];
                for (let i in problemListJson.data){
                    const problem = new Infection(problemListJson.data[i]);
                    options.push({id: problem.getId(), name: problem.getName()});
                    this.problemList.push(problem);
                }
                this.setState({problemCIOptions: options});
            } else {
                refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
            }
            this.setState({isCIProblemLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCIProblemLoading: false});
        });
    }

    // Establece el problema seleccionado en problema de CI.
    setCIProblemFormFill = (items) => {
        for (let i in this.problemList){
            if (items.length > 0) {
                if (items[0].id === this.problemList[i].getId()) {
                    this.setState({selectedCIProblem: new Infection(this.problemList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de problema de CI.
    resetProblemCIOptions = () => {
        this.setState({problemCIOptions: []});
    }

    // Si los campos son válidos, modifica y guarda el CI a crear o editar.
    saveCIChanges = () => {
        if (this.state.isCreation){
            this.createNewCI();
        } else {
            if (this.state.editedCI.length > 0) {
                if (this.state.editedCI.length === 1) {
                    if (this.state.selectedCICrop !== "" && this.state.selectedCIProblem !== "") {
                        let auxCI = new TypePhytosanitaryCropInfection(this.state.editedCI[0]);
                        auxCI.setIdTc(this.state.selectedCICrop.getId());
                        auxCI.setIdI(this.state.selectedCIProblem.getId());
                        auxCI.setTp(document.getElementById("ci_tp").value);

                        auxCI = this.editCIFields(auxCI);

                        let json = {};
                        json.data = auxCI;

                        this.setState({
                            editedCI: [],
                            selectedCICrop: "",
                            selectedCIProblem: "",
                            showEditPhytoCI: false,
                            showEditPhytoCIFields: false
                        }, () => {
                            this.props.showLoaderModal(this.props.strings.updatingPhytoInfo);
                            updateTPCI(this.state.selectedPhyto.getId(), json).then(async phytoResponse => {
                                const phytoResponseJson = await phytoResponse.json();

                                if (!phytoResponse.ok) {
                                    const error = phytoResponseJson.status;
                                    return Promise.reject(error);
                                }

                                if (phytoResponseJson.hasOwnProperty('data')) {
                                    this.showEditPhytoCIModal();
                                } else {
                                    refreshIfNeeded(phytoResponseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                                }
                                this.props.hideLoaderModal();
                            }).catch(error => {
                                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                                console.log(error);
                                this.props.hideLoaderModal();
                            });
                        });
                    } else {
                        this.props.showInfoModal(this.props.strings.phytosanitaryCIErrorTitle, this.props.strings.phytosanitaryCIErrorBody);
                    }
                } else {
                    this.setState({showEditPhytoCIFields: false}, () => {
                        this.props.showLoaderModal(this.props.strings.updatingPhytoInfo);
                        let promises = [];
                        for (let i in this.state.editedCI) {
                            let auxCI = new TypePhytosanitaryCropInfection(this.state.editedCI[i]);
                            auxCI = this.editCIFields(auxCI);

                            let json = {};
                            json.data = auxCI;
                            promises.push(updateTPCI(this.state.selectedPhyto.getId(), json));

                            Promise.all(promises).then(() => {
                                this.props.hideLoaderModal();
                                this.showEditPhytoCIModal();
                                this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                            }).catch(error => {
                                this._typeaheadPhyto.clear();
                                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                                console.log(error);
                            });
                        }
                    });
                }
            }
        }
    }

    // Edita los campos del CI y lo devuelve.
    editCIFields = (auxCI) => {
        auxCI.setMND((document.getElementById("ci_mnd").value > 0 ? parseFloat(document.getElementById("ci_mnd").value) : 0));
        auxCI.setMXD((document.getElementById("ci_mxd").value > 0 ? parseFloat(document.getElementById("ci_mxd").value) : 0));
        if (auxCI.getMND() > auxCI.getMXD()) {
            auxCI.setMXD(auxCI.getMND());
        }
        auxCI.setMD(document.getElementById("ci_md").value);
        auxCI.setMNA((document.getElementById("ci_mna").value > 0 ? parseInt(document.getElementById("ci_mna").value) : 0));
        auxCI.setMXA((document.getElementById("ci_mxa").value > 0 ? parseInt(document.getElementById("ci_mxa").value) : 0));
        if (auxCI.getMNA() > auxCI.getMXA()) {
            auxCI.setMXA(auxCI.getMNA());
        }
        auxCI.setMNI((document.getElementById("ci_mni").value > 0 ? parseInt(document.getElementById("ci_mni").value) : 0));
        auxCI.setMXI((document.getElementById("ci_mxi").value > 0 ? parseInt(document.getElementById("ci_mxi").value) : 0));
        if (auxCI.getMNI() > auxCI.getMXI()) {
            auxCI.setMXI(auxCI.getMNI());
        }
        auxCI.setMNS((document.getElementById("ci_mns").value > 0 ? parseFloat(document.getElementById("ci_mns").value) : 0));
        auxCI.setMXS((document.getElementById("ci_mxs").value > 0 ? parseFloat(document.getElementById("ci_mxs").value) : 0));
        if (auxCI.getMNS() > auxCI.getMXS()) {
            auxCI.setMXS(auxCI.getMNS());
        }
        auxCI.setMS(document.getElementById("ci_ms").value);
        auxCI.setMNDA((document.getElementById("ci_mnda").value > 0 ? parseFloat(document.getElementById("ci_mnda").value) : 0));
        auxCI.setMXDA((document.getElementById("ci_mxda").value > 0 ? parseFloat(document.getElementById("ci_mxda").value) : 0));
        if (auxCI.getMNDA() > auxCI.getMXDA()) {
            auxCI.setMXDA(auxCI.getMNDA());
        }
        auxCI.setMDA(document.getElementById("ci_mda").value);
        auxCI.setS((document.getElementById("ci_s").value > 0 ? parseInt(document.getElementById("ci_s").value) : 0));

        return auxCI;
    }

    // Abre el modal de confirmación con los argumentos pasados como título, cuerpo y función de retorno.
    showConfirmationModal = (title, content, callback) => {
        this.setState({showModalConfirmation: true});
        // Se obtienen los dos numeros para la operación de confirmacion.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        // Se muestra la información en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            //Se establece el evento onclick del boton de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmUpdate(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok para enviar mensaje.
    confirmUpdate = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        // Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        // Se comprueba el resultado y se redirige a la operación o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Oculta el modal de confirmación.
    hideConfirmationModal = () => {
        this.setState({showModalConfirmation: false});
    }

    // Confirma el borrado del CIs, los guardas y vuelve a pedir los CIs para rellenar la tabla.
    confirmDeleteCI = () => {
        if (this.state.editedCI.length > 0){
            this.props.showLoaderModal(this.props.strings.removingPhyto);
            let promises = [];
            for (let i in this.state.editedCI){
                let auxCI = new TypePhytosanitaryCropInfection(this.state.editedCI[i]);

                let json = {};
                json.data = auxCI;

                promises.push(deleteTPCI(this.state.selectedPhyto.getId(), json));
            }

            this.setState({editedCI: [], showEditPhytoCI: false}, () => {
                Promise.all(promises).then(() => {
                    this.props.hideLoaderModal();
                    this.showEditPhytoCIModal();
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                }).catch(error => {
                    this._typeaheadPhyto.clear();
                    this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Realiza la función de borrado múltiple de TPCI.
    onMultipleDeletePhyto = (rowIds) => {
        let ciList = [];
        for (let i in this.state.paginatedTPCI[this.state.page]){
            if (rowIds.includes(this.state.paginatedTPCI[this.state.page][i].getId())) {
                let auxCI = new TypePhytosanitaryCropInfection(this.state.paginatedTPCI[this.state.page][i]);
                ciList.push(auxCI);
            }
        }
        this.setState({editedCI: ciList, showEditPhytoCIFields: false}, () => {
            this.showConfirmationModal(this.props.strings.confirmationModalRemoveCITitle, this.props.strings.confirmationModalRemoveCIBody, this.confirmDeleteCI);
        });
    }

    // Inicia la función de edición múltiple de la tabla de TPCI.
    onHeaderActionPhyto = (type) => {
        let auxTPCIs = [];
        for (let i in this.state.paginatedTPCI[this.state.page]){
            let auxElem = document.getElementById("id_" + this.state.paginatedTPCI[this.state.page][i].getId());
            if (auxElem !== null && auxElem.checked){
                auxTPCIs.push(this.state.paginatedTPCI[this.state.page][i]);
            }
        }

        this.setState({editedCI: auxTPCIs}, () => {
            if (type === "EDIT" && this.state.editedCI.length > 0){
                if (this.state.editedCI.length === 1){
                    this.showEditPhytoCIFieldsModal();
                } else {
                    this.multipleEditPhyto();
                }
            }
        });
    }

    // Abre el modal de editar multiples TPCI.
    multipleEditPhyto = () => {
        this.setState({showEditPhytoCIFields: true, isCreation: false}, () => {
            this.setEditPhytoCIFields();
            document.getElementById("ci_crop_problem_div").classList.add("d-none");
            document.getElementById("ci_tp_form").classList.add("d-none");
        });
    }

    // Rellena la checklist de cultivos de creación de CI.
    fillTypecropList = () => {
        let htmlTypecrop = "<ul>";
        for (let i in this.state.allCrops){
            htmlTypecrop += "<li><input id='typecrop" + this.state.allCrops[i].getId() + "' type='checkbox' name='cbtypecrop' />" +
                "<label class='ml-3' for='typecrop" + this.state.allCrops[i].getId() + "'>" + this.state.allCrops[i].getName() + "</label></li>";
        }
        htmlTypecrop += "</ul>";
        document.getElementById("ci_cb_typecrops").innerHTML = htmlTypecrop;
        document.getElementById("ci_cb_typecrops_div").classList.remove("d-none");
    }

    // Función para crear multiples CI del fitosanitario.
    createNewCI = () => {
        let json = {};
        json.data = [];
        if (this.state.selectedCIProblem !== "") {
            let cbtp = document.getElementsByName("cbtypecrop");
            for (let i in cbtp) {
                if (cbtp[i].checked) {
                    let auxCI = new TypePhytosanitaryCropInfection();

                    for (let j in this.state.allCrops) {
                        if (cbtp[i].id.split("typecrop")[1] === this.state.allCrops[j].getId()) {
                            auxCI.setIdTc(this.state.allCrops[j].getId());
                            break;
                        }
                    }
                    auxCI.setIdI(this.state.selectedCIProblem.getId());
                    auxCI.setTp(document.getElementById("ci_tp").value);
                    auxCI = this.editCIFields(auxCI);

                    json.data.push(auxCI);
                }
            }
            this.setState({showEditPhytoCIFields: false}, () => {
                this.props.showLoaderModal(this.props.strings.updatingPhytoInfo);
                updateTPCI(this.state.selectedPhyto.getId(), json).then(async response => {
                    const responseJson = await response.json();

                    if (!response.ok){
                        const error = response.status;
                        return Promise.reject(error);
                    }

                    if (responseJson.hasOwnProperty('data') && responseJson.data.hasOwnProperty('result') && responseJson.data.result === 'ok'){
                        this.props.hideLoaderModal();
                        this.showEditPhytoCIModal();
                        this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                    } else {
                        refreshIfNeeded(responseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                        this._typeaheadPhyto.clear();
                    }
                }).catch(error => {
                    this._typeaheadPhyto.clear();
                    this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                    console.log(error);
                });
            });
        } else {
            this.props.showInfoModal(this.props.strings.phytosanitaryCIErrorTitle, this.props.strings.phytosanitaryCIErrorBody);
        }
    }
}

export default PhytosanitaryManagement;