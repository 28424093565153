import React, {Component} from 'react';

// Crea un componente label.
class Label extends Component {
    // Constructor de label.
    constructor(props) {
        super(props);
        this.id = props.id;
        this.for = props.for;
        this.className = props.className;
        this.text = props.text;
    }

    // Dibuja la label.
    render() {
        return (
            <label id={this.id} htmlFor={this.for} className={this.className}>{this.text}</label>
        );
    }
}

export default Label;