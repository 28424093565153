import React, {Component} from "react";
import { SketchPicker } from 'react-color';
// noinspection NpmUsedModulesInstalled
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import {
    createLicenseServer,
    createOrganizationServer, deleteLicense, deleteOrgServer,
    getAsyncOrgLicenseServer, getJobAsync, getJobAsyncResult,
    getOrganizationList, getOrgLicenseServer, getTypeOrganization,
} from "../../../logic/functions/ServerPetitions";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import ReactAsyncTable from "react-async-table";
import PageTitle from "../../component/PageTitle";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Input from "../../component/Input";
import ModalFooter from "react-bootstrap/ModalFooter";
import CustomButton from "../../component/CustomButton";
import Organization from "../../../logic/objects/Organization";
import TypeOrganization from "../../../logic/objects/TypeOrganization";
import Flatpickr from "react-flatpickr";
import KTP from "../../../logic/objects/KTP";
import UploadImage from "../../component/UploadImage";
import Strings from "../../../logic/strings/Strings";
import Key from "../../../logic/objects/Key";
import JobAsync from "../../../logic/objects/JobAsync";

// Clase que contiene el contenido de la página OrganizationInfo.
class OrganizationInfo extends Component {
    // Constructor de clase OrganizationInfo.
    constructor(props) {
        super(props);
        this.header = [{
            text: this.props.strings.tableId,
            dataField: 'id'
        }, {
            text: this.props.strings.tableName,
            dataField: 'name'
        }, {
            text: this.props.strings.tableOrgNef,
            dataField: 'nef'
        }, {
            text: this.props.strings.tableOrgSubtype,
            dataField: 'subtype'
        }, {
            text: this.props.strings.tableOrgNl,
            dataField: 'nl'
        }, {
            text: this.props.strings.tableOrgNm,
            dataField: 'nm'
        }, {
            text: this.props.strings.tableOrgNe,
            dataField: 'ne'
        }, {
            text: this.props.strings.tableOrgNf,
            dataField: 'nf'
        }, {
            text: this.props.strings.tableOrgNel,
            dataField: 'nel'
        }, {
            text: this.props.strings.tableOrgNem,
            dataField: 'nem'
        }, {
            text: this.props.strings.tableOrgNee,
            dataField: 'nee'
        }, {
            text: this.props.strings.tableOrgNes,
            dataField: 'nes'
        }, {
            text: this.props.strings.userTablePremiumPhyto,
            dataField: 'premiumc'
        }, {
            text: this.props.strings.userTablePremiumAnalysis,
            dataField: 'premiumd'
        },{
            text: this.props.strings.userTablePremiumStock,
            dataField: 'premiume'
        }];

        this.orgLicHeader = [{
            text: this.props.strings.tableId,
            dataField: 'licid'
        },{
            text: this.props.strings.tableLicenseSubtype,
            dataField: 'licsubtype'
        }, {
            text: this.props.strings.tableKey,
            dataField: 'lickey'
        }, {
            text: this.props.strings.tableNSeason,
            dataField: 'licnseason'
        }, {
            text: this.props.strings.tableKeyUser,
            dataField: 'licuser'
        }, {
            text: this.props.strings.tablePremiumTech,
            dataField: 'licdatetech'
        }, {
            text: this.props.strings.tablePremiumPhyto,
            dataField: 'licdatephy'
        }, {
            text: this.props.strings.tablePremiumAnalysis,
            dataField: 'licdateana'
        }, {
            text: this.props.strings.tablePremiumStock,
            dataField: 'licdatestock'
        }];
        this.listaUsuarios = [];
        this.state = {tablebody: [], organizations: [], organizationIds: [], petitionended: false, maxamount: 0, page_size: 20, page: 0,
            isLoading: true, query: "", exitPetition: false, showOrgModal: false, orgTypes: [], selectedOrg: null, showModalConfirmation: false,
            color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3, orgKeyDateB: undefined, orgKeyDateC: undefined,  orgKeyDateD: undefined,
            orgKeyDateE: undefined, orgLicenses: [], orgLicensesTable: [], showOrgLicensesModal: false, licIsLoading: false, licMaxAmount: 0, licPage: 0,
            selectedEditLicenses: [], showEditOrgLicModal: false, licEditDateC: undefined, licEditDateD: undefined, licEditDateE: undefined, queryLic: ""};

        this.rowActions = ({onAction}) => (
            <span>
                <i className="fas fa-fw fa-pencil-alt table-icon mr-2" onClick={(e) => onAction(e, 'EDIT')} />
                <i className="fas fa-fw fa-trash-alt table-icon mr-2" onClick={(e) => onAction(e, 'REMOVE')} />
                <i className="fas fa-fw fa-download table-icon" onClick={(e) => onAction(e, 'DOWNLOAD')} />
            </span>
        );

        this.headerLicAction = ({onHeaderAction}) => (
            <span>
                <button type={"button"} className={"btn btn-primary mr-2"} data-html="true" data-toggle="tooltip" title="Editar" onClick={() => onHeaderAction('EDIT')}>
                    <i className="fas fa-pencil-alt text-white" />
                </button>
            </span>
        );
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.orgTitle;
        getOrganizationList(this.state.page_size, this.state.page, this.state.query).then(async organizationList => {
            const organizationsJson = await organizationList.json();
            this.setState({maxamount: parseInt(organizationList.headers.get("content-range").split(" ")[2])}, () => {
                document.getElementById("organizationAmount").innerText = this.props.strings.amount1 + this.state.maxamount + this.props.strings.orgAmount2;
            });

            if (!organizationList.ok){
                const error = organizationList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (organizationsJson.hasOwnProperty('data')){
                let organizations = [];
                for(let i in organizationsJson.data) {
                    organizations.push(new Organization(organizationsJson.data[i]));
                }
                this.fillOrganizationListTable(organizations);
                this.setState({isLoading: false});
            } else {
                refreshIfNeeded(organizationsJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
            endPreLoad();
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            endPreLoad();
        });
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja la página de información de organizaciones.
    render = () => {
        let table = '';
        if (this.state.petitionended){
            table = <ReactAsyncTable keyField={"id"} columns={this.header} items={this.state.tablebody} isLoading={this.state.isLoading}
                                     currentPage={this.state.page+1} itemsPerPage={this.state.page_size} totalItems={this.state.maxamount}
                                     onChangePage={this.onChangePage} onSearch={this.onSearch} loader={this.tableLoader} delay={this.props.globals.getAsyncDelay()}
                                     actionsComponent={this.rowActions} onAction={this.onAction} options={{
                                         actionsColumn: true
                                     }}
                                     translations={{
                                         searchPlaceholder: this.props.strings.tableSearch,
                                         noDataText: this.props.strings.orgTableNotFound,
                                         requestFailedText: this.props.strings.tableSearchError,
                                         paginationFirst: this.props.strings.tablePaginationFirst,
                                         paginationLast: this.props.strings.tablePaginationLast,
                                         actionsColumnTitle: ""
                                     }}/>;
        }
        let uploadImg = '';
        if (this.state.selectedOrg !== null){
            uploadImg = <UploadImage strings={this.props.strings} initialLoad={true} orgId={this.state.selectedOrg.getId()} />;
        }

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.orgInfoPageTitle}/>

                <div className="card shadow mb-4">
                    <div id="organizationListTable" className="card-body">
                        {table}
                        <div id="organizationAmount" className="row ml-1 mt-2" />
                    </div>
                </div>

                {/* Modal Org Edit */}
                <Modal show={this.state.showOrgModal} size="lg" backdrop="static" onHide={this.hideOrgEditModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {this.props.strings.orgEditTitle}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div className="row">
                                <FormGroup className="col-6">
                                    <FormLabel>{this.props.strings.tableName}</FormLabel>
                                    <Input id={"editOrgName"} type={"text"} ph={this.props.strings.orgNamePh} />
                                </FormGroup>
                                <FormGroup className="col-6">
                                    <FormLabel>{this.props.strings.tableOrgAlias}</FormLabel>
                                    <Input id={"editOrgAlias"} type={"text"} ph={this.props.strings.orgAliasPh} />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-12">
                                    <FormLabel>{this.props.strings.tableOrgSubtype}</FormLabel>
                                    <select id={"editOrgSubtype"} className="form-control" />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor1}</FormLabel>
                                    <SketchPicker color={this.state.color1} onChangeComplete={this.handleColor1Change} />
                                </FormGroup>
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor2}</FormLabel>
                                    <SketchPicker color={this.state.color2} onChangeComplete={this.handleColor2Change} />
                                </FormGroup>
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor3}</FormLabel>
                                    <SketchPicker color={this.state.color3} onChangeComplete={this.handleColor3Change} />
                                </FormGroup>
                                <FormGroup className="col-3 offset-4 form-group pl-4 mt-3">
                                    <CustomButton additionalClass="btn-primary" parentFunction={this.handleResetColors}>{this.props.strings.tableOrgColorReset}</CustomButton>
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNl}</FormLabel>
                                    <Input id={"editOrgNl"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNm}</FormLabel>
                                    <Input id={"editOrgNm"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNe}</FormLabel>
                                    <Input id={"editOrgNe"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNf}</FormLabel>
                                    <Input id={"editOrgNf"} type={"number"} min={"0"} />
                                </FormGroup>
                            </div>
                            <label className="d-none mt-3">{this.props.strings.userPremiumOfflineDateTitle}</label>
                            <div className="d-none row m-0">
                                <Flatpickr id="orgKeyDateB" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateB} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateB: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateB}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumPhytoDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateC" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateC} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateC: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateC}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumAnalysisDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateD" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateD} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateD: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateD}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumStockDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateE" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateE} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateE: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateE}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <div className="row mt-3 justify-content-center">
                                {uploadImg}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.hideOrgEditModal}/>
                        <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.showOrgLicensesModal}>
                            <span className="icon">
                              <i className="fas fa-pencil-alt"/>
                            </span>
                            <span className="text">{this.props.strings.editLicensesButton}</span>
                        </CustomButton>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.editOrg}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Org Edit */}

                {/* Modal Org Licenses */}
                <Modal show={this.state.showOrgLicensesModal} backdrop="static" onHide={this.clearOrgLicModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {this.props.strings.orgModalEditlicenses + (this.state.selectedOrg !== null ? " " + this.state.selectedOrg.getName() : "")}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row m-0">
                            <ReactAsyncTable keyField={"licid"} columns={this.orgLicHeader} items={this.state.orgLicensesTable} isLoading={this.state.licIsLoading}
                                 currentPage={this.state.licPage+1} itemsPerPage={this.state.page_size} totalItems={this.state.licMaxAmount} onSearch={this.onSearchLic}
                                 onChangePage={this.onChangePageOrgLic} loader={this.tableLicLoader} onMultipleDelete={this.confirmOnMultipleDeleteOrgLic} delay={500}
                                 bootstrapCheckbox={true} headerActions={this.headerLicAction} onHeaderAction={this.onHeaderActionOrgLic} options={{
                                     searchBox: true,
                                     multipleSelect: true
                                 }}
                                 translations={{
                                     searchPlaceholder: this.props.strings.tableSearch,
                                     noDataText: this.props.strings.orgLicenseTableNotFound,
                                     requestFailedText: this.props.strings.tableSearchError,
                                     paginationFirst: this.props.strings.tablePaginationFirst,
                                     paginationLast: this.props.strings.tablePaginationLast
                                 }}/>
                        </div>
                    </ModalBody>
                </Modal>
                {/* End of Modal Org Licenses */}

                {/* Modal Edit Org Licenses */}
                <Modal show={this.state.showEditOrgLicModal} backdrop="static" onHide={this.clearEditOrgLicModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {this.props.strings.orgModalEditlicenses + (this.state.selectedOrg !== null ? " " + this.state.selectedOrg.getName() : "")}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <FormLabel>{this.props.strings.tableNSeason}</FormLabel>
                        <Input id={"licEditNf"} type={"number"} ph={this.props.strings.licenseNSeasonPh} min={"0"} />

                        <label className="mt-3">{this.props.strings.userPremiumPhytoDateTitle}</label>
                        <div className="row m-0">
                            <Flatpickr id="licEditDateC" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                       value={this.state.licEditDateC} options={{dateFormat: "d-m-Y", locale: Spanish, position: "below"}}
                                       onChange={date => {
                                           this.setState({licEditDateC: date});
                                       }} />
                            <div className={"col-1 p-0"}>
                                <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearLicEditDateC}>
                                    <i className="fas fa-trash"/>
                                </CustomButton>
                            </div>
                        </div>

                        <label className="mt-3">{this.props.strings.userPremiumAnalysisDateTitle}</label>
                        <div className="row m-0">
                            <Flatpickr id="licEditDateD" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                       value={this.state.licEditDateD} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                       onChange={date => {
                                           this.setState({licEditDateD: date});
                                       }} />
                            <div className={"col-1 p-0"}>
                                <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearLicEditDateD}>
                                    <i className="fas fa-trash"/>
                                </CustomButton>
                            </div>
                        </div>

                        <label className="mt-3">{this.props.strings.userPremiumStockDateTitle}</label>
                        <div className="row m-0">
                            <Flatpickr id="licEditDateE" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                       value={this.state.licEditDateE} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                       onChange={date => {
                                           this.setState({licEditDateE: date});
                                       }} />
                            <div className={"col-1 p-0"}>
                                <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearLicEditDateE}>
                                    <i className="fas fa-trash"/>
                                </CustomButton>
                            </div>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.clearEditOrgLicModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.editOrgLic}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Edit Org Licenses */}

                {/* Modal confirmación */}
                <Modal show={this.state.showModalConfirmation} backdrop="static" onHide={this.resetConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal confirmación */}
            </div>
        );
    }

    // Rellena la lista de organizaciones de la tabla.
    fillOrganizationListTable = (organizations) => {
        let auxOrgs = [...this.state.organizations];
        let auxIds = [...this.state.organizationIds];
        for (let i in organizations){
            if (!auxIds.includes(organizations[i].getId())){
                auxIds.push(organizations[i].getId());
                auxOrgs.push(new Organization(organizations[i]));
            }
            let newTableBody = this.state.tablebody;
            newTableBody.push({id: organizations[i].getId(), name: organizations[i].getName(), nef: organizations[i].getNef(),
            subtype: organizations[i].getSubtype().getName(), nl: organizations[i].getNl(), nm: organizations[i].getNm(),
            ne: organizations[i].getNe(), nf: organizations[i].getNf(), nel: organizations[i].getNel(), nem: organizations[i].getNem(),
            nee: organizations[i].getNee(), nes: organizations[i].getNes(), premiumc: organizations[i].getPremiumPhytoEndDate(),
            premiumd: organizations[i].getPremiumAnalysisEndDate(), premiume: organizations[i].getPremiumStockEndDate()});
            this.setState({tablebody: newTableBody});
        }
        this.setState({petitionended: true, organizations: auxOrgs, organizationIds: auxIds});
    }

    // Muestra el mensaje de cargando organizaciones cuando se cambia de página en la tabla.
    tableLoader = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.orgLoadingOrganization}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Realiza el cambio de página de la tabla.
    onChangePage = (page) => {
        this.setState({tablebody: [], isLoading: true, page: page-1}, () => {
            this.getOrganizationListPage();
        });
    }

    // Realiza el filtrado de la tabla.
    onSearch = (query) => {
        this.setState({tablebody: [], isLoading: true, page: 0, query: query}, () => {
            this.getOrganizationListPage();
        });
    }

    // Inicia las funciones de edición o borrado de la tabla de organizaciones.
    onAction = (type, row) => {
        for (let i in this.state.organizations){
            if (this.state.organizations[i].getId() === row.id){
                this.setState({selectedOrg: this.state.organizations[i]}, () => {
                    if (type === "EDIT"){
                        this.showOrgEditModal();
                    } else if (type === "REMOVE") {
                        this.showConfirmationModal(this.props.strings.confirmationModalRemoveOrgTitle, this.props.strings.confirmationModalRemoveOrgBody, this.deleteOrg);
                    } else {
                        this.downloadOrgLicAsync();
                    }
                });
            }
        }
    }

    // Realiza la petición de la página de organizaciones pedida en la tabla.
    getOrganizationListPage = () => {
        this.setState({tablebody: []}, () => {
            getOrganizationList(this.state.page_size, this.state.page, this.state.query).then(async orgList => {
                const orgJson = await orgList.json();
                this.setState({maxamount: parseInt(orgList.headers.get("content-range").split(" ")[2])}, () => {
                    document.getElementById("organizationAmount").innerText = this.props.strings.amount1 + this.state.maxamount + this.props.strings.orgAmount2;
                });

                if (!orgList.ok){
                    const error = orgList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (orgJson.hasOwnProperty('data')){
                    this.setState({tablebody: []}, () => {
                        let organizations = [];
                        for (let i in orgJson.data) {
                            organizations.push(new Organization(orgJson.data[i]));
                        }
                        this.fillOrganizationListTable(organizations);
                        this.setState({isLoading: false});
                    });
                } else {
                    refreshIfNeeded(orgJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        });
    }

    // Manda la petición de editar la organización al servidor.
    showOrgEditModal = () => {
        if (this.state.orgTypes.length === 0){
            this.props.showLoaderModal(this.props.strings.loaderLoading);
            getTypeOrganization().then(async orgTypesList => {
                const orgTypesJson = await orgTypesList.json();

                if (!orgTypesList.ok){
                    const error = orgTypesList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (orgTypesJson.hasOwnProperty('data')){
                    let orgTypes = [];
                    for (let i in orgTypesJson.data) {
                        orgTypes.push(new TypeOrganization(orgTypesJson.data[i]));
                    }
                    this.setState({orgTypes: orgTypes, showOrgModal: true}, () => {
                        this.fillOrgEditModal();
                    });
                    this.props.hideLoaderModal();
                } else {
                    refreshIfNeeded(orgTypesJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.props.hideLoaderModal();
            });
        } else {
            this.setState({showOrgModal: true}, () => {
                this.fillOrgEditModal();
            });
        }
    }

    // Rellena los campos del modal de edición de organización.
    fillOrgEditModal = () => {
        document.getElementById("editOrgName").value = this.state.selectedOrg.getName();
        document.getElementById("editOrgAlias").value = this.state.selectedOrg.getAlias();
        let orgOptions = "";
        for (let i in this.state.orgTypes){
            orgOptions += "<option value=" + this.state.orgTypes[i].getId() + " " + (this.state.orgTypes[i].getId() === this.state.selectedOrg.getSubtype().getId() ? "selected" : "") + ">" + this.state.orgTypes[i].getName() + "</option>";
        }
        document.getElementById("editOrgSubtype").innerHTML = orgOptions;
        document.getElementById("editOrgNl").value = this.state.selectedOrg.getNl();
        document.getElementById("editOrgNl").min = this.state.selectedOrg.getNl();
        document.getElementById("editOrgNm").value = this.state.selectedOrg.getNm();
        document.getElementById("editOrgNm").min = this.state.selectedOrg.getNm();
        document.getElementById("editOrgNe").value = this.state.selectedOrg.getNe();
        document.getElementById("editOrgNe").min = this.state.selectedOrg.getNe();
        document.getElementById("editOrgNf").value = this.state.selectedOrg.getNf();
        document.getElementById("editOrgNf").min = this.state.selectedOrg.getNf();
        this.setState({color1: this.state.selectedOrg.getColor1(), color2: this.state.selectedOrg.getColor2(), color3: this.state.selectedOrg.getColor3(),
            orgKeyDateB: this.state.selectedOrg.getPremiumOfflineEndDate(), orgKeyDateC: this.state.selectedOrg.getPremiumPhytoEndDate(),
            orgKeyDateD: this.state.selectedOrg.getPremiumAnalysisEndDate(), orgKeyDateE: this.state.selectedOrg.getPremiumStockEndDate()});
    }

    // Oculta el modal de edición de organizaciones.
    hideOrgEditModal = () => {
        document.getElementById("editOrgName").value = "";
        document.getElementById("editOrgAlias").value = "";
        document.getElementById("editOrgSubtype").value = "1";
        document.getElementById("editOrgNl").value = 0;
        document.getElementById("editOrgNl").min = 0;
        document.getElementById("editOrgNm").value = 0;
        document.getElementById("editOrgNm").min = 0;
        document.getElementById("editOrgNe").value = 0;
        document.getElementById("editOrgNe").min = 0;
        document.getElementById("editOrgNf").value = 0;
        document.getElementById("editOrgNf").min = 0;
        if (document.getElementById("uploadImageClear")) {
            document.getElementById("uploadImageClear").click();
        }
        document.getElementById("uploadImageInput").value = null;
        this.setState({showOrgModal: false, color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3, orgKeyDateB: undefined,
            orgKeyDateC: undefined,  orgKeyDateD: undefined, orgKeyDateE: undefined});
    }

    // Establece los campos a editar en la organización.
    setOrgData = () => {
        let orgSub;
        for (let i in this.state.orgTypes){
            if (this.state.orgTypes[i].getId().toString() === document.getElementById("editOrgSubtype").value){
                orgSub = new TypeOrganization(this.state.orgTypes[i]);
                break;
            }
        }
        let auxDate;
        let licensePremium = [];
        if (document.getElementById("orgKeyDateB").value !== "") {
            auxDate = document.getElementById("orgKeyDateB").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in this.state.selectedOrg.getPremium()){
                if (this.state.selectedOrg.getPremium()[i].getIdtp() === KTP.ID_OFFLINE_PREMIUM){
                    this.state.selectedOrg.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(this.state.selectedOrg.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_OFFLINE_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("orgKeyDateC").value !== "") {
            auxDate = document.getElementById("orgKeyDateC").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in this.state.selectedOrg.getPremium()){
                if (this.state.selectedOrg.getPremium()[i].getIdtp() === KTP.ID_PHYTOSANITARY_PREMIUM){
                    this.state.selectedOrg.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(this.state.selectedOrg.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_PHYTOSANITARY_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("orgKeyDateD").value !== "") {
            auxDate = document.getElementById("orgKeyDateD").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in this.state.selectedOrg.getPremium()){
                if (this.state.selectedOrg.getPremium()[i].getIdtp() === KTP.ID_ANALYSIS_PREMIUM){
                    this.state.selectedOrg.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(this.state.selectedOrg.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_ANALYSIS_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("orgKeyDateE").value !== "") {
            auxDate = document.getElementById("orgKeyDateE").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in this.state.selectedOrg.getPremium()){
                if (this.state.selectedOrg.getPremium()[i].getIdtp() === KTP.ID_STOCK_PREMIUM){
                    this.state.selectedOrg.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(this.state.selectedOrg.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_STOCK_PREMIUM, enddate: auxDate}));
            }
        }

        let org = new Organization({
            id: this.state.selectedOrg.getId(),
            name: document.getElementById("editOrgName").value,
            alias: document.getElementById("editOrgAlias").value,
            subtype: orgSub,
            nl: parseInt(document.getElementById("editOrgNl").value),
            nm: parseInt(document.getElementById("editOrgNm").value),
            ne: parseInt(document.getElementById("editOrgNe").value),
            nf: parseInt(document.getElementById("editOrgNf").value),
            color1: this.state.color1,
            color2: this.state.color2,
            color3: this.state.color3,
            premium: licensePremium,
        });
        this.loadOrgImg(org);

        return org;
    }

    // Manda la petición de editar la organización al servidor.
    editOrg = () => {
        let orgName = document.getElementById("editOrgName").value;
        if (orgName.length > 2 && orgName.length <= 200) {
            if (document.getElementById("editOrgNf").value >= this.state.selectedOrg.getNf()) {
                if (document.getElementById("editOrgNl").value >= this.state.selectedOrg.getNl()
                    || document.getElementById("editOrgNm").value >= this.state.selectedOrg.getNm()
                    || document.getElementById("editOrgNe").value >= this.state.selectedOrg.getNe()) {
                    if (document.getElementById("orgKeyDateC").value !== ""
                        || document.getElementById("orgKeyDateD").value !== ""
                        || document.getElementById("orgKeyDateE").value !== "") {
                        this.setState({showOrgModal: false}, () => {
                            this.props.showLoaderModal(this.props.strings.editingOrg);
                            let json = {};
                            json.data = this.setOrgData();
                            setTimeout(() => {
                                createOrganizationServer(json).then(async orgAsync => {
                                    const orgJson = await orgAsync.json();

                                    if (!orgAsync.ok) {
                                        const error = orgAsync.status;
                                        return Promise.reject(error);
                                    }

                                    this.setState({});
                                    if (orgJson.hasOwnProperty('data')) {
                                        let jobAsync = new JobAsync(orgJson.data);
                                        this.getJobAsyncEditOrg(jobAsync.id, this.props.globals.getMinTimeout());
                                    } else {
                                        refreshIfNeeded(orgJson, this.props.hideLoaderModal, this.props.showInfoModal);
                                    }
                                }).catch(error => {
                                    this.setState({});
                                    this.props.hideLoaderModal();
                                    this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                                    console.log(error);
                                });
                            }, 500);
                        });
                    } else {
                        this.props.showInfoModal(this.props.strings.orgPremiumModalErrorTitle, this.props.strings.orgPremiumModalErrorBody);
                    }
                } else {
                    this.props.showInfoModal(this.props.strings.orgLicensesModalErrorTitle, this.props.strings.orgLicensesModalErrorBody);
                }
            } else {
                this.props.showInfoModal(this.props.strings.orgNfModalErrorTitle, this.props.strings.orgNfModalErrorBody);
            }
        } else {
            this.props.showInfoModal(this.props.strings.orgNameModalErrorTitle, this.props.strings.orgNameModalErrorBody);
        }
    }

    // Petición asíncrona de editar organización.
    getJobAsyncEditOrg = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                setTimeout(() => {
                                    this.props.hideLoaderModal();
                                    window.location.reload();
                                }, 250);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.props.hideLoaderModal, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncEditOrg(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }, timeout);
    }

    // Manda la petición de eliminar la organización al servidor.
    deleteOrg = () => {
        this.props.showLoaderModal(this.props.strings.removingOrg);
        let json = {};
        json.data = this.state.selectedOrg;
        deleteOrgServer(json).then(async orgAsync => {
            const orgJson = await orgAsync.json();

            if (!orgAsync.ok) {
                const error = orgAsync.status;
                return Promise.reject(error);
            }

            this.setState({distId: "", distName: ""});
            if (orgJson.hasOwnProperty('data')) {
                setTimeout(() => {
                    this.props.hideLoaderModal();
                    window.location.reload();
                }, 250);
            } else {
                refreshIfNeeded(orgJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.hideLoaderModal();
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
        });
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor1Change = (color) => {
        this.setState({color1: color.hex});
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor2Change = (color) => {
        this.setState({color2: color.hex});
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor3Change = (color) => {
        this.setState({color3: color.hex});
    }

    // Realiza el reseteo de colores de la organización.
    handleResetColors = () => {
        this.setState({color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3});
    }

    // Limpia la fecha de premium offline de key de organización.
    clearOrgKeyDateB = () => {
        this.setState({orgKeyDateB: undefined});
    }

    // Limpia la fecha de premium fitosanitarios de key de organización.
    clearOrgKeyDateC = () => {
        this.setState({orgKeyDateC: undefined});
    }

    // Limpia la fecha de premium análisis de key de organización.
    clearOrgKeyDateD = () => {
        this.setState({orgKeyDateD: undefined});
    }

    // Limpia la fecha de premium stock de key de organización.
    clearOrgKeyDateE = () => {
        this.setState({orgKeyDateE: undefined});
    }

    // Carga el archivo como cadena en base64 para adjuntarla en la organización.
    loadOrgImg = (org) => {
        if (document.getElementById("uploadImageInput").files.length > 0) {
            const file = document.getElementById("uploadImageInput").files[0];
            // noinspection JSUnresolvedReference
            const blobURL = window.URL.createObjectURL(file);
            const img = new Image();
            img.src = blobURL;
            img.onload = () => {
                // noinspection JSUnresolvedReference
                window.URL.revokeObjectURL(blobURL);
                let maxSize = 800;
                const canvas = document.createElement('canvas');
                let finalW;
                let finalH;
                if (img.width > img.height) {
                    if (img.width > maxSize) {
                        let reduceFactor = img.width / maxSize;
                        finalW = maxSize;
                        finalH = img.height / reduceFactor;
                    } else {
                        finalW = img.width;
                        finalH = img.height;
                    }
                } else {
                    if (img.height > maxSize) {
                        let reduceFactor = img.height / maxSize;
                        finalH = maxSize;
                        finalW = img.width / reduceFactor;
                    } else {
                        finalW = img.width;
                        finalH = img.height;
                    }
                }
                canvas.width = finalW;
                canvas.height = finalH;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, finalW, finalH);

                canvas.toBlob((blob) => {
                    let reader = new FileReader();
                    reader.onload = (_file) => {
                        this.toDataURL(_file.target.result, (dataUrl) => {
                            org.image = dataUrl.split("base64,")[1];
                        });
                    }
                    reader.readAsDataURL(blob);
                }, 'image/png', 0.7);
            }
        }
    }

    // Convierte la imagen a base64 necesario para guardarse.
    toDataURL = (url, callback) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
            let reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // Petición inicial de descarga de licencias de organización.
    downloadOrgLicAsync = () => {
        this.props.showLoaderModal(this.props.strings.downloadingLicenses);
        getAsyncOrgLicenseServer(this.state.selectedOrg.getId()).then(async orgAsync => {
            const orgAsyncJson = await orgAsync.json();

            if (!orgAsync.ok){
                const error = orgAsync.status;
                return Promise.reject(error);
            }

            if (orgAsyncJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(orgAsyncJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncDownloadOrgLic(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(orgAsyncJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.props.hideLoaderModal();
        });
    }


    // Petición asíncrona de descarga de licencias de organización.
    getJobAsyncDownloadOrgLic = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.downloadOrg(jobAnswerJson);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.props.hideLoaderModal, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncDownloadOrgLic(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideProvinceLoader();
            });
        }, timeout);
    }

    // Descarga el excel con las licencias de la organización.
    downloadOrg = (orgLicensesJson) => {
        if (orgLicensesJson.hasOwnProperty('data')) {
            let licenses = [];
            for (let i in orgLicensesJson.data) {
                let license = new Key(orgLicensesJson.data[i]);
                licenses.push(license);
            }
            this.downloadOrgLicenses(licenses);
            this.props.hideLoaderModal();
        }
    }

    // Descarga el excel con las licencias de la organización
    downloadOrgLicenses = (licenses) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let dashboardExcelTitle = this.props.strings.licenseDownloadLicensesSheetTitle1 + this.state.selectedOrg.getName() + this.props.strings.excelFormat;
        // noinspection DuplicatedCode
        let dashboardExcel = new ExcelJS.Workbook();

        let worksheet = dashboardExcel.addWorksheet(this.props.strings.licenseDownloadOrgLicensesTitle, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        worksheet.columns = [
            {key: 'id', width: 25},
            {key: 'subtype', width: 25},
            {key: 'key', width: 25},
            {key: 'nseason', width: 25},
            {key: 'username', width: 25},
            {key: 'datetech', width: 25},
            {key: 'datephy', width: 25},
            {key: 'dateana', width: 25},
            {key: 'datestock', width: 25}
        ];
        worksheet.getRow(1).values = [this.props.strings.tableId, this.props.strings.tableLicenseSubtype, this.props.strings.tableKey, this.props.strings.tableNSeason,
            this.props.strings.tableKeyUser, this.props.strings.tablePremiumTech, this.props.strings.tablePremiumPhyto,
            this.props.strings.tablePremiumAnalysis, this.props.strings.tablePremiumStock];
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'cccccc'}
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });

        for (let i in licenses){
            let datephy = "";
            let datetech = "";
            let dateana = "";
            let datestock = "";

            // Licencias de la organización.
            for (let j in licenses[i].premium){
                if (licenses[i].premium[j].idtp === KTP.ID_PHYTOSANITARY_PREMIUM){
                    datephy = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_MANAGER_PREMIUM){
                    datetech = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_ANALYSIS_PREMIUM){
                    dateana = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_STOCK_PREMIUM){
                    datestock = licenses[i].premium[j].enddate.split(" ")[0];
                }
            }
            worksheet.addRow([licenses[i].getId(), licenses[i].getSubtype().getName(), licenses[i].getValue(), licenses[i].getNF(),
                (licenses[i].getUser() !== null ? licenses[i].getUser().getVisibleName() : ""), datetech, datephy, dateana, datestock]);
        }

        dashboardExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], {type: this.blobType});
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, dashboardExcelTitle);
        });
    }

    // Oculta el modal de edición de organización y abre el modal con la tabla de licencias de la organización.
    showOrgLicensesModal = () => {
        this.setState({showOrgModal: false}, () => {
            this.props.showLoaderModal(this.props.strings.loaderLoading);
            getOrgLicenseServer(this.state.selectedOrg.getId(), this.state.licPage, this.state.page_size).then(async orgLicensesList => {
                const orgLicensesJson = await orgLicensesList.json();
                this.setState({licMaxAmount: parseInt(orgLicensesList.headers.get("content-range").split(" ")[2])});

                if (!orgLicensesList.ok){
                    const error = orgLicensesList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (orgLicensesJson.hasOwnProperty('data')){
                    let licenses = [];
                    for (let i in orgLicensesJson.data){
                        let license = new Key(orgLicensesJson.data[i]);
                        licenses.push(license);
                    }
                    this.setState({orgLicenses: licenses, showOrgLicensesModal: true}, () => {
                        this.fillOrgLicListTable(licenses);
                        this.props.hideLoaderModal();
                    });
                } else {
                    this.props.hideLoaderModal();
                    refreshIfNeeded(orgLicensesJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                this.props.hideLoaderModal();
                console.log(error);
            });
        });
    }

    // Muestra el mensaje de cargando licencias cuando se cambia de página en la tabla de licencias de organización.
    tableLicLoader = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.orgLicenseLoading}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Realiza el cambio de página de la tabla de licencias de organización.
    onChangePageOrgLic = (page) => {
        this.setState({orgLicensesTable: [], licIsLoading: true, licPage: page-1}, () => {
            this.getOrgLicListPage();
        });
    }

    // Realiza la petición de la página de licencias de organización pedida en la tabla.
    getOrgLicListPage = () => {
        this.setState({orgLicensesTable: []}, () => {
            getOrgLicenseServer(this.state.selectedOrg.getId(), this.state.licPage, this.state.page_size, this.state.queryLic).then(async orgLicList => {
                const orgLicJson = await orgLicList.json();
                this.setState({maxLicAmount: parseInt(orgLicList.headers.get("content-range").split(" ")[2])});

                if (!orgLicList.ok){
                    const error = orgLicList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (orgLicJson.hasOwnProperty('data')){
                    this.setState({orgLicenses: []}, () => {
                        let licenses = [];
                        for (let i in orgLicJson.data) {
                            licenses.push(new Key(orgLicJson.data[i]));
                        }
                        this.setState({orgLicenses: licenses}, () => {
                            this.fillOrgLicListTable(licenses);
                        });
                    });
                } else {
                    refreshIfNeeded(orgLicJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        });
    }

    // Rellena la lista de licencias de organizaciones de la tabla.
    fillOrgLicListTable = (licenses) => {
        let newTableBody = this.state.orgLicensesTable;
        for (let i in licenses){
            let datephy = "";
            let datetech = "";
            let dateana = "";
            let datestock = "";

            // Licencias de la organización.
            for (let j in licenses[i].premium){
                if (licenses[i].premium[j].idtp === KTP.ID_PHYTOSANITARY_PREMIUM){
                    datephy = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_MANAGER_PREMIUM){
                    datetech = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_ANALYSIS_PREMIUM){
                    dateana = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_STOCK_PREMIUM){
                    datestock = licenses[i].premium[j].enddate.split(" ")[0];
                }
            }
            newTableBody.push({licid: licenses[i].getId(), licsubtype: licenses[i].getSubtype().getName(), lickey: licenses[i].getValue(),
                licnseason: licenses[i].getNF(), licuser: (licenses[i].getUser() !== null ? licenses[i].getUser().getVisibleName() : ""), licdatetech: datetech,
                licdatephy: datephy, licdateana: dateana, licdatestock: datestock});
        }
        this.setState({orgLicensesTable: newTableBody, licIsLoading: false});
    }

    // Confirma que se quieren eliminar las licencias seleccionadas.
    confirmOnMultipleDeleteOrgLic = (rowIds) => {
        this.showConfirmationModal(this.props.strings.confirmationModalRemoveLicenseTitle, this.props.strings.confirmationModalRemoveLicenseBody, () => this.onMultipleDeleteOrgLic(rowIds));
    }

    // Realiza la funciónd de borrado múltiples de la tabla de licencias de organización.
    onMultipleDeleteOrgLic = (rowIds) => {
        let promises = [];
        for (let i in this.state.orgLicenses){
            if (rowIds.includes(this.state.orgLicenses[i].id)){
                let json = {};
                json.data = this.state.orgLicenses[i];
                promises.push(deleteLicense(json));
            }
        }

        this.setState({showOrgLicensesModal: false});
        this.props.showLoaderModal(this.props.strings.removingLicenses);
        Promise.all(promises).then(() => {
            setTimeout(() => {
                this.props.hideLoaderModal();
                window.location.reload();
            }, 1000);
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
        });
    }

    // Limpia el modal con la tabla de licencias de la organización.
    clearOrgLicModal = () => {
        this.setState({orgLicenses: [], orgLicensesTable: [], showOrgLicensesModal: false, licIsLoading: false, licMaxAmount: 0, licPage: 0, queryLic: ""});
    }

    // Inicia la función de edición múltiple de la tabla de licencias de organización.
    onHeaderActionOrgLic = (type) => {
        let auxLicenses = [];
        for (let i in this.state.orgLicenses){
            let auxElem = document.getElementById("licid_" + this.state.orgLicenses[i].getId());
            if (auxElem !== null && auxElem.checked){
                auxLicenses.push(new Key(this.state.orgLicenses[i]));
            }
        }

        if (auxLicenses.length > 0) {
            this.setState({selectedEditLicenses: auxLicenses}, () => {
                if (type === "EDIT") {
                    this.multipleEditOrgLic();
                }
            });
        }
    }

    // Abre el modal de edición de licencias de organización.
    multipleEditOrgLic = () => {
        this.setState({showEditOrgLicModal: true, showOrgLicensesModal: false}, () => {
            if (this.state.selectedEditLicenses.length === 1){
                this.fillEditOrgLicModal();
            }
        })
    }

    // Establece los valores de la licencia en los campos del moadl de editar licencias de organización.
    fillEditOrgLicModal = () => {
        document.getElementById("licEditNf").value = this.state.selectedEditLicenses[0].getNF();

        let datephy = "";
        let dateana = "";
        let datestock = "";

        // Licencias de la organización.
        for (let j in this.state.selectedEditLicenses[0].getPremium()){
            if (this.state.selectedEditLicenses[0].getPremium()[j].idtp === KTP.ID_PHYTOSANITARY_PREMIUM){
                datephy = this.state.selectedEditLicenses[0].getPremium()[j].enddate.split(" ")[0];
                continue;
            }
            if (this.state.selectedEditLicenses[0].getPremium()[j].idtp === KTP.ID_ANALYSIS_PREMIUM){
                dateana = this.state.selectedEditLicenses[0].getPremium()[j].enddate.split(" ")[0];
                continue;
            }
            if (this.state.selectedEditLicenses[0].getPremium()[j].idtp === KTP.ID_STOCK_PREMIUM){
                datestock = this.state.selectedEditLicenses[0].getPremium()[j].enddate.split(" ")[0];
            }
        }
        this.setState({licEditDateC: datephy, licEditDateD: dateana, licEditDateE: datestock});
    }

    // Limpia los campos del modal de edición de licencias de la organización.
    clearEditOrgLicModal = () => {
        document.getElementById("licEditNf").value = 0;
        this.clearLicEditDateC();
        this.clearLicEditDateD();
        this.clearLicEditDateE();
        this.setState({showEditOrgLicModal: false, showOrgLicensesModal: true, selectedEditLicenses: []});
    }

    // Limpia la fecha de premium fitosanitarios de key de licencia de organización.
    clearLicEditDateC = () => {
        this.setState({licEditDateC: undefined});
    }

    // Limpia la fecha de premium análisis de key de licencia de organización.
    clearLicEditDateD = () => {
        this.setState({licEditDateD: undefined});
    }

    // Limpia la fecha de premium stock de key de licencia de organización.
    clearLicEditDateE = () => {
        this.setState({licEditDateE: undefined});
    }

    editOrgLic = () => {
        let promises = [];
        let licenses = [];
        for (let i in this.state.selectedEditLicenses){
            licenses.push(new Key(this.state.selectedEditLicenses[i]));
        }

        for (let i in licenses){
            licenses[i].setNF(parseInt(document.getElementById("licEditNf").value));

            let auxDate;
            let licensePremium = [];
            if (document.getElementById("licEditDateC").value !== "") {
                auxDate = document.getElementById("licEditDateC").value;
                auxDate += " 00:00:00";
                let found = false;
                for (let j in licenses[i].getPremium()){
                    if (licenses[i].getPremium()[j].getIdtp() === KTP.ID_PHYTOSANITARY_PREMIUM){
                        licenses[i].getPremium()[j].setEndDate(auxDate);
                        licensePremium.push(licenses[i].getPremium()[j]);
                        found = true;
                        break;
                    }
                }
                if (!found){
                    licensePremium.push(new KTP({idtp: KTP.ID_PHYTOSANITARY_PREMIUM, enddate: auxDate}));
                }
            }

            if (document.getElementById("licEditDateD").value !== "") {
                auxDate = document.getElementById("licEditDateD").value;
                auxDate += " 00:00:00";
                let found = false;
                for (let j in licenses[i].getPremium()){
                    if (licenses[i].getPremium()[j].getIdtp() === KTP.ID_ANALYSIS_PREMIUM){
                        licenses[i].getPremium()[j].setEndDate(auxDate);
                        licensePremium.push(licenses[i].getPremium()[j]);
                        found = true;
                        break;
                    }
                }
                if (!found){
                    licensePremium.push(new KTP({idtp: KTP.ID_ANALYSIS_PREMIUM, enddate: auxDate}));
                }
            }

            if (document.getElementById("licEditDateE").value !== "") {
                auxDate = document.getElementById("licEditDateE").value;
                auxDate += " 00:00:00";
                let found = false;
                for (let j in licenses[i].getPremium()){
                    if (licenses[i].getPremium()[j].getIdtp() === KTP.ID_STOCK_PREMIUM){
                        licenses[i].getPremium()[j].setEndDate(auxDate);
                        licensePremium.push(licenses[i].getPremium()[j]);
                        found = true;
                        break;
                    }
                }
                if (!found){
                    licensePremium.push(new KTP({idtp: KTP.ID_STOCK_PREMIUM, enddate: auxDate}));
                }
            }

            licenses[i].setPremium(licensePremium);
            let json = {};
            json.data = licenses[i];
            promises.push(createLicenseServer(json));
        }

        this.setState({showEditOrgLicModal: false});
        this.props.showLoaderModal(this.props.strings.editingLicenses);
        Promise.all(promises).then(() => {
            this.props.hideLoaderModal();
            window.location.reload();
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
        });
    }

    // Abre el modal de confirmación con los argumentos pasados como título, cuerpo y función de retorno.
    showConfirmationModal = (title, content, callback) => {
        this.setState({showModalConfirmation: true});
        // Se obtienen los dos números para la operación de confirmación.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        // Se muestra la información en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            // Se establece el evento onclick del botón de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmDownloadProvinceExcel(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok.
    confirmDownloadProvinceExcel = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        // Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        // Se comprueba el resultado y se redirige a la operación o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Oculta el modal de confirmación.
    hideConfirmationModal = () => {
        this.setState({showModalConfirmation: false});
    }

    // Realiza el filtrado de la tabla de licencias.
    onSearchLic = (query) => {
        this.setState({orgLicenses: [], orgLicensesTable: [], licIsLoading: true, licMaxAmount: 0, licPage: 0, queryLic: query}, () => {
            this.getOrgLicListPage();
        });
    }
}

export default OrganizationInfo;