// Clase del objeto Farm.
class Farm {
    // Constructor del objeto Farm.
    constructor(props){
        this.type = "Farm";
        this.id = (props.id !== undefined && props.id !== "" ? props.id : "-1");
        this.name = (props.name !== undefined ? props.name : "");
        this.alias = (props.alias !== undefined ? props.alias : "");
        this.siex = (props.siex !== undefined ? props.siex : 0);
        this.rea = (props.rea !== undefined ? props.rea : "");
        this.nif = (props.nif !== undefined ? props.nif : "");
    }

    // Devuelve el id de la explotación.
    getId = () => {
        return (this.id !== undefined && this.id !== "" ? this.id : "");
    }

    // Devuelve el nombre de la explotación.
    getName = () => {
        if (this.alias !== "") {
            return this.alias;
        } else if ((this.name !== "") && (this.rea !== "")) {
            return this.name + " (" + this.rea + ")";
        } else if ((this.name !== "") && this.nif !== "") {
            return this.name + " (" + this.nif + ")";
        } else if (this.name !== "") {
            return this.name;
        } else {
            return "Campaña desconocida";
        }
    }
}

export default Farm;