// Clase del objeto TypeMessage.
class TypeMessage {
    // Constructor del objeto TypeMessage.
    constructor(props) {
        this.type = "TypeMessage";
        this.id = (props.id !== "" ? props.id : "1");
        this.name = props.name;
        this.detail = props.detail;
    }
}

export default TypeMessage;