// Clase del objeto Role.
class Role {
    // Constructor del objeto Role.
    constructor(props){
        this.type = "Role";
        this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
        this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
        this.wf = (props.wf !== "" && props.wf !== undefined ? props.wf : "");
        this.wmu = (props.wmu !== "" && props.wmu !== undefined ? props.wmu : "");
        this.wwt = (props.wwt !== "" && props.wwt !== undefined ? props.wwt : "");
        this.wt = (props.wt !== "" && props.wt !== undefined ? props.wt : "");
        this.wl = (props.wl !== "" && props.wl !== undefined ? props.wl : "");
        this.ws = (props.ws !== "" && props.ws !== undefined ? props.ws : "");
        this.wi = (props.wi !== "" && props.wi !== undefined ? props.wi : "");
        this.wr = (props.wr !== "" && props.wr !== undefined ? props.wr : "");
    }

    // Devuelve el nombre del rol.
    getName = () => {
        return this.name;
    }

    // Devuelve el id del rol.
    getId = () => {
        return this.id;
    }
}

export default Role;