import DashboardDay from "./DashboardDay";

// Clase del objeto DashboardIncidence.
class DashboardIncidence {
    // Constructor del objeto DashboardIncidence.
    constructor(props) {
        this.type = "DdI";
        this.infection = [];
        for (let i in props.infection){
            this.infection.push(new DashboardDay(props.infection[i]));
        }
        this.fertilize = [];
        for (let i in props.fertilize){
            this.fertilize.push(new DashboardDay(props.fertilize[i]));
        }
        this.phytosanitary = [];
        for (let i in props.phytosanitary){
            this.phytosanitary.push(new DashboardDay(props.phytosanitary[i]));
        }
        this.state = [];
        for (let i in props.state){
            this.state.push(new DashboardDay(props.state[i]));
        }
        this.collect = [];
        for (let i in props.collect){
            this.collect.push(new DashboardDay(props.collect[i]));
        }
        this.work = [];
        for (let i in props.work){
            this.work.push(new DashboardDay(props.work[i]));
        }
        this.irrigation = [];
        for (let i in props.irrigation){
            this.irrigation.push(new DashboardDay(props.irrigation[i]));
        }
        this.analysis = [];
        for (let i in props.analysis){
            this.analysis.push(new DashboardDay(props.analysis[i]));
        }
        this.harvest = [];
        for (let i in props.harvest){
            this.harvest.push(new DashboardDay(props.harvest[i]));
        }
        this.shepherd = [];
        for (let i in props.shepherd){
            this.shepherd.push(new DashboardDay(props.shepherd[i]));
        }
        this.crop = [];
        for (let i in props.crop){
            this.crop.push(new DashboardDay(props.crop[i]));
        }
        this.note = [];
        for (let i in props.note){
            this.note.push(new DashboardDay(props.note[i]));
        }
    }

    // Obtiene la cantidad de infecciones.
    infoInfectionAmount = () => {
        let total = 0;
        for(let i in this.infection){
            total += this.infection[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de fertilizantes.
    infoFertilizeAmount = () => {
        let total = 0;
        for(let i in this.fertilize){
            total += this.fertilize[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de fitosanitarios.
    infoPhytosanitaryAmount = () => {
        let total = 0;
        for(let i in this.phytosanitary){
            total += this.phytosanitary[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de estados fenológicos.
    infoStateAmount = () => {
        let total = 0;
        for(let i in this.state){
            total += this.state[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de recolectas.
    infoCollectAmount = () => {
        let total = 0;
        for(let i in this.collect){
            total += this.collect[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de labores realizadas.
    infoWorkAmount = () => {
        let total = 0;
        for(let i in this.work){
            total += this.work[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de riego realizado.
    infoIrrigationAmount = () => {
        let total = 0;
        for(let i in this.irrigation){
            total += this.irrigation[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de análisis realizados.
    infoAnalysisAmount = () => {
        let total = 0;
        for(let i in this.analysis){
            total += this.analysis[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de cosechas comercializadas.
    infoHarvestAmount = () => {
        let total = 0;
        for(let i in this.harvest){
            total += this.harvest[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de pastoreos realizados.
    infoShepherdAmount = () => {
        let total = 0;
        for(let i in this.shepherd){
            total += this.shepherd[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de cultivos plantados.
    infoCropAmount = () => {
        let total = 0;
        for(let i in this.crop){
            total += this.crop[i].value;
        }
        return total;
    };

    // Obtiene la cantidad de notas añadidas.
    infoNoteAmount = () => {
        let total = 0;
        for(let i in this.note){
            total += this.note[i].value;
        }
        return total;
    };
}

export default DashboardIncidence;