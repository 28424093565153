import React, {Component} from "react";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import CustomButton from "../../component/CustomButton";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import {getJobAsync, getJobAsyncResult, updateBasicInfo, updatePhytoInfo, updateSigpacInfo, updateHashInfo} from "../../../logic/functions/ServerPetitions";
import JobAsync from "../../../logic/objects/JobAsync";

class ToolManagement extends Component{
    constructor(props){
        super(props);
        this.state = {showModalConfirmation: false, exitPetition: false};
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.toolTitle;
        endPreLoad();
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja el contenido de la página ToolManagement.
    render = () => {
        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.toolManagementPageTitle}/>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por provincias */}
                        <Collapsible triggerId={"collapseUpdateBasicInfoCardTrigger"} collapseId={"collapseUpdateBasicInfoCard"} cardTitle={this.props.strings.toolUpdateInfo} >
                            <div id="updateBasicInfoFormLoader" className="form-loader d-none"/>
                            <form id="updateBasicInfoForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.updateBasicInfoConfirmation()}>
                                                <span className="icon">
                                                  <i className="fas fa-hammer"/>
                                                </span>
                                                <span className="text">{this.props.strings.updateButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por provincias */}
                        <Collapsible triggerId={"collapseUpdatePhytosanitaryInfoCardTrigger"} collapseId={"collapseUpdatePhytosanitaryInfoCard"} cardTitle={this.props.strings.toolUpdatePhyto} >
                            <div id="updatePhytosanitaryInfoFormLoader" className="form-loader d-none"/>
                            <form id="updatePhytosanitaryInfoForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.updatePhytoConfirmation()}>
                                                <span className="icon">
                                                  <i className="fas fa-hammer"/>
                                                </span>
                                                <span className="text">{this.props.strings.updateButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por provincias */}
                        <Collapsible triggerId={"collapseUpdateSigpacInfoCardTrigger"} collapseId={"collapseUpdateSigpacInfoCard"} cardTitle={this.props.strings.toolUpdateSigpac} >
                            <div id="updateSigpacInfoFormLoader" className="form-loader d-none"/>
                            <form id="updateSigpacInfoForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.updateSigpacConfirmation()}>
                                                <span className="icon">
                                                  <i className="fas fa-hammer"/>
                                                </span>
                                                <span className="text">{this.props.strings.updateButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por provincias */}
                        <Collapsible triggerId={"collapseUpdateHashInfoCardTrigger"} collapseId={"collapseUpdateHashInfoCard"} cardTitle={this.props.strings.toolUpdateHash} >
                            <div id="updateHashInfoFormLoader" className="form-loader d-none"/>
                            <form id="updateHashInfoForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.updateHashConfirmation()}>
                                                <span className="icon">
                                                  <i className="fas fa-hammer"/>
                                                </span>
                                                <span className="text">{this.props.strings.updateButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                {/* Modal confirmación */}
                <Modal show={this.state.showModalConfirmation} backdrop="static" onHide={this.resetConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal confirmación */}
            </div>
        );
    }

    // Abre el modal de confirmación con los argumentos pasados como título, cuerpo y función de retorno.
    showConfirmationModal = (title, content, callback) => {
        this.setState({showModalConfirmation: true});
        // Se obtienen los dos numeros para la operación de confirmacion.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        // Se muestra la información en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            //Se establece el evento onclick del boton de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmUpdate(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok para enviar mensaje.
    confirmUpdate = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        // Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        // Se comprueba el resultado y se redirige a la operación o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Oculta el modal de confirmación.
    hideConfirmationModal = () => {
        this.setState({showModalConfirmation: false});
    }

    // Enseña el loader del formulario para actualizar información básica.
    showBasicLoader = () => {
        document.getElementById("updateBasicInfoFormLoader").classList.remove("d-none");
        document.getElementById("updateBasicInfoForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para actualizar información básica.
    hideBasicLoader = () => {
        document.getElementById("updateBasicInfoFormLoader").classList.add("d-none");
        document.getElementById("updateBasicInfoForm").classList.remove("d-none");
    }

    // Muestra el modal de confirmación con la funcionalidad de actualizar la información básica.
    updateBasicInfoConfirmation = () => {
        this.showConfirmationModal(this.props.strings.confirmationModalUpdateTitle, this.props.strings.confirmationModalUpdateBody, this.updateBasicInfoForm2Server);
    }

    // Actualiza la información básica en el servidor.
    updateBasicInfoForm2Server = () => {
        this.showBasicLoader();
        updateBasicInfo().then(async basicAsync => {
            const basicJson = await basicAsync.json();

            if (!basicAsync.ok) {
                const error = basicAsync.status;
                return Promise.reject(error);
            }

            if (basicJson.hasOwnProperty('data')) {
                let jobAsync = new JobAsync(basicJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncUpdateBasicInfo(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(basicJson, this.hideBasicLoader, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
            this.hideBasicLoader();
        });
    }

    // Petición asíncrona para actualizar información básica.
    getJobAsyncUpdateBasicInfo = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideBasicLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.hideBasicLoader();
                        });
                        this.hideBasicLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncUpdateBasicInfo(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideBasicLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideBasicLoader();
            });
        }, timeout);
    }

    // Enseña el loader del formulario para actualizar información fitosanitaria.
    showPhytoLoader = () => {
        document.getElementById("updatePhytosanitaryInfoFormLoader").classList.remove("d-none");
        document.getElementById("updatePhytosanitaryInfoForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para actualizar información fitosanitaria.
    hidePhytoLoader = () => {
        document.getElementById("updatePhytosanitaryInfoFormLoader").classList.add("d-none");
        document.getElementById("updatePhytosanitaryInfoForm").classList.remove("d-none");
    }

    // Muestra el modal de confirmación con la funcionalidad de actualizar la información fitosanitaria.
    updatePhytoConfirmation = () => {
        this.showConfirmationModal(this.props.strings.confirmationModalUpdateTitle, this.props.strings.confirmationModalUpdateBody, this.updatePhytoForm2Server);
    }

    // Actualiza la información fitosanitaria en el servidor.
    updatePhytoForm2Server = () => {
        this.showPhytoLoader();
        updatePhytoInfo().then(async phytoAsync => {
            const phytoJson = await phytoAsync.json();

            if (!phytoAsync.ok) {
                const error = phytoAsync.status;
                return Promise.reject(error);
            }

            if (phytoJson.hasOwnProperty('data')) {
                let jobAsync = new JobAsync(phytoJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncUpdatePhyto(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(phytoJson, this.hidePhytoLoader, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
            this.hidePhytoLoader();
        });
    }

    // Petición asíncrona para actualizar información fitosanitaria.
    getJobAsyncUpdatePhyto = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hidePhytoLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                        this.hidePhytoLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncUpdatePhyto(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hidePhytoLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hidePhytoLoader();
            });
        }, timeout);
    }

    // Enseña el loader del formulario para actualizar información sigpac.
    showSigpacLoader = () => {
        document.getElementById("updateSigpacInfoFormLoader").classList.remove("d-none");
        document.getElementById("updateSigpacInfoForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para actualizar información sigpac.
    hideSigpacLoader = () => {
        document.getElementById("updateSigpacInfoFormLoader").classList.add("d-none");
        document.getElementById("updateSigpacInfoForm").classList.remove("d-none");
    }

    // Muestra el modal de confirmación con la funcionalidad de actualizar la información sigpac.
    updateSigpacConfirmation = () => {
        this.showConfirmationModal(this.props.strings.confirmationModalUpdateTitle, this.props.strings.confirmationModalUpdateBody, this.updateSigpacForm2Server);
    }

    // Actualiza la información sigpac en el servidor.
    updateSigpacForm2Server = () => {
        this.showSigpacLoader();
        updateSigpacInfo().then(async sigpacAsync => {
            const sigpacJson = await sigpacAsync.json();

            if (!sigpacAsync.ok) {
                const error = sigpacAsync.status;
                return Promise.reject(error);
            }

            if (sigpacJson.hasOwnProperty('data')) {
                let jobAsync = new JobAsync(sigpacJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncUpdateSigpac(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(sigpacJson, this.hideSigpacLoader, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
            this.hideSigpacLoader();
        });
    }

    // Petición asíncrona para actualizar información sigpac.
    getJobAsyncUpdateSigpac = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideSigpacLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                        this.hideSigpacLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncUpdateSigpac(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideSigpacLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideSigpacLoader();
            });
        }, timeout);
    }

    // Enseña el loader del formulario para actualizar información hash.
    showHashLoader = () => {
        document.getElementById("updateHashInfoFormLoader").classList.remove("d-none");
        document.getElementById("updateHashInfoForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para actualizar información hash.
    hideHashLoader = () => {
        document.getElementById("updateHashInfoFormLoader").classList.add("d-none");
        document.getElementById("updateHashInfoForm").classList.remove("d-none");
    }

    // Muestra el modal de confirmación con la funcionalidad de actualizar la información hash.
    updateHashConfirmation = () => {
        this.showConfirmationModal(this.props.strings.confirmationModalUpdateTitle, this.props.strings.confirmationModalUpdateBody, this.updateHashForm2Server);
    }

    // Actualiza la información hash en el servidor.
    updateHashForm2Server = () => {
        this.showHashLoader();
        updateHashInfo().then(async hashAsync => {
            const hashJson = await hashAsync.json();

            if (!hashAsync.ok) {
                const error = hashAsync.status;
                return Promise.reject(error);
            }

            if (hashJson.hasOwnProperty('data')) {
                let jobAsync = new JobAsync(hashJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncUpdateHash(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(hashJson, this.hideHashLoader, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
            console.log(error);
            this.hideHashLoader();
        });
    }

    // Petición asíncrona para actualizar información hash.
    getJobAsyncUpdateHash = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data') && jobAnswerJson.data.hasOwnProperty('result') && jobAnswerJson.data['result'] === 'ok'){
                                this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideHashLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                        this.hideHashLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncUpdateHash(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideHashLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideHashLoader();
            });
        }, timeout);
    }
}

export default ToolManagement;