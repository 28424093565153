import React, {Component} from 'react';

// Crea un componente horizontal rule.
class Divider extends Component {
    constructor(props) {
        super(props);
        this.className = "sidebar-divider " + (props.additionalClass !== undefined ? props.additionalClass : "");
    }

    // Dibuja el horizontal rule.
    render() {
        return (
            <hr className={this.className} />
        );
    }
}

export default Divider;