class TypeInfection {
    constructor(props){
        this.type = "TypeInfection";
        if (props !== undefined && props !== null){
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
        } else {
            this.id = "-1";
            this.name = "";
        }
    }
}

export default TypeInfection;