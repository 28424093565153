// Clase del objeto TypeOrganization.

class TypeOrganization {
    // Constructor del objeto TypeOrganization.
    constructor(props) {
        this.type = "TypeOrganization";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
        } else {
            this.id = "-1";
            this.name = "";
        }
    }

    // Obtiene el id del tipo de organización.
    getId = () => {
        return this.id;
    }

    // Obtiene el nombre del tipo de organización.
    getName = () => {
        return this.name;
    }
}

export default TypeOrganization;