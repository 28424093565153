import React, {Component} from 'react';

class UploadImage extends Component {
    // Constructor de la clase UploadImage para cargar imágenes.
    constructor(props){
        super(props);
        this.state = {initialLoad: props.initialLoad, selectedImg: null};
    }

    // Limpia la imagen seleccionada.
    clearData = () => {
        this.setState({selectedImg: null, initialLoad: false});
        document.getElementById("uploadImageInput").value = null;
    };

    // noinspection JSUnresolvedReference
    render = () => (
        <div>
            {(this.state.initialLoad || this.state.selectedImg) && (<div className="row justify-content-center">
                <div className="col-12 d-flex justify-content-center">
                    <img id="uploadImageImg" className="load-img-bg" alt="Logo de organización" width={"350px"} onError={() => {this.clearData()}} src={(this.state.initialLoad ? this.props.strings.API_URL + "images/logo/" + this.props.orgId + ".png" : URL.createObjectURL(this.state.selectedImg))} />
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <button id="uploadImageClear" className="btn btn-danger my-3" onClick={() => this.clearData()}>
                        <i className="fas fa-trash" />
                    </button>
                </div>
            </div>)}
            <div className="row justify-content-center">
                <input id="uploadImageInput" accept="image/*" className="btn btn-primary" type="file" onChange={(event) => {
                    this.setState({selectedImg: event.target.files[0], initialLoad: false});
                }} />
            </div>
        </div>
    );
}

export default UploadImage;