import React, {Component} from "react";
// noinspection ES6CheckImport
import {Button} from "react-floating-action-button";
import PageTitle from "../../component/PageTitle";
import {
    getCompanyNoPag,
    getCropListFilter, getMeasure,
    getPhytoTPCI, getProblemListFilter, getSubstanceListFilter, getTypeProtection,
    getVademecum
} from "../../../logic/functions/ServerPetitions";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import ReactAsyncTable from "react-async-table";
import TypePhytosanitary from "../../../logic/objects/TypePhytosanitary";
import TypePhytosanitaryCropInfection from "../../../logic/objects/TypePhytosanitaryCropInfection";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import CustomButton from "../../component/CustomButton";
import FormGroup from "react-bootstrap/FormGroup";
import Input from "../../component/Input";
import AsyncTypeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import TypeCrop from "../../../logic/objects/TypeCrop";
import Infection from "../../../logic/objects/Infection";
import Company from "../../../logic/objects/Company";
import Substance from "../../../logic/objects/Substance";
import Measure from "../../../logic/objects/Measure";
import TypeProtection from "../../../logic/objects/TypeProtection";

class PhytosanitaryInfo extends Component {
    // Constructor de clase PhytosanitaryInfo.
    constructor(props) {
        super(props);
        this.header = [{
            text: this.props.strings.phytosanitaryTableKey,
            dataField: 'key',
            columnHeaderClass: 'd-none',
            columnDataClass: 'd-none'
        },{
            text: this.props.strings.phytosanitaryTableId,
            dataField: 'id',
            columnHeaderClass: 'd-none',
            columnDataClass: 'd-none'
        },{
            text: this.props.strings.phytosanitaryTableNReg,
            dataField: 'nreg'
        }, {
            text: this.props.strings.phytosanitaryTableName,
            dataField: 'name'
        }, {
            text: this.props.strings.phytosanitaryTableSubs,
            dataField: 'subs'
        }, {
            text: this.props.strings.phytosanitaryTableComp,
            dataField: 'comp'
        }, {
            text: this.props.strings.phytosanitaryTableMix,
            dataField: 'mix'
        }, {
            text: this.props.strings.phytosanitaryTableEDate,
            dataField: 'edate'
        },{
            text: this.props.strings.phytosanitaryTableCDate,
            dataField: 'cdate'
        }];
        this.cropList = [];
        this.problemList = [];
        this.subsList = [];
        this.compList = [];
        this.state = {tablebody: [], petitionended: false, maxamount: 0, page_size: 20, page: 0,
            isLoading: true, query: "", phytoPdfId: {}, phytoCI: {}, showFilterModal: false, isCropLoading: false,
            cropOptions: [], isProblemLoading: false, problemOptions: [], isSubsLoading: false, subsOptions: [],
            isCompLoading: false, compOptions: [], selectedName: "", selectedCrop: "", selectedSubs: "",
            selectedProblem : "", selectedComp: "", showInfoModal: false, allCrops: [], allProblem: [],
            allProtection: [], allDoseMeasures: [], allSoupMeasures: [], isInnerCropLoading: false, isInnerProblemLoading: false,
            cropInnerOptions: [], problemInnerOptions: [], selectedInnerCrop: "", selectedInnerProblem: "", lastPhytoId: ""};

        this.rowActions = ({onAction}) => (
            <span>
                <i className="fas fa-fw fa-info-circle table-icon mr-2" onClick={(e) => onAction(e, 'INFO')} />
                <i className="far fa-fw fa-file-pdf table-icon" onClick={(e) => onAction(e, 'PDF')} />
            </span>
        );
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.phytosanitaryTitle;
        getVademecum(this.state.page_size, this.state.page, this.state.query).then(async phytoList => {
            const phytoJson = await phytoList.json();
            this.setState({maxamount: parseInt(phytoList.headers.get("content-range").split(" ")[2])});

            if (!phytoList.ok){
                const error = phytoList.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (phytoJson.hasOwnProperty('data')){
                let phytos = [];
                let newPdfIds = {}
                for (let i in phytoJson.data) {
                    phytos.push(new TypePhytosanitary(phytoJson.data[i]));
                    newPdfIds[phytoJson.data[i].id] = phytoJson.data[i].idpdf;
                }
                this.fillPhytosanitaryListTable(phytos);
                this.setState({isLoading: false, phytoPdfId: Object.assign(this.state.phytoPdfId, newPdfIds)});
            } else {
                refreshIfNeeded(phytoJson, null, this.props.showInfoModal);
            }
            endPreLoad();
        }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                endPreLoad();
            }
        );
    }

    // Dibuja el contenido de la página PhytosanitaryInfo.
    render = () => {
        let table = '';
        if (this.state.petitionended){
            table = <ReactAsyncTable keyField={"key"} columns={this.header} items={this.state.tablebody} isLoading={this.state.isLoading}
                                     currentPage={this.state.page+1} itemsPerPage={this.state.page_size} totalItems={this.state.maxamount}
                                     onChangePage={this.onChangePage} loader={this.tableLoader} delay={this.props.globals.getAsyncDelay()}
                                     actionsComponent={this.rowActions} onAction={this.onAction} options={{
                                         actionsColumn: true,
                                         searchBox: false
                                     }}
                                     translations={{
                                         noDataText: this.props.strings.phytosanitaryTableNotFound,
                                         requestFailedText: this.props.strings.tableSearchError,
                                         paginationFirst: this.props.strings.tablePaginationFirst,
                                         paginationLast: this.props.strings.tablePaginationLast,
                                         actionsColumnTitle: ""
                                     }}/>;
        }

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.phytosanitaryInfoPageTitle}/>

                <div className="card shadow mb-4">
                    <div id="phytosanitaryListTable" className="card-body">
                        {table}
                    </div>
                </div>

                <Button className="fab" icon="fas fa-fw fa-search" onClick={this.showFilterModal} />

                {/* Modal Filter */}
                <Modal show={this.state.showFilterModal} backdrop="static" onHide={this.hideFilterModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>{this.props.strings.phytosanitaryModalFilterTitle}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <FormGroup>
                                        <Input id={"filter-name"} type={"text"} ph={this.props.strings.phytosanitaryFilterName} />
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup>
                                        <AsyncTypeahead id="filter-crop" placeholder={this.props.strings.phytosanitaryFilterCrop} useCache={false} onInputChange={this.resetCropOptions}
                                                        inputProps={{
                                                            id: 'filter-crop-input',
                                                        }}
                                                        isLoading={this.state.isCropLoading} minLength={3} onSearch={this.getAutocompleteCrop} onChange={this.setCropFormFill}
                                                        delay={this.props.globals.getAsyncDelay()} filterBy={this.filterCropFunction} options={this.state.cropOptions} labelKey="name"
                                                        renderMenuItemChildren={(option) => (
                                                            <span>{option.name}</span>
                                                        )} />
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup>
                                        <AsyncTypeahead id="filter-problem" placeholder={this.props.strings.phytosanitaryFilterProblem} useCache={false} onInputChange={this.resetProblemOptions}
                                                        inputProps={{
                                                            id: 'filter-problem-input',
                                                        }}
                                                        isLoading={this.state.isProblemLoading} minLength={3} onSearch={this.getAutocompleteProblem} onChange={this.setProblemFormFill}
                                                        delay={this.props.globals.getAsyncDelay()} filterBy={this.filterNameFunction} options={this.state.problemOptions} labelKey="name"
                                                        renderMenuItemChildren={(option) => (
                                                            <span>{option.name}</span>
                                                        )} />
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup>
                                        <AsyncTypeahead id="filter-subs" placeholder={this.props.strings.phytosanitaryFilterSubs} useCache={false} onInputChange={this.resetSubsOptions}
                                                        inputProps={{
                                                            id: 'filter-subs-input',
                                                        }}
                                                        isLoading={this.state.isSubsLoading} minLength={3} onSearch={this.getAutocompleteSubs} onChange={this.setSubsFormFill}
                                                        delay={this.props.globals.getAsyncDelay()} filterBy={this.filterNameFunction} options={this.state.subsOptions} labelKey="name"
                                                        renderMenuItemChildren={(option) => (
                                                            <span>{option.name}</span>
                                                        )} />
                                    </FormGroup>
                                </div>
                                <div className="col-6">
                                    <FormGroup>
                                        <AsyncTypeahead id="filter-comp" placeholder={this.props.strings.phytosanitaryFilterComp} useCache={false} onInputChange={this.resetCompOptions}
                                                        inputProps={{
                                                            id: 'filter-comp-input',
                                                        }}
                                                        isLoading={this.state.isCompLoading} minLength={3} onSearch={this.getAutocompleteComp} onChange={this.setCompFormFill}
                                                        delay={this.props.globals.getAsyncDelay()} filterBy={this.filterNameFunction} options={this.state.compOptions} labelKey="name"
                                                        renderMenuItemChildren={(option) => (
                                                            <span>{option.name}</span>
                                                        )} />
                                    </FormGroup>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.phytosanitaryClearButton} parentFunction={this.clearFilters}/>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.hideFilterModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.searchButton} parentFunction={this.applyFilters}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Filter */}

                {/* Modal Filter */}
                <Modal show={this.state.showInfoModal} backdrop="static" onHide={this.hideInfoModal} dialogClassName="modal-create" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            <span id="modalInfoTitle">
                            </span>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6">
                                <FormGroup>
                                    <AsyncTypeahead id="filter-inner-crop" placeholder={this.props.strings.phytosanitaryFilterCrop} useCache={false} onInputChange={this.resetCropInnerOptions}
                                                    isLoading={this.state.isInnerCropLoading} minLength={3} onSearch={this.getInnerAutocompleteCrop} onChange={this.setInnerCropFormFill}
                                                    delay={this.props.globals.getAsyncDelay()} filterBy={this.filterCropFunction} options={this.state.cropInnerOptions} labelKey="name"
                                                    renderMenuItemChildren={(option) => (
                                                        <span>{option.name}</span>
                                                    )} />
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <FormGroup>
                                    <AsyncTypeahead id="filter-inner-problem" placeholder={this.props.strings.phytosanitaryFilterProblem} useCache={false} onInputChange={this.resetProblemInnerOptions}
                                                    isLoading={this.state.isInnerProblemLoading} minLength={3} onSearch={this.getInnerAutocompleteProblem} onChange={this.setInnerProblemFormFill}
                                                    delay={this.props.globals.getAsyncDelay()} filterBy={this.filterNameFunction} options={this.state.problemInnerOptions} labelKey="name"
                                                    renderMenuItemChildren={(option) => (
                                                        <span>{option.name}</span>
                                                    )} />
                                </FormGroup>
                            </div>
                        </div>
                        <div id="modalInfoContent">
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideInfoModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Filter */}
            </div>
        );
    }

    // Rellena la lista de fitosanitarios de la tabla.
    fillPhytosanitaryListTable = (phytos) => {
        for (let i in phytos){
            let newTableBody = this.state.tablebody;
            newTableBody.push({key: phytos[i].getId() + " " + phytos[i].getName(), id: phytos[i].getId(), nreg: phytos[i].getCode(), name: phytos[i].getName(), subs: phytos[i].getSubsName(),
                comp: phytos[i].getCompName(), mix: (phytos[i].getMixable() === 1 ? 'SÍ' : 'NO'), edate: phytos[i].getEDate(),
                cdate: phytos[i].getCDate()});
            this.setState({tablebody: newTableBody});
        }
        this.setState({petitionended: true});
    }



    // Muestra el mensaje de cargando usuarios cuando se cambia de página en la tabla.
    tableLoader = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.phytosanitaryLoadingPhytos}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Realiza el cambio de página de la tabla.
    onChangePage = (page) => {
        this.setState({tablebody: [], isLoading: true, page: page-1}, () => {
            this.getPhytoListPage();
        });
    }

    // Inicia las funciones de visualizar información o descargar pdf del vademecum.
    onAction = (type, row) => {
        if (type === "INFO"){
            if (row.id !== "" && row.id !== undefined){
                this.getPhytoInfo(row.id, row.name);
            } else {
                this.props.showInfoModal(this.props.strings.phytosanitaryInfoErrorTitle, this.props.strings.phytosanitaryInfoErrorBody);
            }
        } else {
            if (this.state.phytoPdfId[row.id] !== undefined) {
                this.getPhytoPdf(this.state.phytoPdfId[row.id]);
            } else {
                this.props.showInfoModal(this.props.strings.phytosanitaryPdfErrorTitle, this.props.strings.phytosanitaryPdfErrorBody);
            }
        }
    }

    // Realiza la petición de la página de vademecum pedida en la tabla.
    getPhytoListPage = () => {
        this.setState({tablebody: []}, () => {
            getVademecum(this.state.page_size, this.state.page, this.state.query).then(async phytoList => {
                const phytoJson = await phytoList.json();
                this.setState({maxamount: parseInt(phytoList.headers.get("content-range").split(" ")[2])});

                if (!phytoList.ok){
                    const error = phytoList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (phytoJson.hasOwnProperty('data')){
                    let phytos = [];
                    let newPdfIds = {}
                    for (let i in phytoJson.data) {
                        phytos.push(new TypePhytosanitary(phytoJson.data[i]));
                        newPdfIds[phytoJson.data[i].id] = phytoJson.data[i].idpdf;
                    }
                    this.fillPhytosanitaryListTable(phytos);
                    this.setState({isLoading: false, phytoPdfId: Object.assign(this.state.phytoPdfId, newPdfIds)});
                } else {
                    refreshIfNeeded(phytoJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                endPreLoad();
            });
        });
    }

    // Obtiene la información del tratamiento con el id pasado.
    getPhytoInfo = (phytoId, phytoName) => {
        if (this.state.phytoCI[phytoId] === undefined) {
            this.props.showLoaderModal(this.props.strings.downloadingPhytoInfo);
            getPhytoTPCI(phytoId).then(async phytoInfoList => {
                const phytoInfoJson = await phytoInfoList.json();

                if (!phytoInfoList.ok) {
                    const error = phytoInfoList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (phytoInfoJson.hasOwnProperty('data')) {
                    let newPhytoCI = {};
                    let tpci = [];
                    for (let i in phytoInfoJson.data) {
                        tpci.push(new TypePhytosanitaryCropInfection(phytoInfoJson.data[i]));
                    }
                    newPhytoCI[phytoId] = tpci;
                    Promise.all([
                        this.getTypecropsPromise(),
                        this.getProblemsPromise(),
                        this.getProtectionPromise(),
                        this.getMeasurePromise()
                    ]).then(() => {
                        this.props.hideLoaderModal();
                        this.setState({phytoCI: Object.assign(this.state.phytoCI, newPhytoCI), showInfoModal: true, lastPhytoId: phytoId}, () => {
                            setTimeout( () => {
                                document.getElementById("modalInfoTitle").innerText = "Información de " + phytoName
                                this.fillInfoModalContent(phytoId);
                            }, this.props.globals.getModalShowDelay());
                        });
                    });
                } else {
                    refreshIfNeeded(phytoInfoJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.hideLoaderModal();
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        } else {
            this.setState({showInfoModal: true}, () => {
                setTimeout( () => {
                    document.getElementById("modalInfoTitle").innerText = "Información de " + phytoName
                    this.fillInfoModalContent(phytoId);
                }, this.props.globals.getModalShowDelay());
            });
        }
    }

    // Descarga el pdf del fito seleccionado en la tabla.
    getPhytoPdf = (idpdf) => {
        let form = document.createElement("form");
        form.target = "view";
        form.method = "POST";
        form.action = 'https://servicio.mapa.gob.es/regfiweb/Productos/ExportFichaProductoPdf?idProducto=' + idpdf;
        document.body.appendChild(form);
        form.submit();
        window.open('', 'view');
    }

    // Abre el modal de filtro de fitosanitarios.
    showFilterModal = () => {
        this.setState({showFilterModal: true}, () => {
            setTimeout( () => {
                document.getElementById("filter-name").value = (this.state.selectedName);
                document.getElementById("filter-crop-input").value = (this.state.selectedCrop !== "" ? this.state.selectedCrop.getName() : "");
                document.getElementById("filter-problem-input").value = (this.state.selectedProblem !== "" ? this.state.selectedProblem.getName() : "");
                document.getElementById("filter-comp-input").value = (this.state.selectedComp !== "" ? this.state.selectedComp.getName() : "");
                document.getElementById("filter-subs-input").value = (this.state.selectedSubs !== "" ? this.state.selectedSubs.getName() : "");
            }, this.props.globals.getModalShowDelay());
        });
    }

    // Cierra el modal de filtro de fitosanitarios.
    hideFilterModal = () => {
        this.setState({showFilterModal: false});
    }

    // Aplica los filtros indicados en la búsqueda de fitosanitarios.
    applyFilters = () => {
        let queryValue = "";
        let name = document.getElementById("filter-name").value;
        if (name !== ""){
            queryValue += '&qg1[or]=name,code&name[in]=' + name + '&code[in]=' + name;
        }
        if (this.state.selectedSubs !== ""){
            queryValue += '&qg2[and]=substance&substance[eq]=' + this.state.selectedSubs.getId();
        }
        if (this.state.selectedCrop !== ""){
            queryValue += '&qg3[and]=typecrop&typecrop[eq]=' + this.state.selectedCrop.getId();
        }
        if (this.state.selectedProblem !== ""){
            queryValue += '&qg4[and]=infection&infection[eq]=' + this.state.selectedProblem.getId();
        }
        if (this.state.selectedComp !== ""){
            queryValue += '&qg5[and]=company&company[eq]=' + this.state.selectedComp.getId();
        }
        this.setState({query: queryValue, page: 0, selectedName: name, showFilterModal: false, isLoading: true}, () => {
            this.getPhytoListPage();
        });
    }

    // Limpia los filtros utilizados en el modal de filtros.
    clearFilters = () => {
        this.setState({query: "", page: 0, showFilterModal: false, selectedName: "",
                            selectedCrop: "", selectedSubs: "", selectedProblem: "", selectedComp: "", isLoading: true}, () => {
            this.getPhytoListPage();
        });
    }

    // Realiza el filtrado de cultivos por nombre y código.
    filterCropFunction = (item, props) => {
        return item.name.toString().toLowerCase().includes(props.text.toString().toLowerCase()
                || item.code.toString().toLowerCase().includes(props.text.toString().toLowerCase()));
    }

    // Realiza la petición de cultivos con el texto introducido en el input de filtros de cultivo.
    getAutocompleteCrop = (query) => {
        getCropListFilter(query).then(async cropList => {
            this.setState({isCropLoading: true});
            const cropListJson = await cropList.json();

            if (!cropList.ok){
                const error = cropList.status;
                return Promise.reject(error);
            }

            if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0){
                let options = [];
                this.cropList = [];
                for (let i in cropListJson.data){
                    const crop = new TypeCrop(cropListJson.data[i]);
                    options.push({id: crop.getId(), name: crop.getName(), code: crop.getCode()});
                    this.cropList.push(crop);
                }
                this.setState({cropOptions: options});
            } else {
                refreshIfNeeded(cropListJson, null, this.props.showInfoModal);
            }
            this.setState({isCropLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCropLoading: false});
        });
    }

    // Establece el tipo de cultivo seleccionado en el filtro de cultivos.
    setCropFormFill = (items) => {
        for (let i in this.cropList){
            if (items.length > 0) {
                if (items[0].id === this.cropList[i].getId()) {
                    this.setState({selectedCrop: new TypeCrop(this.cropList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de cultivo.
    resetCropOptions = () => {
        this.setState({cropOptions: []});
    }

    // Realiza el filtrado de elemento por nombre.
    filterNameFunction = (item, props) => {
        return item.name.toString().toLowerCase().includes(props.text.toString().toLowerCase());
    }

    // Realiza la petición de problemas con el texto introducido en el input de filtros de problemas.
    getAutocompleteProblem = (query) => {
        getProblemListFilter(query).then(async problemList => {
            this.setState({isProblemLoading: true});
            const problemListJson = await problemList.json();

            if (!problemList.ok){
                const error = problemList.status;
                return Promise.reject(error);
            }

            if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0){
                let options = [];
                this.problemList = [];
                for (let i in problemListJson.data){
                    const problem = new Infection(problemListJson.data[i]);
                    options.push({id: problem.getId(), name: problem.getName()});
                    this.problemList.push(problem);
                }
                this.setState({problemOptions: options});
            } else {
                refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
            }
            this.setState({isProblemLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isProblemLoading: false});
        });
    }

    // Establece el problema seleccionado en el filtro de problemas.
    setProblemFormFill = (items) => {
        for (let i in this.problemList){
            if (items.length > 0) {
                if (items[0].id === this.problemList[i].getId()) {
                    this.setState({selectedProblem: new Infection(this.problemList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de problemas.
    resetProblemOptions = () => {
        this.setState({problemOptions: []});
    }

    // Realiza la petición de materia activa con el texto introducido en el input de filtros de materia activa.
    getAutocompleteSubs = (query) => {
        getSubstanceListFilter(query).then(async subsList => {
            this.setState({isSubsLoading: true});
            const subsListJson = await subsList.json();

            if (!subsList.ok){
                const error = subsList.status;
                return Promise.reject(error);
            }

            if (subsListJson.hasOwnProperty('data') && subsListJson.data.length > 0){
                let options = [];
                this.subsList = [];
                for (let i in subsListJson.data){
                    const subs = new Substance(subsListJson.data[i]);
                    this.subsList.push(subs);
                    options.push({id: subs.getId(), name: subs.getName()});
                }
                this.setState({subsOptions: options});
            } else {
                refreshIfNeeded(subsListJson, null, this.props.showInfoModal);
            }
            this.setState({isSubsLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isSubsLoading: false});
        });
    }

    // Establece la materia activa seleccionada en el filtro de materia activa.
    setSubsFormFill = (items) => {
        for (let i in this.subsList){
            if (items.length > 0) {
                if (items[0].id === this.subsList[i].getId()) {
                    this.setState({selectedSubs: new Substance(this.subsList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de sustancias.
    resetSubsOptions = () => {
        this.setState({subsOptions: []});
    }

    // Realiza la petición de casa comercial con el texto introducido en el input de filtros de casa comercial.
    getAutocompleteComp = (query) => {
        getCompanyNoPag(query).then(async compList => {
            this.setState({isCompLoading: true});
            const compListJson = await compList.json();

            if (!compList.ok){
                const error = compList.status;
                return Promise.reject(error);
            }

            if (compListJson.hasOwnProperty('data') && compListJson.data.length > 0){
                let options = [];
                this.compList = [];
                for (let i in compListJson.data){
                    const comp = new Company(compListJson.data[i]);
                    this.compList.push(comp);
                    options.push({id: comp.getId(), name: comp.getName()});
                }
                this.setState({compOptions: options});
            } else {
                refreshIfNeeded(compListJson, null, this.props.showInfoModal);
            }
            this.setState({isCompLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isCompLoading: false});
        });
    }

    // Establece la casa comercial seleccionada en el filtro de casa comercial.
    setCompFormFill = (items) => {
        for (let i in this.compList){
            if (items.length > 0) {
                if (items[0].id === this.compList[i].getId()) {
                    this.setState({selectedComp: new Company(this.compList[i])});
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de casa comercial.
    resetCompOptions = () => {
        this.setState({compOptions: []});
    }

    // Cierra el modal de información de fitosanitarios.
    hideInfoModal = () => {
        this.setState({showInfoModal: false, selectedInnerCrop: "", selectedInnerProblem: "", lastPhytoId: ""});
    }

    // Rellena el cuerpo del modal de información con los CI del fitosanitario.
    fillInfoModalContent = (phytoId) => {
        let content = "";
        for (let i in this.state.phytoCI[phytoId]){
            content += this.fillCIContent(this.state.phytoCI[phytoId][i]);
        }
        document.getElementById("modalInfoContent").innerHTML = content;
    }

    // Rellena la información de cada CI del fitosanitario.
    fillCIContent = (ci) => {
        let crop = '';
        let problem = '';
        let doseM = '';
        let minDoseM = '';
        let soupM = '';
        let protection = '';
        for (let i in this.state.allCrops) {
            if (this.state.allCrops[i].id === ci.idtc.toString()) {
                crop = this.state.allCrops[i].name;
                break;
            }
        }
        for (let i in this.state.allProblem) {
            if (this.state.allProblem[i].id === ci.idi.toString()) {
                problem = this.state.allProblem[i].name;
                break;
            }
        }
        for (let i in this.state.allDoseMeasures) {
            if (this.state.allDoseMeasures[i].id === ci.md.toString()) {
                doseM = this.state.allDoseMeasures[i].symbol;
                minDoseM = this.state.allDoseMeasures[i].symbol;
            }
        }
        for (let i in this.state.allSoupMeasures) {
            if (this.state.allSoupMeasures[i].id === ci.ms.toString()) {
                soupM = this.state.allSoupMeasures[i].symbol;
                break;
            }
        }
        if (ci.tp !== null) {
            for (let i in this.state.allProtection) {
                if (this.state.allProtection[i].id === ci.tp.toString()) {
                    protection = this.state.allProtection[i].name;
                    break;
                }
            }
        } else {
            protection = this.props.strings.unspecified;
        }
        return '<div class="messageroundbox mb-5 p-0"><div class="row"><div class="col-6"><span class="green-text"><b>Cultivo: </b></span><b>' + crop + '</b></div><div class="col-6"><span class="green-text"><b>Problema: </b></span><b>' + problem + '</b></div></div>' +
            '<div class="row border-bottom"><div class="col-6 d-flex justify-content-center"></div><div class="col-6 d-flex justify-content-center"></div></div>' +
            '<div class="row"><div class="col-3"><b>Dosis mínima</b></div><div class="col-3"><b>Dosis máxima</b></div><div class="col-3"><b>Caldo mínimo</b></div><div class="col-3"><b>Caldo máximo</b></div></div>' +
            '<div class="row border-bottom"><div class="col-3">' + ci.mnd + " " + doseM + '</div><div class="col-3">' + ci.mxd + " " + doseM + '</div><div class="col-3">' + ci.mns + " " + soupM + '</div><div class="col-3">' + ci.mxs + " " + soupM + '</div></div>' +
            '<div class="row"><div class="col-3"><b>Nº mín. aplic.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Número mínimo de aplicaciones"></i></div><div class="col-3"><b>Nº máx. aplic.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Número máximo de aplicaciones"></i></div>' +
            '<div class="col-3"><b>Dosis aplic. mín.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Dosis de aplicación mínima"></i></div><div class="col-3"><b>Dosis aplic. máx.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Dosis de aplicación máxima"></i></div></div>' +
            '<div class="row border-bottom"><div class="col-3">' + ci.mna + '</div><div class="col-3">' + ci.mxa + '</div><div class="col-3">' + ci.mnda + " " + minDoseM + '</div><div class="col-3">' + ci.mxda + " " + minDoseM + '</div></div>' +
            '<div class="row"><div class="col-3"><b>Intervalo mín. aplic.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Intervalo mínimo de aplicación"></i></div><div class="col-3"><b>Intervalo máx. aplic.</b><i class="mdi mdi-18px mdi-information-outline green-text" data-toggle="tooltip" data-placement="top" title="Intervalo máximo de aplicación"></i></div><div class="col-3"><b>Límite seguridad</b></div><div class="col-3"><b>Tipo de protección</b></div></div>' +
            '<div class="row"><div class="col-3">' + ci.mni + ' días</div><div class="col-3">' + ci.mxi + ' días</div><div class="col-3">' + ci.s + ' días</div><div class="col-3">' + protection + '</div></div></div>';
    }

    // Devuelve la promise de la petición de tipos de cultivo.
    getTypecropsPromise = () => {
        if (this.state.allCrops.length <= 0) {
            return getCropListFilter().then(async cropList => {
                const cropListJson = await cropList.json();

                if (!cropList.ok) {
                    const error = cropList.status;
                    return Promise.reject(error);
                }

                if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0) {
                    let allCropList = [];
                    for (let i in cropListJson.data) {
                        allCropList.push(new TypeCrop(cropListJson.data[i]));
                    }
                    this.setState({allCrops: allCropList});
                } else {
                    refreshIfNeeded(cropListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de los problemas fitosanitarios.
    getProblemsPromise = () => {
        if (this.state.allProblem.length <= 0) {
            return getProblemListFilter().then(async problemList => {
                const problemListJson = await problemList.json();

                if (!problemList.ok) {
                    const error = problemList.status;
                    return Promise.reject(error);
                }

                if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0) {
                    let problems = [];
                    for (let i in problemListJson.data) {
                        problems.push(new Infection(problemListJson.data[i]));
                    }
                    this.setState({allProblem: problems});
                } else {
                    refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de los tipos de protección.
    getProtectionPromise = () => {
        if (this.state.allProtection.length <= 0) {
            return getTypeProtection().then(async protectionList => {
                const protectionListJson = await protectionList.json();

                if (!protectionList.ok) {
                    const error = protectionList.status;
                    return Promise.reject(error);
                }

                if (protectionListJson.hasOwnProperty('data') && protectionListJson.data.length > 0) {
                    let protection = [];
                    for (let i in protectionListJson.data) {
                        protection.push(new TypeProtection(protectionListJson.data[i]));
                    }
                    this.setState({allProtection: protection});
                } else {
                    refreshIfNeeded(protectionListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Devuelve la promise de la petición de medidas.
    getMeasurePromise = () => {
        if (this.state.allDoseMeasures.length <= 0 || this.state.allSoupMeasures.length <= 0) {
            return getMeasure().then(async measureList => {
                const measureListJson = await measureList.json();

                if (!measureList.ok) {
                    const error = measureList.status;
                    return Promise.reject(error);
                }

                if (measureListJson.hasOwnProperty('data') && measureListJson.data.length > 0) {
                    let doseMeasures = [];
                    let soupMeasures = [];
                    for (let i in measureListJson.data) {
                        let measure = new Measure(measureListJson.data[i]);
                        switch (measure.getSubtype().getId()) {
                            case "1":
                                doseMeasures.push(measure);
                                break;
                            case "5":
                                soupMeasures.push(measure);
                                break;
                            default:
                                break;
                        }
                    }
                    this.setState({allDoseMeasures: doseMeasures, allSoupMeasures: soupMeasures});
                } else {
                    refreshIfNeeded(measureListJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }
    }

    // Realiza la petición de cultivos con el texto introducido en el input de filtros de cultivo de CI.
    getInnerAutocompleteCrop = (query) => {
        getCropListFilter(query).then(async cropList => {
            this.setState({isInnerCropLoading: true});
            const cropListJson = await cropList.json();

            if (!cropList.ok){
                const error = cropList.status;
                return Promise.reject(error);
            }

            if (cropListJson.hasOwnProperty('data') && cropListJson.data.length > 0){
                let options = [];
                this.cropList = [];
                for (let i in cropListJson.data){
                    const crop = new TypeCrop(cropListJson.data[i]);
                    options.push({id: crop.getId(), name: crop.getName(), code: crop.getCode()});
                    this.cropList.push(crop);
                }
                this.setState({cropInnerOptions: options});
            } else {
                refreshIfNeeded(cropListJson, null, this.props.showInfoModal);
            }
            this.setState({isInnerCropLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isInnerCropLoading: false});
        });
    }

    // Establece el tipo de cultivo seleccionado en el filtro de cultivos de CI.
    setInnerCropFormFill = (items) => {
        for (let i in this.cropList){
            if (items.length > 0) {
                if (items[0].id === this.cropList[i].getId()) {
                    this.setState({selectedInnerCrop: new TypeCrop(this.cropList[i])}, () => {
                        this.fillCIFiltered();
                    });
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de cultivos de CI.
    resetCropInnerOptions = () => {
        this.setState({cropInnerOptions: []});
    }

    // Realiza la petición de problemas con el texto introducido en el input de filtros de problemas de CI.
    getInnerAutocompleteProblem = (query) => {
        getProblemListFilter(query).then(async problemList => {
            this.setState({isInnerProblemLoading: true});
            const problemListJson = await problemList.json();

            if (!problemList.ok){
                const error = problemList.status;
                return Promise.reject(error);
            }

            if (problemListJson.hasOwnProperty('data') && problemListJson.data.length > 0){
                let options = [];
                this.problemList = [];
                for (let i in problemListJson.data){
                    const problem = new Infection(problemListJson.data[i]);
                    options.push({id: problem.getId(), name: problem.getName()});
                    this.problemList.push(problem);
                }
                this.setState({problemInnerOptions: options});
            } else {
                refreshIfNeeded(problemListJson, null, this.props.showInfoModal);
            }
            this.setState({isInnerProblemLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isInnerProblemLoading: false});
        });
    }

    // Establece el problema seleccionado en el filtro de problemas de CI.
    setInnerProblemFormFill = (items) => {
        for (let i in this.problemList){
            if (items.length > 0) {
                if (items[0].id === this.problemList[i].getId()) {
                    this.setState({selectedInnerProblem: new Infection(this.problemList[i])}, () => {
                        this.fillCIFiltered();
                    });
                    break;
                }
            }
        }
    }

    // Resetea las opciones del filtro de problemas de CI.
    resetProblemInnerOptions = () => {
        this.setState({problemInnerOptions: []});
    }

    // Rellena el contenido del modal de CI con los filtros aplicados.
    fillCIFiltered = () => {
        let content = "";
        for (let i in this.state.phytoCI[this.state.lastPhytoId]){
            if (this.state.selectedInnerCrop !== ""){
                if (this.state.selectedInnerProblem !== ""){
                    if (this.state.selectedInnerCrop.getId() === this.state.phytoCI[this.state.lastPhytoId][i].idtc.toString()
                    && this.state.selectedInnerProblem.getId() === this.state.phytoCI[this.state.lastPhytoId][i].idi.toString()){
                        content += this.fillCIContent(this.state.phytoCI[this.state.lastPhytoId][i]);
                    }
                } else {
                    if (this.state.selectedInnerCrop.getId() === this.state.phytoCI[this.state.lastPhytoId][i].idtc.toString()){
                        content += this.fillCIContent(this.state.phytoCI[this.state.lastPhytoId][i]);
                    }
                }
            } else {
                if (this.state.selectedInnerProblem !== ""){
                    if (this.state.selectedInnerProblem.getId() === this.state.phytoCI[this.state.lastPhytoId][i].idi.toString()){
                        content += this.fillCIContent(this.state.phytoCI[this.state.lastPhytoId][i]);
                    }
                } else {
                    content += this.fillCIContent(this.state.phytoCI[this.state.lastPhytoId][i]);
                }
            }
        }
        document.getElementById("modalInfoContent").innerHTML = content;
    }
}

export default PhytosanitaryInfo;