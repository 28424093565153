import TypeCrop from "./TypeCrop";
import TypeSagip from "./TypeSagip";
import TypeIrrigation from "./TypeIrrigation";
import TypeProtection from "./TypeProtection";
import CropSigpac from "./CropSigpac";
import Metadata from "./Metadata";
import {datetimeToString} from "../functions/Basic";
import TypeProduction from "./TypeProduction";

// Clase del objeto InfoCrop.
class InfoCrop {
    // Constructor del objeto InfoCrop.
    constructor(props) {
        this.type = "Crop";
        this.id = (props.id !== "" ? props.id : "-1");
        this.typecrop = new TypeCrop(props.typecrop);
        this.inidate = (props.inidate !== "" ? props.inidate : datetimeToString(new Date()));
        this.enddate = props.enddate;
        this.sagip = (props.sagip !== "" ? new TypeSagip(props.sagip): new TypeSagip({id: 1}));
        this.irrigation = (props.irrigation !== "" ? new TypeIrrigation(props.irrigation) : new TypeIrrigation({id: 1}));
        this.protection = (props.protection !== "" ? new TypeProtection(props.protection) : new TypeProtection({id: 2}));
        this.production = (props.production !== "" ? new TypeProduction(props.production) : new TypeProduction({id: 1}));
        this.numtree = (props.numtree !== "" ? props.numtree : "0");
        this.dimension = (props.dimension !== "" ? props.dimension : "0");
        this.code = props.code;
        this.variety = props.variety;
        this.detail = props.detail;
        this.sigpac = [];
        for(let i in props.sigpac){
            this.sigpac.push(new CropSigpac(props.sigpac[i]));
        }
        this.metadata = [];
        for (let i in props.metadata){
            this.metadata.push(new Metadata(props.metadata[i]));
        }
    }

    // Devuelve el id del cultivo.
    getId = () =>{
        return this.id;
    }
}

export default InfoCrop;