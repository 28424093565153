// Clase del objeto TypeProtection.
class TypeProduction {
    // Constructor del objeto TypeProtection.
    constructor(props) {
        this.type = "TypeProduction";
        // noinspection DuplicatedCode
        if (props !== null) {
            this.id = props.id;
            this.name = props.name;
            this.acronym = props.acronym;
            this.detail = props.detail;
        } else {
            this.id = "-1";
            this.name = "";
            this.acronym = "";
            this.detail = "";
        }
    }
}

export default TypeProduction;