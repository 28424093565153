import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import CustomButton from "../component/CustomButton";

import '../../../css/sb-admin-2.css';
import '../../../css/login.css';
import {logout} from "../../logic/functions/ServerPetitions";
import Strings from "../../logic/strings/Strings";
import Dashboard from "./dashboard/Dashboard";
// noinspection ES6CheckImport
import {Redirect} from "react-router-dom";
import {endPreLoad, loadSysAdminImageAndName, setCopyright} from "../../logic/functions/Basic";
import Sidebar from "../component/Sidebar";
import Header from "../component/Header";
import Footer from "../component/Footer";
import SysAdmin from "../../logic/objects/SysAdmin";
import {getSysAdmin} from "../../logic/functions/ServerPetitions";
import UserInfo from "./user/UserInfo";
import UserManagement from "./user/UserManagement";
import MessageInfo from "./message/MessageInfo";
import SeasonManagement from "./season/SeasonManagement";
import MessageManagement from "./message/MessageManagement";
import ToolManagement from "./tool/ToolManagement";
import PhytosanitaryInfo from "./phytosanitary/PhytosanitaryInfo";
import PhytosanitaryManagement from "./phytosanitary/PhytosanitaryManagement";
import Globals from "../../logic/objects/Globals";
import OrganizationInfo from "./organization/OrganizationInfo";
import OrganizationManagement from "./organization/OrganizationManagement";

// Clase que contiene el contenido de la página Login.
class HtmlPage extends Component {
    // Constructor de clase Login.
    // noinspection DuplicatedCode
    constructor(props) {
        super(props);
        this.globals = new Globals();
        this.strings = new Strings();
        this.children = null;
        this.state = {showModalLogout: false, showModalLoader: false, redirect: false, showInfoModal: false};
    }

    // Función que se realiza cuando se ha cargado el DOM, añade el elemento preload y establece el copyright.
    componentDidMount = () => {
        const style = document.createElement("style");
        style.id = "preloadstyle";
        style.innerHTML = "#preload{width:100%;height:100vh;background:url('img/loader.gif') no-repeat center #fff;position:fixed;z-index:100000;top:0;left:0}";
        document.head.appendChild(style);

        setCopyright();
        getSysAdmin().then(async admin => {
            const adminJson = await admin.json();

            if (!admin.ok){
                const error = (adminJson && adminJson.error) || admin.status;
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (adminJson.hasOwnProperty('data')){
                const adminData = new SysAdmin(adminJson.data);
                loadSysAdminImageAndName(adminData);
            } else if (adminJson.hasOwnProperty('error') && adminJson.error[0].hasOwnProperty('idsubcat') && adminJson.error[0]['idsubcat'] === "303") {
                window.location.href = '/';
            }
        }).catch( error => {
            this.showInfoModal(this.strings.getModalErrorTitle, this.strings.getModalErrorBody);
            console.log(error);
            endPreLoad();
        });
    }

    // Función que se realiza cuando el elemento va a desaparecer, quita el elemento preload.
    componentWillUnmount = () => {
        const style = document.getElementById("preloadstyle");
        document.head.removeChild(style);
    }

    // Dibuja los elementos de la página.
    render = () => {
        switch (this.props.id){
            case "Dashboard":
                this.children = <Dashboard logoutServer={this.logoutServer} showInfoModal={this.showInfoModal}
                                           hideInfoModal={this.hideInfoModal} strings={this.strings} />;
                break;
            case "UserInfo":
                this.children = <UserInfo logoutServer={this.logoutServer} showInfoModal={this.showInfoModal}
                                          hideInfoModal={this.hideInfoModal} strings={this.strings} globals={this.globals} />;
                break;
            case "UserManagement":
                this.children = <UserManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal}
                                                strings={this.strings} globals={this.globals} />;
                break;
            case "OrganizationInfo":
                this.children = <OrganizationInfo logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal}
                                                strings={this.strings} globals={this.globals} />
                break;
            case "OrganizationManagement":
                this.children = <OrganizationManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal}
                                                hideInfoModal={this.hideInfoModal} showLoaderModal={this.showLoaderModal}
                                                hideLoaderModal={this.hideLoaderModal} strings={this.strings} globals={this.globals} />
                break;
            case "MessageInfo":
                this.children = <MessageInfo logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                             showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal} strings={this.strings} globals={this.globals} />
                break;
            case "MessageManagement":
                this.children = <MessageManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                   strings={this.strings} globals={this.globals} />;
                break;
            case "SeasonManagement":
                this.children = <SeasonManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                              showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal} strings={this.strings} globals={this.globals} />;
                break;
            case "PhytosanitaryInfo":
                this.children = <PhytosanitaryInfo logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                   showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal}
                                                   strings={this.strings} globals={this.globals} />
                break;
            case "PhytosanitaryManagement":
                this.children = <PhytosanitaryManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                        showLoaderModal={this.showLoaderModal} hideLoaderModal={this.hideLoaderModal}
                                                         strings={this.strings} globals={this.globals} />
                break;
            case "ToolManagement":
                this.children = <ToolManagement logoutServer={this.logoutServer} showInfoModal={this.showInfoModal} hideInfoModal={this.hideInfoModal}
                                                strings={this.strings} globals={this.globals} />
                break;
            default:
                this.children = null;
                break;
        }

        const redirect = this.state.redirect;
        if (localStorage.getItem(this.strings.sysadminKey) === null || redirect) {
            return <Redirect to='/'/>;
        }

        return (
            <div>
                <div id="preload"/>

                <div id="wrapper">
                    <Sidebar strings={this.strings} activeId={this.props.id}/>
                    {/* Content Wrapper */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* Main Content */}
                        <div id="content">
                            {/* Topbar */}
                            <Header logoutFunction={this.showLogoutModal}/>
                            {/* End of Topbar */}

                            {this.children ? this.children : ''}

                        </div>
                        {/* Footer */}
                        <Footer />
                    </div>
                    {/* End of Content Wrapper */}
                </div>

                {/* Modal Logout */}
                <Modal show={this.state.showModalLogout} backdrop="static" onHide={this.hideLogoutModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle>
                            ¿Seguro que quieres cerrar sesión?
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        Selecciona "Cerrar sesión" a continuación si realmente deseas salir de tu cuenta de usuario.
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={"Cancelar"} parentFunction={this.hideLogoutModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={"Cerrar sesión"} parentFunction={this.logoutServer}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Logout */}

                {/* Modal Info */}
                <Modal show={this.state.showInfoModal} backdrop="static" onHide={this.hideInfoModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-info-title"/>
                    </ModalHeader>
                    <ModalBody id="modal-info-body"/>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-primary"} text={"Entendido"} parentFunction={this.hideInfoModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal Info */}

                {/* Modal Loader */}
                <Modal size={'sm'} show={this.state.showModalLoader} backdrop="static" centered>
                    <ModalBody id={"loaderbody"}/>
                </Modal>
                {/* End of Modal Loader */}
            </div>
        );
    }

    // Muestra el modal de logout.
    showLogoutModal = () => {
        this.setState({showModalLogout: true});
    }

    // Oculta el modal de logout.
    hideLogoutModal = () => {
        this.setState({showModalLogout: false});
    }

    // Realiza la función de logout del servidor.
    logoutServer = () => {
        this.hideLogoutModal();
        this.showLoaderModal(this.strings.logoutContent);
        logout().then(
            async response => {
                const data = await response.json();

                if (!response.ok){
                    const error = (data && data.error) || response.status;
                    return Promise.reject(error);
                }
                if (data.hasOwnProperty('revoked') && data.revoked === true) {
                    this.hideLoaderModal();
                    window.localStorage.removeItem(this.strings.sysadminKey);
                    window.localStorage.removeItem(this.strings.credentialsKey);
                    this.setState({redirect: true});
                    window.location.href = '/';
                }
            }
        ).catch( error => {
            console.log(error);
            this.hideLoaderModal();
            window.localStorage.removeItem(this.strings.sysadminKey);
            window.localStorage.removeItem(this.strings.credentialsKey);
            this.setState({redirect: true});
            window.location.href = '/';
        });
    }

    // Muestra el modal del loader.
    showLoaderModal = (body) => {
        this.setState({showModalLoader: true});
        setTimeout(function(){
            let modalbody = document.getElementById("loaderbody");
            if (modalbody !== null) {
                modalbody.innerHTML = body;
            }
        }, 50);
    }

    // Oculta el modal del loader.
    hideLoaderModal = () => {
        this.setState({showModalLoader: false});
    }

    // Muestra el modal de información con los errores del login.
    showInfoModal = (title, body) => {
        this.setState({showInfoModal: true});
        setTimeout(function() {
            let modaltitle = document.getElementById("modal-info-title");
            if (modaltitle !== null) {
                modaltitle.innerHTML = title;
            }
            let modalbody = document.getElementById("modal-info-body");
            if (modalbody !== null) {
                modalbody.innerHTML = body;
            }
        }, 50);
    }

    // Oculta el modal de información.
    hideInfoModal = () => {
        this.setState({showInfoModal: false});
    }
}

export default HtmlPage;