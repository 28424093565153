import React, {Component} from "react";
import PageTitle from "../../component/PageTitle";
import DashboardData from "../../../logic/objects/DashboardData";
import Card from "../../component/Card";
import DashboardGraph from "../../component/DashboardGraph";
// noinspection ES6CheckImport
import {Chart} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import {getDashboard} from "../../../logic/functions/ServerPetitions";

import '../../../../css/sb-admin-2.css';
import '../../../../css/dashboard.css';

// Clase que contiene el contenido de la página Dasboard.
// noinspection DuplicatedCode
class Dashboard extends Component {
    // Constructor de clase Dashboard.
    constructor(props) {
        super(props);
        this.graphs = [];
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.dashboardTitle;
        Chart.register(zoomPlugin);

        getDashboard().then(async dashboard => {
            const dashboardJson = await dashboard.json();

            if (!dashboard.ok){
                const error = ((dashboardJson && dashboardJson.error) || dashboard.status);
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (dashboardJson.hasOwnProperty('data')){
                const dashboardData = new DashboardData(dashboardJson.data);
                this.fillBasicInformationDashboard(dashboardData);
                this.fillChartInformationDashboard(dashboardData);
                endPreLoad();
            } else {
                refreshIfNeeded(dashboardJson, null, this.props.showInfoModal);
            }
        }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                endPreLoad();
            }
        );
    }

    // Dibuja los elementos de la página.
    render = () => {
        // noinspection HtmlUnknownAnchorTarget
        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={"Información general"}/>

                {/* Content Row */}
                <div className="row">
                    {/* Card numero usuarios */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"userAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº usuarios"} iconClass={"fa-users"} />
                    </div>
                    {/* Card numero explotaciones */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"farmAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº explotaciones"} iconClass={"fa-mountain"} />
                    </div>
                    {/* Card numero temporadas */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"seasonAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº temporadas"} iconClass={"fa-mountain"} />
                    </div>
                    {/* Card numero cultivos */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"cropAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº cultivos"} iconClass={"fa-carrot"} />
                    </div>
                    {/* Card numero maquinaria */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"machineAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº maquinaria"} iconClass={"fa-tractor"} />
                    </div>
                    {/* Card numero mensajes */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"messageAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº mensajes"} iconClass={"fa-envelope"} />
                    </div>
                    {/* Card numero notificaciones */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"notificationAmount"} cardColorClass={"border-left-primary"} titleColorClass={"text-primary"} text={"Nº notificaciones"} iconClass={"fa-bell"} />
                    </div>
                </div>

                {/* Page Heading */}
                <PageTitle text={"Incidencias"}/>

                {/* Content Row */}
                <div className="row">
                    {/* Card numero infecciones */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"infectionAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº infecciones"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero fertilizantes */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"fertilizeAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº fertilizantes"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero fitosanitarios */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"phytosanitaryAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº fitosanitarios"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero recolectas */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"collectAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº recolectas"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero labores */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"workAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº labores"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero riegos */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"irrigationAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº riego"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero pastoreos */}
                    <div className="col-xl-3 col-md-6 mb-4">
                        <Card id={"shepherdAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº pastoreos"} iconClass={"fa-exclamation"} />
                    </div>
                    {/* Card numero recolectas */}
                    <div className="col-xl-3 col-md-6 mb-4">
                    <Card id={"noteAmount"} cardColorClass={"border-left-danger"} titleColorClass={"text-danger"} text={"Nº notas"} iconClass={"fa-exclamation"} />
                    </div>
                </div>

                {/* Page Heading */}
                <PageTitle text={"Gráficas generales"}/>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        {/* User Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserAreaChartCardTrigger"} collapseId={"collapseUserAreaChartCard"} chartId={"userAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardUser} cardShadow={true} btnFunction={this.resetUserChart} />
                        {/* Season Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseSeasonAreaChartCardTrigger"} collapseId={"collapseSeasonAreaChartCard"} chartId={"seasonAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardSeason} cardShadow={true} btnFunction={this.resetSeasonChart} />
                        {/* Crop Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseCropAreaChartCardTrigger"} collapseId={"collapseCropAreaChartCard"} chartId={"cropAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardCrop} cardShadow={true} btnFunction={this.resetCropChart} />
                        {/* Machine Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseMachineAreaChartCardTrigger"} collapseId={"collapseMachineAreaChartCard"} chartId={"machineAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardMachine} cardShadow={true} btnFunction={this.resetMachineChart} />
                        {/* Message Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseMessageAreaChartCardTrigger"} collapseId={"collapseMessageAreaChartCard"} chartId={"messageAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardMessage} cardShadow={true} btnFunction={this.resetMessageChart} />
                        {/* Notification Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseNotificationAreaChartCardTrigger"} collapseId={"collapseNotificationAreaChartCard"} chartId={"notificationAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardNotification} cardShadow={true} btnFunction={this.resetNotificationChart} />
                    </div>
                </div>

                {/* Page Heading */}
                <PageTitle text={"Gráficas incidencias"}/>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        {/* Infection Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseInfectionAreaChartCardTrigger"} collapseId={"collapseInfectionAreaChartCard"} chartId={"infectionAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardInfection} cardShadow={true} btnFunction={this.resetInfectionChart} />
                        {/* Fertilize Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseFertilizeAreaChartCardTrigger"} collapseId={"collapseFertilizeAreaChartCard"} chartId={"fertilizeAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardFertilize} cardShadow={true} btnFunction={this.resetFertilizeChart} />
                        {/* Phytosanitary Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapsePhytosanitaryAreaChartCardTrigger"} collapseId={"collapsePhytosanitaryAreaChartCard"} chartId={"phytosanitaryAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardPhytosanitary} cardShadow={true} btnFunction={this.resetPhytosanitaryChart} />
                        {/* Collect Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseCollectAreaChartCardTrigger"} collapseId={"collapseCollectAreaChartCard"} chartId={"collectAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardCollect} cardShadow={true} btnFunction={this.resetCollectChart} />
                        {/* Work Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseWorkAreaChartCardTrigger"} collapseId={"collapseWorkAreaChartCard"} chartId={"workAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardWork} cardShadow={true} btnFunction={this.resetWorkChart} />
                        {/* Irrigation Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseIrrigationAreaChartCardTrigger"} collapseId={"collapseIrrigationAreaChartCard"} chartId={"irrigationAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardIrrigation} cardShadow={true} btnFunction={this.resetIrrigationChart} />
                        {/* Shepherd Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseShepherdAreaChartCardTrigger"} collapseId={"collapseShepherdAreaChartCard"} chartId={"shepherdAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardShepherd} cardShadow={true} btnFunction={this.resetShepherdChart} />
                        {/* Note Area Chart */}
                        <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseNoteAreaChartCardTrigger"} collapseId={"collapseNoteAreaChartCard"} chartId={"noteAreaChart"} cardTitleClass={"text-primary"} cardTitle={this.props.strings.dashboardNote} cardShadow={true} btnFunction={this.resetNoteChart} />
                    </div>
                </div>
            </div>
        );
    }

    // Rellena la información de las cards de dashboard.
    fillBasicInformationDashboard = (dashboard) => {
        //Se estabece el numero de usuarios registrados
        document.getElementById("userAmount").innerHTML = dashboard.infoUserAmount();
        //Se estabece el numero de explotaciones registradas
        document.getElementById("farmAmount").innerHTML = dashboard.infoFarmAmount();
        //Se estabece el numero de temporadas registradas
        document.getElementById("seasonAmount").innerHTML = dashboard.infoSeasonAmount();
        //Se estabece el numero de cultivos plantados
        document.getElementById("cropAmount").innerHTML = dashboard.infoCropAmount();
        //Se estabece el numero de maquinaria registrada
        document.getElementById("machineAmount").innerHTML = dashboard.infoMachineAmount();
        //Se estabece el numero de maquinaria registrada
        document.getElementById("messageAmount").innerHTML = dashboard.infoMessageAmount();
        //Se estabece el numero de maquinaria registrada
        document.getElementById("notificationAmount").innerHTML = dashboard.infoNotificationAmount();

        //Se estabece el numero de infecciones detectadas
        document.getElementById("infectionAmount").innerHTML = dashboard.infoInfectionAmount();
        //Se estabece el numero de fertilizantes aplicados
        document.getElementById("fertilizeAmount").innerHTML = dashboard.infoFertilizeAmount();
        //Se estabece el numero de fitosanitarios aplicados
        document.getElementById("phytosanitaryAmount").innerHTML = dashboard.infoPhytosanitaryAmount();
        //Se estabece el numero de recolectas realizadas
        document.getElementById("collectAmount").innerHTML = dashboard.infoCollectAmount();
        //Se estabece el numero de labores realizadas
        document.getElementById("workAmount").innerHTML = dashboard.infoWorkAmount();
        //Se estabece el numero de riego realizado
        document.getElementById("irrigationAmount").innerHTML = dashboard.infoIrrigationAmount();
        //Se estabece el numero de pastoreos realizados
        document.getElementById("shepherdAmount").innerHTML = dashboard.infoShepherdAmount();
        //Se estabece el numero de notas guardadas
        document.getElementById("noteAmount").innerHTML = dashboard.infoNoteAmount();
    }

    // Rellena los gráficos de dashboard.
    fillChartInformationDashboard = (dashboard) => {
        this.graphs[0] = this.drawChart(document.getElementById("userAreaChart"), this.getAreaChartLabels(dashboard.user),
            this.props.strings.dashboardNUser, this.getAreaChartData(dashboard.user));
        this.graphs[1] = this.drawChart(document.getElementById("seasonAreaChart"), this.getAreaChartLabels(dashboard.season),
            this.props.strings.dashboardNSeason, this.getAreaChartData(dashboard.season));
        this.graphs[2] = this.drawChart(document.getElementById("cropAreaChart"), this.getAreaChartLabels(dashboard.incidence.crop),
            this.props.strings.dashboardNCrop, this.getAreaChartData(dashboard.incidence.crop));
        this.graphs[3] = this.drawChart(document.getElementById("machineAreaChart"), this.getAreaChartLabels(dashboard.machine),
            this.props.strings.dashboardNMachine, this.getAreaChartData(dashboard.machine));
        this.graphs[4] = this.drawChart(document.getElementById("messageAreaChart"), this.getAreaChartLabels(dashboard.message),
            this.props.strings.dashboardNMessage, this.getAreaChartData(dashboard.message));
        this.graphs[5] = this.drawChart(document.getElementById("notificationAreaChart"), this.getAreaChartLabels(dashboard.notification),
            this.props.strings.dashboardNNotification, this.getAreaChartData(dashboard.notification));

        this.graphs[6] = this.drawChart(document.getElementById("infectionAreaChart"), this.getAreaChartLabels(dashboard.incidence.infection),
            this.props.strings.dashboardNInfection, this.getAreaChartData(dashboard.incidence.infection));
        this.graphs[7] = this.drawChart(document.getElementById("fertilizeAreaChart"), this.getAreaChartLabels(dashboard.incidence.fertilize),
            this.props.strings.dashboardNFertilize, this.getAreaChartData(dashboard.incidence.fertilize));
        this.graphs[8] = this.drawChart(document.getElementById("phytosanitaryAreaChart"), this.getAreaChartLabels(dashboard.incidence.phytosanitary),
            this.props.strings.dashboardNPhytosanitary, this.getAreaChartData(dashboard.incidence.phytosanitary));
        this.graphs[9] = this.drawChart(document.getElementById("collectAreaChart"), this.getAreaChartLabels(dashboard.incidence.collect),
            this.props.strings.dashboardNCollect, this.getAreaChartData(dashboard.incidence.collect));
        this.graphs[10] = this.drawChart(document.getElementById("workAreaChart"), this.getAreaChartLabels(dashboard.incidence.work),
            this.props.strings.dashboardNWork, this.getAreaChartData(dashboard.incidence.work));
        this.graphs[11] = this.drawChart(document.getElementById("irrigationAreaChart"), this.getAreaChartLabels(dashboard.incidence.irrigation),
            this.props.strings.dashboardNIrrigation, this.getAreaChartData(dashboard.incidence.irrigation));
        this.graphs[12] = this.drawChart(document.getElementById("shepherdAreaChart"), this.getAreaChartLabels(dashboard.incidence.shepherd),
            this.props.strings.dashboardNShepherd, this.getAreaChartData(dashboard.incidence.shepherd));
        this.graphs[13] = this.drawChart(document.getElementById("noteAreaChart"), this.getAreaChartLabels(dashboard.incidence.note),
            this.props.strings.dashboardNNote, this.getAreaChartData(dashboard.incidence.note));
    }

    // Dibuja un gráfico.
    drawChart = (chart, labels, labelName, data) => {
        // noinspection JSCheckFunctionSignatures
        return new Chart(chart, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    lineTension: 0.3,
                    backgroundColor: "rgba(78, 115, 223, 0.05)",
                    borderColor: "rgba(78, 115, 223, 1)",
                    pointRadius: 3,
                    pointBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointBorderColor: "rgba(78, 115, 223, 1)",
                    pointHoverRadius: 3,
                    pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointHoverBorderColor: "rgba(78, 115, 223, 1)",
                    pointHitRadius: 10,
                    pointBorderWidth: 2,
                    label: labelName,
                    data: data
                }]
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 25,
                        top: 25,
                        bottom: 0
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            maxTicksLimit: 7
                        },
                        time: {
                            unit: 'date'
                        }
                    },
                    y: {
                        grid: {
                            color: "rgb(234, 236, 244)",
                            drawBorder: false,
                            borderDash: [2],
                        },
                        ticks: {
                            maxTicksLimit: 5,
                            padding: 10,
                        }
                    }
                },
                transitions: {
                  zoom: {
                      animation: {
                          duration: 1000
                      }
                  }
                },
                plugins: {
                    zoom: {
                        // Container for zoom options
                        zoom: {
                            drag: {
                                enabled: true
                            },
                            wheel: {
                                enabled: false,
                            },
                            mode: 'x'
                        },
                        pan: {
                            enabled: true,
                            mode: 'x'
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10
                    }
                }
            }
        });
    }

    // Devuelve las labels de los gráficos.
    getAreaChartLabels = (dashboardDays) => {
        let result = [];
        for (let i in dashboardDays){
            result.push(dashboardDays[i].areaChartLabel());
        }
        return result;
    }

    // Devuelve los datos d elos gráficos.
    getAreaChartData = (dashboardDays) => {
        let result = [];
        for (let i in dashboardDays){
            result.push(dashboardDays[i].areaChartData());
        }
        return result;
    }

    // Resetea el zoom del gráfico de usuarios.
    resetUserChart = () => {
        this.graphs[0].resetZoom();
    }

    // Resetea el zoom del gráfico de temporadas.
    resetSeasonChart = () => {
        this.graphs[1].resetZoom();
    }

    // Resetea el zoom del gráfico de cultivos.
    resetCropChart = () => {
        this.graphs[2].resetZoom();
    }

    // Resetea el zoom del gráfico de maquinaria.
    resetMachineChart = () => {
        this.graphs[3].resetZoom();
    }

    // Resetea el zoom del gráfico de mensajes.
    resetMessageChart = () => {
        this.graphs[4].resetZoom();
    }

    // Resetea el zoom del gráfico de notificaciones.
    resetNotificationChart = () => {
        this.graphs[5].resetZoom();
    }

    // Resetea el zoom del gráfico de infecciones.
    resetInfectionChart = () => {
        this.graphs[6].resetZoom();
    }

    // Resetea el zoom del gráfico de fertilizantes.
    resetFertilizeChart = () => {
        this.graphs[7].resetZoom();
    }

    // Resetea el zoom del gráfico de fitosanitarios.
    resetPhytosanitaryChart = () => {
        this.graphs[8].resetZoom();
    }

    // Resetea el zoom del gráfico de recolectas.
    resetCollectChart = () => {
        this.graphs[9].resetZoom();
    }

    // Resetea el zoom del gráfico de labores.
    resetWorkChart = () => {
        this.graphs[10].resetZoom();
    }

    // Resetea el zoom del gráfico de riego.
    resetIrrigationChart = () => {
        this.graphs[11].resetZoom();
    }

    // Resetea el zoom del gráfico de pastoreos.
    resetShepherdChart = () => {
        this.graphs[12].resetZoom();
    }

    // Resetea el zoom del gráfico de notas.
    resetNoteChart = () => {
        this.graphs[13].resetZoom();
    }
}

export default Dashboard;