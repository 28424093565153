import {datetimeToString} from "../functions/Basic";
import Metadata from "./Metadata";
import TypeMessage from "./TypeMessage";

// Clase del objeto InfoMessage.
class InfoMessage {
    // Constructor del objeto InfoMessage.
    constructor(props) {
        this.type = "InfoMessage";
        this.id = (props.id !== "" ? props.id : "-1");
        this.subtype = new TypeMessage(props.subtype);
        this.subject = props.subject;
        this.body = props.body;
        this.date = (props.date !== "" ? props.date : datetimeToString(new Date()));
        this.metadata = [];
        if (props.metadata !== undefined) {
            for (let i in props.metadata) {
                this.metadata.push(new Metadata(props.metadata[i]));
            }
        }
    }
}

export default InfoMessage;