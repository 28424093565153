class TypeMeasure {
    constructor(props){
        this.type = "TypeMeasure";
        if (props !== undefined && props !== null){
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.name = (props.name !== "" && props.name !== undefined ? props.name : "");
        } else {
            this.id = "-1";
            this.name = "";
        }
    }

    // Devuelve el id del tipo de medida.
    getId = () => {
        return this.id;
    }
}

export default TypeMeasure;