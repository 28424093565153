// Clase del objeto UserMetadata.
class UserMetadata {
    // Constructor del objeto UserMetadata.
    constructor(props) {
        this.type = "UserMetadata";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== undefined ? props.id : "");
            this.loginname = (props.loginname !== undefined ? props.loginname : "");
            this.email = (props.email !== undefined ? props.email : "");
            this.name = (props.name !== undefined ? props.name : "");
            this.surname = (props.surname !== undefined ? props.surname : "");
        } else {
            this.id = "";
            this.loginname = "";
            this.email = "";
            this.name = "";
            this.surname = "";
        }
    }

    // Obtiene el loginname si existe y sino, combinación de nombre más apellido.
    getVisibleName = () => {
        return this.loginname !== "" ? this.loginname : this.name + " " + this.surname;
    }
}

export default UserMetadata;