import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";

// Crea un componente collapsible de gestión de usuarios.
class Collapsible extends Component {
    // Constructor de la clase collapsible de gestión de usuarios.
    constructor(props) {
        super(props);
        this.triggerId = props.triggerId;
        this.leftColour = "shadow mb-4 " + (props.leftColour !== undefined ? props.leftColour : "");
        this.collapseId = props.collapseId;
        this.cardTitle = props.cardTitle;
        this.state = {show: false};
        this.toggleBody = this.toggleBody.bind(this);
    }

    // Dibuja la gráfica.
    render() {
        return (
            <Card className={this.leftColour} onClick={this.toggleBody}>
                <Card.Header id={this.triggerId} href={'#' + this.triggerId} as="a" className="collapsed d-block py-3" data-toggle="collapse" data-target={'#' + this.collapseId} aria-expanded="false" aria-controls={this.collapseId}>
                    <h6 id={this.triggerId + "Title"} className="m-0 font-weight-bold text-primary">{this.cardTitle}</h6>
                </Card.Header>
                <Collapse in={this.state.show}>
                    <div id={this.collapseId}>
                        <Card.Body>
                            {this.props.children}
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        );
    }

    // Muestra u oculta el cuerpo de la gráfica.
    toggleBody(e) {
        e.preventDefault();
        const trigger = document.getElementById(this.triggerId);
        if (e.target === trigger || e.target === document.getElementById(this.triggerId + "Title")) {
            if (trigger.classList.contains("collapsed")) {
                trigger.classList.remove("collapsed");
            } else {
                trigger.classList.add("collapsed");
            }
            this.setState({show: !this.state.show});
        }
    }
}

export default Collapsible;