import {jsonStringToDate} from '../functions/Basic';
import TypeLicense from "./TypeLicense";
import Metadata from "./Metadata";
import UserMetadata from "./UserMetadata";
import Key from "./Key";

// Clase del objeto User.
// noinspection DuplicatedCode
class User {
    // Constructor del objeto User.
    constructor(props) {
        this.type = "User";
        this.id = props.id;
        this.loginname = props.loginname;
        this.loginpass = props.loginpass;
        this.email = props.email;
        this.name = props.name;
        this.surname = props.surname;
        this.key = (props.key !== null ? new Key(props.key) : null);
        this.company = props.company;
        this.phone = props.phone;
        this.nf = props.nf;
        this.nif = props.nif;
        this.ropo = props.ropo;
        this.verified = (props.verified !== undefined ? props.verified : 0);
        this.siex = (props.siex !== undefined ? props.siex : 0);
        this.policy = (props.policy !== undefined ? props.policy : "-1");
        this.communication = (props.communication !== undefined ? props.communication : "0");
        this.license = new TypeLicense(props.license);
        this.image = props.image;
        this.metadata = [];
        for (let i in props.metadata){
            this.metadata.push(props.metadata[i]);
        }
    }

    // Devuelve si el usuario es premium de tipo A.
    isPremiumA = () => {
        if (this.key !== null) {
            for (let i in this.key.premium) {
                if (this.key.premium[i].idtp === 1) {
                    if (jsonStringToDate(this.key.premium[i].enddate) > new Date()) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    // Devuelve si el usuario es premium de tipo B.
    isPremiumB = () => {
        if (this.key !== null) {
            for (let i in this.key.premium) {
                if (this.key.premium[i].idtp === 2) {
                    if (jsonStringToDate(this.key.premium[i].enddate) > new Date()) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    // Devuelve si el usuario es premium de tipo C.
    isPremiumC = () => {
        if (this.key !== null) {
            for (let i in this.key.premium) {
                if (this.key.premium[i].idtp === 3) {
                    if (jsonStringToDate(this.key.premium[i].enddate) > new Date()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    // Devuelve si el usuario es premium de tipo D.
    isPremiumD = () => {
        if (this.key !== null) {
            for (let i in this.key.premium) {
                if (this.key.premium[i].idtp === 4) {
                    if (jsonStringToDate(this.key.premium[i].enddate) > new Date()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    // Devuelve si el usuario es premium de tipo E.
    isPremiumE = () => {
        if (this.key !== null) {
            for (let i in this.key.premium) {
                if (this.key.premium[i].idtp === 5) {
                    if (jsonStringToDate(this.key.premium[i].enddate) > new Date()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    // Devuelve si el usuario está registrado.
    isRegistered = () => {
        return this.id !== "-1";
    }

    // Genera el metadata del usuario.
    generateMetadata = (type, user) => {
        this.metadata = [new Metadata({type: type, user: new UserMetadata(user), idorigin: '2'})];
    }

    // Devuelve el id del usuario.
    getId = () => {
        return this.id;
    }

    // Devuelve el loginname del usuario.
    getLoginname = () => {
        return this.loginname;
    }

    // Devuelve el email del usuario.
    getEmail = () => {
        return this.email;
    }

    // Devuelve el nombre del usuario.
    getName = () => {
        return this.name;
    }

    // Devuelve el apellido del usuario.
    getSurname = () => {
        return this.surname;
    }

    // Devuelve la empresa del usuario.
    getCompany = () => {
        return this.company;
    }

    // Devuelve el teléfono del usuario.
    getPhone = () => {
        return this.phone;
    }

    // Devuelve el número de explotaciones del usuario.
    getNf = () => {
        return this.nf;
    }

    // Devuelve el nif del usuario.
    getNif = () => {
        return this.nif;
    }

    // Obtiene el loginname si existe y sino, combinación de nombre más apellido.
    getVisibleName = () => {
        return this.loginname !== "" ? this.loginname : this.name + " " + this.surname;
    }

    // Obtiene la key del usuario.
    getKey = () => {
        return this.key;
    }
}

export default User;