// Clase del objeto TypePhytosanitaryCropInfection.

class TypePhytosanitaryCropInfection {
    // Constructor del objeto TypePhytosanitaryCropInfection.
    // noinspection DuplicatedCode
    constructor(props) {
        this.type = "TPCI";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" ? props.id : "-1");
            this.idate = props.idate;
            this.rdate = props.rdate;
            this.mnd = props.mnd; //mindose
            this.mxd = props.mxd; //maxdose
            this.md = props.md; //measuredose
            this.mna = props.mna; //minnumapplication
            this.mxa = props.mxa; //maxnumapplication
            this.mni = props.mni; //mininterval
            this.mxi = props.mxi; //maxinterval
            this.mns = props.mns; //minsoup
            this.mxs = props.mxs; //maxsoup
            this.ms = props.ms; //measuresoup
            this.mnda = props.mnda; //mindoseapplication
            this.mxda = props.mxda; //maxdoseapplication
            this.mda = props.mda; //measuredoseapplication
            this.s = props.s; //securitylimit
            this.tp = props.tp; //idtypeprotection
            this.d = props.d; //details
            this.idtc = props.idtc; //idtypecrop
            this.idi = props.idi; //idinfection
        } else {
            this.id = "-1";
            this.idate = "";
            this.rdate = "";
            this.mnd = 0;
            this.mxd = 0;
            this.md = 19;
            this.mna = 0;
            this.mxa = 0;
            this.mni = 0;
            this.mxi = 0;
            this.mns = 0;
            this.mxs = 0;
            this.ms = 410;
            this.mnda = 0;
            this.mxda = 0;
            this.mda = 19;
            this.s = 0;
            this.tp = null;
            this.d = "";
            this.idtc = 0;
            this.idi = 0;
        }
    }

    // Devuelve el id del CI.
    getId = () => {
        return this.id.toString();
    }

    // Devuelve el id del tipo de cultivo.
    getIdTc = () => {
        return this.idtc.toString();
    }

    // Establece el id del tipo de cultivo.
    setIdTc = (idtc) => {
        this.idtc = idtc;
    }

    // Devuelve el id de la infección.
    getIdI = () => {
        return this.idi.toString();
    }

    // Establece el id de la infección.
    setIdI = (idi) => {
        this.idi = idi;
    }

    // Devuelve el valor de la dosis mínima.
    getMND = () => {
        return this.mnd;
    }

    // Establece el valor de la dosis mínima.
    setMND = (mnd) => {
        this.mnd = mnd;
    }

    // Devuelve el valor de la dosis máxima.
    getMXD = () => {
        return this.mxd;
    }

    // Establece el valor de la dosis máxima.
    setMXD = (mxd) => {
        this.mxd = mxd;
    }

    // Devuelve el id de la unidad de medida de dosis.
    getMD = () => {
        return this.md.toString();
    }

    // Establece el id de la unidad de medida de dosis.
    setMD = (md) => {
        this.md = md;
    }

    // Devuelve el número mínimo de aplicaciones.
    getMNA = () => {
        return this.mna;
    }

    // Establece el número mínimo de aplicaciones.
    setMNA = (mna) => {
        this.mna = mna;
    }

    // Devuelve el número máximo de aplicaciones.
    getMXA = () => {
        return this.mxa;
    }

    // Establece el número máximo de aplicaciones.
    setMXA = (mxa) => {
        this.mxa = mxa;
    }

    // Devuelve el número de días mínimo del intervalo.
    getMNI = () => {
        return this.mni;
    }

    // Establece el número de días mínimo del intervalo.
    setMNI = (mni) => {
        this.mni = mni;
    }

    // Devuelve el número de días máximo del intervalo.
    getMXI = () => {
        return this.mxi;
    }

    // Establece el número de días máximo del intervalo.
    setMXI = (mxi) => {
        this.mxi = mxi;
    }

    // Devuelve el valor del caldo mínimo.
    getMNS = () => {
        return this.mns;
    }

    // Establece el valor del caldo mínimo.
    setMNS = (mns) => {
        this.mns = mns;
    }

    // Devuelve el valor del caldo máximo.
    getMXS = () => {
        return this.mxs;
    }

    // Establece el valor del caldo máximo.
    setMXS = (mxs) => {
        this.mxs = mxs;
    }

    // Devuelve el id de la unidad de medida de caldo.
    getMS = () => {
        return this.ms.toString();
    }

    // Establece el id de la unidad de medida de caldo.
    setMS = (ms) => {
        this.ms = ms;
    }

    // Devuelve la dosis de aplicación mínima.
    getMNDA = () => {
        return this.mnda;
    }

    // Establece la dosis de aplicación mínima.
    setMNDA = (mnda) => {
        this.mnda = mnda;
    }

    // Devuelve la dosis de aplicación máxima.
    getMXDA = () => {
        return this.mxda;
    }

    // Establece la dosis de aplicación máxima.
    setMXDA = (mxda) => {
        this.mxda = mxda;
    }

    // Devuelve la medida de la dosis de aplicación.
    getMDA = () => {
        return this.mda.toString();
    }

    // Establece la medida de la dosis de aplicación.
    setMDA = (mda) => {
        this.mda = mda;
    }

    // Devuelve el plazo de seguridad.
    getS = () => {
        return this.s;
    }

    // Establece el plazo de seguridad.
    setS = (s) => {
        this.s = s;
    }

    // Devuelve el id del tipo de protección.
    getTp = () => {
        return (this.tp !== null ? this.tp.toString() : null);
    }

    // Establece el id del tipo de protección.
    setTp = (tp) => {
        this.tp = tp;
    }
}

export default TypePhytosanitaryCropInfection;