import React, {Component} from 'react';
import Input from "./Input";
import CustomButton from "./CustomButton";

// Crea un componente de bloque de cooperativa o distribuidor.
class SeasonBlock extends Component {
    // Constructor de SeasonBlock.
    constructor(props) {
        super(props);
        this.id = (props.id !== undefined ? props.id : "");
    }

    // Dibuja el bloque.
    render() {
        return (
            <div className="row mx-0 mb-4 seasonblock">
                <div className="col-lg-7 col-md-12">
                    <div className="row mb-2">
                        <Input id={"season" + this.id} disabled={true} value={this.props.value} />
                    </div>
                    <div className="row">
                        <div className="col-12 p-0">
                            <label>{this.props.yearTxt}</label>
                        </div>
                        <div className="col-12 p-0">
                            <p className="h4 ml-2 txt-dark-gray">{this.props.year}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 p-0">
                    <div className="d-flex justify-content-end">
                        <CustomButton additionalClass={this.props.btnType2} parentFunction={() => {this.props.btnF2(this.props.id)}}>
                            <span className="icon">
                              <i className={this.props.btnIcon2}/>
                            </span>
                            <span className="text">{this.props.btnTxt2}</span>
                        </CustomButton>
                        <CustomButton additionalClass={this.props.btnType} parentFunction={() => {this.props.btnF(this.props.id)}}>
                            <span className="icon">
                              <i className={this.props.btnIcon}/>
                            </span>
                            <span className="text">{this.props.btnTxt}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default SeasonBlock;