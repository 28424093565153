// Clase del objeto SysAdmin.
class SysAdmin {
    // Constructor del objeto SysAdmin.
    constructor(props) {
        this.type = "SysAdmin";
        this.id = (props.id ? props.id : -1);
        this.loginname = (props.loginname ? props.loginname : "");
        this.loginpass = (props.loginpass ? props.loginpass : "");
        this.email = (props.email ? props.email : "");
        this.name = (props.name ? props.name : "");
        this.surname = (props.surname ? props.surname : "");
        this.image = "";
    }
}

export default SysAdmin;