import UserMetadata from "./UserMetadata";
import {datetimeToString} from "../functions/Basic";

// Clase del objeto Metadata.
class Metadata {
    // Constructor del objeto Metadata.
    constructor(props) {
        if (props !== undefined && props !== null) {
            this.type = (props.type !== undefined ? props.type : "");
            this.user = (props.user !== undefined ? new UserMetadata(props.user) : "");
            // noinspection JSUnusedGlobalSymbols
            this.idorigin = (props.idorigin !== undefined ? props.idorigin : "2");
            this.date = (props.date !== undefined ? props.date : datetimeToString(new Date()));
        } else {
            this.type = "";
            this.user = "";
            this.idorigin = "2";
            this.date = datetimeToString(new Date());
        }
    }

    static CREATE_TYPE = "Create";
    static UPDATE_TYPE = "Update";
    static DELETE_TYPE = "Delete";
}

export default Metadata;