import React, {Component} from 'react';
import Input from "../../component/Input";
import CustomButton from "../../component/CustomButton";
// noinspection ES6CheckImport
import {Redirect} from 'react-router-dom';
import Label from "../../component/Label";
import {validateForm, saveAccessToken} from "../../../logic/functions/Basic";
import {getInfoDatabase, logInServer, getSysAdmin} from "../../../logic/functions/ServerPetitions";

// Clase que contiene el formulario de Login.
class LoginForm extends Component {
    // Constructor del formulario de Login.
    // noinspection DuplicatedCode
    constructor(props) {
        super(props);
        this.state = {redirect: false, errors: {loginName: '', loginPassword: ''}};
    }

    // Se comprueba el redirect de estate para comprobar si se ha de realizar una redirección a dashboard.
    render = () => {
        const redirect = this.state.redirect;
        const errors = this.state.errors;
        if (redirect){
            return <Redirect to='/dashboard'/>;
        }

        return (
            <form id="loginForm" className="user" onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <Input type={"text"} id={"loginName"} additionalClass={"form-control-user"} described={"emailHelp"} ph={this.props.strings.loginUsernamePh} onChange={this.handleChange}/>
                    {errors.loginName.length > 0 && <Label for={"loginName"} className={"error"} text={errors.loginName}/>}
                </div>
                <div className="form-group">
                    <Input type={"password"} id={"loginPassword"} additionalClass={"form-control-user"} ph={this.props.strings.loginPasswordPh} onChange={this.handleChange}/>
                    {errors.loginPassword.length > 0 && <Label for={"loginPassword"} className={"error"} text={errors.loginPassword}/>}
                </div>
                <CustomButton type={"submit"} additionalClass={"btn-primary btn-user btn-block"} text={this.props.strings.loginSubmitButton}/>
            </form>
        );
    }

    // Previene realizar la acción por defecto del formulario e inicia la secuencia de peticiones para
    // realizar el LogIn.
    handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm(this.state.errors)) {
            document.getElementById("loginFormLoader").classList.remove("d-none");
            this.getInfoDatabaseResponse();
        }
    }

    // Comprueba si existen cambios en los inputs para establecer los errores.
    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            case 'loginName':
                errors.loginName = value.length < 4 ? this.props.strings.loginLengthMin4 : '';
                break;
            case 'loginPassword':
                errors.loginPassword = value.length < 6 ? this.props.strings.loginLengthMin6 : '';
                break;
            default:
                break;
        }

        this.setState({errors, [name]: value});
    }

    // Comprueba si la base de datos funciona.
    getInfoDatabaseResponse = () => {
        getInfoDatabase().then(
            async response => {
                const data = await response.json();

                if (!response.ok){
                    const error = response.status;
                    return Promise.reject(error);
                }
                if (data.hasOwnProperty('data')) {
                    this.logInServerResponse();
                }
            }
        ).catch( error => {
                this.props.showInfoModal(this.props.strings.maintenanceModalErrorTitle, this.props.strings.maintenanceModalErrorBody);
                console.log(error);
                document.getElementById("loginFormLoader").classList.add("d-none");
            }
        );
    }

    // Realiza la petición de log in al servidor con los datos escritos en el formulario.
    logInServerResponse = () => {
        const loginname = document.getElementById("loginName").value;
        let sha512 = require('js-sha512');
        const password = sha512.sha512(document.getElementById("loginPassword").value);

        logInServer(loginname, password).then(
            async response => {
                const data = await response.json();

                if (!response.ok){
                    const error = response.status;
                    return Promise.reject(error);
                }

                if (data.hasOwnProperty('access_token')){
                    saveAccessToken(data);
                    this.getSysAdminResponse();
                }
            }
        ).catch( error => {
                if (error === 401){
                    this.props.showInfoModal(this.props.strings.loginModalCredentialsErrorTitle, this.props.strings.loginModalCredentialsErrorBody);
                } else {
                    this.props.showInfoModal(this.props.strings.loginModalServerErrorTitle, this.props.strings.loginModalServerErrorBody);
                }
                console.log(error);
                document.getElementById("loginFormLoader").classList.add("d-none");
            }
        );
    }

    // Realiza una petición para obtener el usuario y cambia a la página de dashboard.
    getSysAdminResponse = () => {
        getSysAdmin().then(
            async response => {
                const data = await response.json();

                if (!response.ok){
                    const error = response.status;
                    return Promise.reject(error);
                }

                if (data.hasOwnProperty('data')) {
                    localStorage.setItem(this.props.strings.sysadminKey, JSON.stringify(data.data));
                    this.setState({redirect: true});
                } else {
                    this.props.showInfoModal(this.props.strings.loginModalServerErrorTitle, this.props.strings.loginModalServerErrorBody);
                    document.getElementById("loginFormLoader").classList.add("d-none");
                }
            }
        ).catch( error => {
                this.props.showInfoModal(this.props.strings.loginModalServerErrorTitle, this.props.strings.loginModalServerErrorBody);
                console.log(error);
                document.getElementById("loginFormLoader").classList.add("d-none");
            }
        );
    }
}

export default LoginForm;