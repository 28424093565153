// Clase del objeto JobStatus.
class JobStatus {
    // Constructor del objeto JobStatus.
    constructor(props) {
        this.type = "JobStatus";
        this.id = props.id;
        this.name = props.name;
    }

    // Devuelve si el status ha finalizado o no.
    hasFinished = () => {
        return parseInt(this.id) >= 3 && parseInt(this.id) <= 6;
    }
}

export default JobStatus;