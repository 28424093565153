// Clase que contiene las strings utilizadas en la web.
class Strings {
    // Constructor de las strings.
    constructor() {
        /**** Url del servidor ************************************************************************************************/
        this.BASE_URL = "https://api-cxt.plataformatierra.es/";
        this.RESTFUL_URL = "osigrisapi/";
        this.API_URL = this.BASE_URL + this.RESTFUL_URL;
        this.CLIENT_ID = "CXTierra";
        this.CLIENT_SECRET = "nbNb0JmjFiD7oSG1OqIgYMmYhybF24yWnSjjfbQi0CW6KefiBjkmLSjtKJHZKOLG";

        /**** Modal general de mantenimiento **********************************************************************************/
        this.maintenanceModalErrorTitle = "Una pausa en CXTierra";
        this.maintenanceModalErrorBody = "Lo sentimos, pero hemos desenchufado el servidor para hacernos un café. Vuelve más tarde y comprueba las novedades";

        /**** Modal general de actualización **********************************************************************************/
        this.updateModalOkTitle = "Información actualizada";
        this.updateModalOkBody = "Se ha actualizado la información satisfactoriamente.";
        this.updateModalErrorTitle = "Error al actualizar la información";
        this.updateModalErrorBody = "Ha sido imposible actualizar la información en el sistema.";

        /**** Modal general de obtención de información ***********************************************************************/
        this.getModalErrorTitle = "Error al obtener la información";
        this.getModalErrorBody = "Ha sido imposible obtener la información del sistema.";

        /**** Modal general de confirmación de acción *************************************************************************/
        this.confirmationModalOperation = "Para confirmar esta acción indica el resultado de la siguiente operación:";
        this.confirmationModalTitleError = "Error al confirmar la acción";
        this.confirmationModalBodyError = "El valor que has indicado no coincide con el resultado de la operación. ¿Estarás en buen estado para realizar esta acción? :)";

        /**** Modal de logueo *************************************************************************************************/
        this.loginModalCredentialsErrorTitle = "Error de acceso";
        this.loginModalCredentialsErrorBody = "Las credenciales de acceso son incorrectas";
        this.loginModalServerErrorTitle = "Error de servidor";
        this.loginModalServerErrorBody = "Intenta iniciar sesión más tarde";

        /**** Modal de envío de mensajes **************************************************************************************/
        this.updateModalMessageOkTitle = "Mensaje guardado";
        this.updateModalMessageOkBody = "Se ha guardado la información del mensaje para enviarlo en la fecha indicada.";

        /**** Modal de confirmación de mensajes *********************************************************************************/
        this.confirmationModalMessageTitle = "¿Estás seguro de enviar el mensaje?";
        this.confirmationModalMessageBody = "Ten en cuenta que se enviará este mensaje a muchos usuarios.";
        this.deleteModalMessageTitle = "¿Estás seguro de eliminar el mensaje?";
        this.deleteModalMessageBody = "Ten en cuenta que el mensaje no volverá a estar disponible.";

       /**** Modal de confirmación de descarga de excel *************************************************************************/
        this.confirmationModalDownloadTitle = "¿Estás seguro de descargar el fichero?";
        this.confirmationModalDownloadBody = "Ten en cuenta que este proceso va a tardar varios minutos y debes tener mucha paciencia.";

        /**** Modal de confirmación de actualización ****************************************************************************/
        this.confirmationModalUpdateTitle = "¿Estás seguro de realizar la actualización?";
        this.confirmationModalUpdateBody = "Ten en cuenta que este proceso va a tardar varios minutos y debes tener mucha paciencia.";

        /**** Modal de confirmación de eliminar usuario *************************************************************************/
        this.confirmationModalRemoveTitle = "¿Estás seguro de que quieres eliminar el usuario?";
        this.confirmationModalRemoveBody = "Ten en cuenta que el usuario no volverá a estar disponible.";

        /**** Modal de confirmación de eliminar licencias ***********************************************************************/
        this.confirmationModalRemoveLicenseTitle = "¿Estás seguro de que quieres eliminar las licencias?";
        this.confirmationModalRemoveLicenseBody = "Ten en cuenta que las licencias no volverán a estar disponibles.";

        /**** Modal de confirmación de eliminar CI ******************************************************************************/
        this.confirmationModalRemoveCITitle = "¿Estás seguro de que quieres eliminar el CI indicado?";
        this.confirmationModalRemoveCIBody = "Ten en cuenta que el CI no volverá a estar disponible.";

        /**** Modal de confirmación de eliminar Organización ******************************************************************************/
        this.confirmationModalRemoveOrgTitle = "¿Estás seguro de que quieres eliminar la organización indicada?";
        this.confirmationModalRemoveOrgBody = "Ten en cuenta que la organización no volverá a estar disponible.";

        /**** Key del usuario guardado en localStorage ************************************************************************/
        this.sysadminKey = "sysadmin";
        this.credentialsKey = "credentials";

        /**** Contenido del modal loader **************************************************************************************/
        this.loaderLoading = '<h5 class="modal-title mb-3 text-center">Cargando información</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.logoutContent = '<h5 class="modal-title mb-3 text-center">Cerrando sesión</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.editingMessage = '<h5 class="modal-title mb-3 text-center">Actualizando mensaje</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingMessage = '<h5 class="modal-title mb-3 text-center">Eliminando mensaje</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.loadingUsersMessage = '<h5 class="modal-title mb-3 text-center">Cargando usuarios</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingUserMessage = '<h5 class="modal-title mb-3 text-center">Eliminando usuario</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.editingOrg = '<h5 class="modal-title mb-3 text-center">Editando organización</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingOrg = '<h5 class="modal-title mb-3 text-center">Eliminando organización</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.downloadingLicenses = '<h5 class="modal-title mb-3 text-center">Descargando licencias</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.editingLicenses = '<h5 class="modal-title mb-3 text-center">Actualizando licencias</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingLicenses = '<h5 class="modal-title mb-3 text-center">Eliminando licencias</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.updatingUserSeasons = '<h5 class="modal-title mb-3 text-center">Actualizando explotaciones de usuario</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingSeason = '<h5 class="modal-title mb-3 text-center">Eliminando usuario de la explotación</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.loadingRoles = '<h5 class="modal-title mb-3 text-center">Cargando roles</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.downloadingUserSeasons = '<h5 class="modal-title mb-3 text-center">Descargando explotaciones de usuario</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.downloadingPhytoInfo = '<h5 class="modal-title mb-3 text-center">Cargando información de fitosanitario</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.updatingPhytoInfo = '<h5 class="modal-title mb-3 text-center">Actualizando información de fitosanitario</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';
        this.removingPhyto = '<h5 class="modal-title mb-3 text-center">Eliminando fitosanitarios</h5><div class="row justify-content-center"><div class="spinner-border text-success" role="status"><span class="sr-only">Loading...</span></div></div>';

        /**** Strings comunes de página **************************************************************************************/
        this.copyright = "© " + new Date().getFullYear() + " oSIGris Sistemas de Información S.L.";
        this.information = "Información";
        this.management = "Gestión";
        this.dashboardSidebar = "Dashboard";
        this.userSidebar = "Usuarios";
        this.orgSidebar = "Organización";
        this.messageSidebar = "Mensajes";
        this.seasonSidebar = "Explotaciones";
        this.phytosanitarySidebar = "Tratamientos";
        this.toolSidebar = "Herramientas";
        this.amount1 = "Existen un total de ";

        this.tableSearch = "Buscar...";
        this.tableSearchError = "Error en búsqueda.";
        this.tablePaginationFirst = "Primera";
        this.tablePaginationLast = "Última";
        this.tableId = "ID";
        this.tableLicenseSubtype = "Tipo de licencia";
        this.tableName = "Nombre";
        this.tableUsername = "Nombre de usuario";
        this.tableEmail = "Email";
        this.tableSurname = "Apellidos";
        this.tableNSeason = "Nº explotaciones";
        this.tableTypeKey = "Tipo de licencia";
        this.tablePhone = "Teléfono";
        this.tableNIF = "NIF";
        this.tableUser = "Usuario";
        this.tableDate = "Fecha";
        this.tableCompany = "Empresa";
        this.tableKey = "Clave";
        this.tableKeyUser = "Usuario asignado";
        this.tableOrganization = "Organización";
        this.tablePremiumPhyto = "Fecha fitosanitarios";
        this.tablePremiumTech = "Fecha técnico";
        this.tablePremiumAnalysis = "Fecha análisis";
        this.tablePremiumStock = "Fecha stock";

        this.modalConfirm = "Entendido";
        this.modalPrimaryButton = "Confirmar";
        this.modalSecondaryButton = "Cancelar";
        this.modalSendButton = "Enviar";
        this.downloadButton = "Descargar";
        this.updateButton = "Actualizar";
        this.removeButton = "Eliminar";
        this.saveButton = "Guardar";
        this.loadImgButton = "Cargar imagen";
        this.loadMoreButton = "Cargar más";
        this.editLicensesButton = "Editar licencias";
        this.searchButton = "Buscar";

        this.yes = "SI";
        this.no = "NO";

        this.selectDatePh = "Selecciona una fecha";
        this.asyncSearchUserPh = "Introduce un nombre de usuario, email, nombre o apellidos";

        this.operationConfirmation = "Introduce el resultado";

        this.osigrisExcel = "CXTierra";
        this.excelFormat = ".xlsx";

        this.unspecified = "Sin especificar";

        /**** Strings de Dashboard *****************************************************************************/
        this.dashboardTitle = "CXTierra Admin | Dashboard";
        this.resetZoom = "Restablecer zoom";
        this.dashboardUser = "Incremento de usuarios";
        this.dashboardNUser = "Nº usuarios";
        this.dashboardSeason = "Incremento de temporadas";
        this.dashboardNSeason = "Nº temporadas";
        this.dashboardCrop = "Incremento de cultivos";
        this.dashboardNCrop = "Nº cultivos";
        this.dashboardMachine = "Incremento de maquinaria";
        this.dashboardNMachine = "Nº maquinaria";
        this.dashboardMessage = "Incremento de mensajes";
        this.dashboardNMessage = "Nº mensajes";
        this.dashboardNotification = "Incremento de notificaciones";
        this.dashboardNNotification = "Nº notificaciones";
        this.dashboardInfection = "Incremento de infecciones";
        this.dashboardNInfection = "Nº infecciones";
        this.dashboardFertilize = "Incremento de fertilizantes";
        this.dashboardNFertilize = "Nº fertilizantes";
        this.dashboardPhytosanitary = "Incremento de fitosanitarios";
        this.dashboardNPhytosanitary = "Nº fitosanitarios";
        this.dashboardCollect = "Incremento de recolectas";
        this.dashboardNCollect = "Nº recolectas";
        this.dashboardWork = "Incremento de labores";
        this.dashboardNWork = "Nº labores";
        this.dashboardIrrigation = "Incremento de riegos";
        this.dashboardNIrrigation = "Nº riegos";
        this.dashboardShepherd = "Incremento de pastoreos";
        this.dashboardNShepherd = "Nº pastoreos";
        this.dashboardNote = "Incremento de notas";
        this.dashboardNNote = "Nº notas";

        /**** Strings de Organización *****************************************************************************/
        this.orgTitle = "CXTierra Admin | Organizaciones";
        this.orgAmount2 = " organizaciones.";
        this.orgTableNotFound = "No se han encontrado organizaciones.";
        this.orgInfoPageTitle = "Información de organizaciones";
        this.orgManagementPageTitle = "Gestión de organizaciones";
        this.orgEditTitle = "Editar organización";
        this.orgNamePh = "Introduce el nombre de la organización";
        this.orgAliasPh = "Introduce el alias de la organización";
        this.orgLoadingOrganization = "Cargando organizaciones";
        this.tableOrgSubtype = "Tipo de organización";
        this.tableOrgColor1 = "Color 1";
        this.tableOrgColor2 = "Color 2";
        this.tableOrgColor3 = "Color 3";
        this.tableOrgColorReset = "Resetear colores";
        this.tableOrgAlias = "Alias";
        this.tableOrgNl = "Nº admin.";
        this.tableOrgNm = "Nº técnicos";
        this.tableOrgNe = "Nº externos";
        this.tableOrgNf = "Nº explotaciones";
        this.tableOrgNel = "Nº admin. activos";
        this.tableOrgNem = "Nº técnicos activos";
        this.tableOrgNee = "Nº externos activos";
        this.tableOrgNes = "Nº agricultores activos";
        this.tableOrgNef = "Nº explotaciones activas";
        this.orgNameModalErrorTitle = "Nombre de organización no válido";
        this.orgNameModalErrorBody = "El nombre de la organización ha de tener entre 3 y 200 caracteres.";
        this.orgNfModalErrorTitle = "Número de explotaciones no válido";
        this.orgNfModalErrorBody = "El número de explotaciones de la organización ha de ser mayor que 0 e igual o mayor que el número de explotaciones anterior.";
        this.orgLicensesModalErrorTitle = "Número de licencias no válido";
        this.orgLicensesModalErrorBody = "El número de licencias de la organización ha de ser igual o mayor que el número de licencias anterior.";
        this.orgPremiumModalErrorTitle = "Fecha premium no válida";
        this.orgPremiumModalErrorBody = "Todas las fechas de premium no pueden estar vacías.";
        this.orgCreateTitle = "Crear organización";
        this.orgModalEditlicenses = "Editar licencias de la organización";
        this.orgLicenseTableNotFound = "No se han encontrado licencias.";
        this.orgLicenseLoading = "Cargando licencias";

        /**** Strings de licencias **************************************************************************/
        this.licenseNSeasonPh = "Introduce el número de explotaciones para las que se quiere licencia.";
        this.licenseCreateTechSeasonErrorTitle = "Error en campo nº explotaciones";
        this.licenseCreateTechSeasonErrorBody = "Se ha de introducir un número de explotaciones mayor que cero.";
        this.licenseCreatePremiumErrorTitle = "Error en fechas premium";
        this.licenseCreatePremiumErrorBody = "Se ha de establecer como mínimo la fecha fin de un premium.";
        this.licenseTypeErrorTitle = "Licencia existente";
        this.licenseUserTypeErrorBody = "El usuario ya tiene una licencia existente con una organización. " +
            "Accede a gestión de licencias de la organización para modificarla.";
        this.licenseSupportTypeErrorBody = "El usuario ya tiene una licencia de soporte. Contácta con oSIGris para modificarla.";
        this.licenseDownloadLicensesSheetTitle1 = "Licencias de ";
        this.licenseDownloadOrgLicensesTitle = "Licencias de organización";

        /**** Strings de Login *****************************************************************************/
        this.loginWelcome = "¡Bienvenido!";
        this.loginUsernamePh = "usuario";
        this.loginPasswordPh = "contraseña";
        this.loginSubmitButton = "entrar";

        this.loginLengthMin4 = "Mínimo 4 caracteres";
        this.loginLengthMin6 = "Mínimo 6 caracteres";

        /**** Strings de Message *****************************************************************************/
        this.messageTitle = "CXTierra Admin | Mensajes";
        this.messageInfoPageTitle = "Información de mensajes";
        this.messageEditTitle = "Editar mensaje";
        this.messageSubjectTitle = "Asunto";
        this.messageSubjectPh = "Introduce el asunto del mensaje";
        this.messageBodyTitle = "Mensaje";
        this.messageBodyPh = "Introduce el cuerpo del mensaje";
        this.messageDateTitle = "Fecha de envío";
        this.messageManagementPageTitle = "Gestión de mensajes";
        this.messageOsigrisMessageTitle = "Enviar mensaje de CXTierra";
        this.messageAreeiroMessageTitle = "Enviar mensaje de Areeiro";

        /**** Strings de Season *****************************************************************************/
        this.seasonTitle = "CXTierra Admin | Explotaciones";
        this.seasonManagementPageTitle = "Gestión de explotaciones";
        this.seasonDownloadProvince = "Descargar excel por provincias";
        this.seasonDownloadTown = "Descargar excel por municipios";
        this.seasonUserSeasonManagement = "Administrar explotaciones de usuario";
        this.seasonProvinceExcelTitle = "Resumen provincias ";
        this.seasonExcelProvinceCode = "Código provincia";
        this.seasonExcelNSigpac = "Nº sigpacs";
        this.seasonExcelNCrop = "Nº cultivos";
        this.seasonExcelNInfection = "Nº infecciones";
        this.seasonExcelNFertilize = "Nº fertilizantes";
        this.seasonExcelNPhyto = "Nº fitosanitarios";
        this.seasonExcelNWork = "Nº labores";
        this.seasonExcelNCollect = "Nº recolectas";
        this.seasonExcelNShepherd = "Nº pastoreo";
        this.seasonExcelNState = "Nº estados crecimiento";
        this.seasonTownExcelTitle = "Resumen municipios ";
        this.seasonExcelINE = "Código INE";
        this.seasonYear = "Campaña";
        this.seasonAddUserSeasons = "Añadir explotaciones";
        this.seasonDownloadUserSeasons = "Descargar explotaciones";
        this.seasonUserExcelTitle = "Explotaciones de ";
        this.seasonName = "Nombre de explotación";
        this.seasonId = "ID de explotación";
        this.seasonEditRole = "Editar rol";

        /**** Strings de Tratamientos *********************************************************************/
        this.phytosanitaryTitle = "CXTierra Admin | Tratamientos";
        this.phytosanitaryInfoPageTitle = "Información de tratamientos";
        this.phytosanitaryTableKey = "Key";
        this.phytosanitaryTableId = "ID";
        this.phytosanitaryTableNReg = "Número de registro";
        this.phytosanitaryTablePDF = "ID PDF";
        this.phytosanitaryTableName = "Producto";
        this.phytosanitaryTableSubs = "Materia activa";
        this.phytosanitaryTableComp = "Casa comercial";
        this.phytosanitaryTableMix = "Mezclable";
        this.phytosanitaryTableEDate = "Fecha caducidad";
        this.phytosanitaryTableCDate = "Fecha cancelación";
        this.phytosanitaryTableNotFound = "No se han encontrado tratamientos.";
        this.phytosanitaryLoadingPhytos = "Cargando tratamientos";
        this.phytosanitaryPdfErrorTitle = "Error al buscar el tratamiento";
        this.phytosanitaryPdfErrorBody = "Ha ocurrido un error a la hora de buscar el pdf del tratamiento";
        this.phytosanitaryInfoErrorTitle = "Error al buscar la información del tratamiento";
        this.phytosanitaryInfoErrorBody = "Ha ocurrido un error a la hora de buscar la información del tratamiento";
        this.phytosanitaryModalFilterTitle = "Añadir filtros";
        this.phytosanitaryFilterName = "Filtrar por número de registro o nombre de producto";
        this.phytosanitaryFilterCrop = "Cultivo";
        this.phytosanitaryFilterProblem = "Problema";
        this.phytosanitaryFilterSubs = "Materia activa";
        this.phytosanitaryFilterComp = "Casa comercial";
        this.phytosanitaryClearButton = "Limpiar filtros";
        this.phytosanitaryManagementPageTitle = "Gestión de tratamientos";
        this.phytosanitaryEditInfo = "Editar información de fitosanitario";
        this.phytosanitaryTablePhyto = "Tratamiento";
        this.phytosanitarySearchName = "Buscar por número de registro o nombre de producto";
        this.phytosanitaryTableCode = "Nº registro";
        this.phytosanitaryEditCIButton = "Editar CI";
        this.phytosanitaryExpirationDate = "Fecha caducidad";
        this.phytosanitaryCancelDate = "Fecha cancelación";
        this.phytosanitaryLimitDate = "Fecha límite";
        this.phytosanitaryMixable = "Mezclable";
        this.phytosanitaryNames = "Denominaciones comunes";
        this.phytosanitaryEditCITitle = "Editar CI del tratamiento ";
        this.phytosanitaryLoadingCI = "Cargando CI del tratamiento";
        this.phytosanitaryCITableNotFound = "No se han encontrado CI en el tratamiento.";
        this.phytosanitaryTableCrop = "Cultivo";
        this.phytosanitaryTableProblem = "Problema";
        this.phytosanitaryTableMND = "Dosis Mín";
        this.phytosanitaryTableMXD = "Dosis Máx";
        this.phytosanitaryTableMD = "Unidad Dosis";
        this.phytosanitaryTableMNA = "Nº Aplic Mín";
        this.phytosanitaryTableMXA = "Nº Aplic Máx";
        this.phytosanitaryTableMNI = "Interval Mín";
        this.phytosanitaryTableMXI = "Interval Máx";
        this.phytosanitaryTableMNS = "Caldo Mín";
        this.phytosanitaryTableMXS = "Caldo Máx";
        this.phytosanitaryTableMS = "Unidad Caldo";
        this.phytosanitaryTableMNDA = "Dosis Aplic Mín";
        this.phytosanitaryTableMXDA = "Dosis Aplic Máx";
        this.phytosanitaryTableMDA = "Unidad Dosis Aplic";
        this.phytosanitaryTableS = "Plazo Seguridad";
        this.phytosanitaryTableTP = "Protección"
        this.phytosanitaryAddCI = "Añadir CI";
        this.phytosanitaryCIErrorTitle = "Error al crear o editar el CI";
        this.phytosanitaryCIErrorBody = "Se ha de seleccionar un tipo de cultivo y de problema para poder continuar.";

        /**** Strings de Tool *****************************************************************************/
        this.toolTitle = "CXTierra Admin | Herramientas";
        this.toolManagementPageTitle = "Herramientas";
        this.toolUpdateInfo = "Actualizar información básica";
        this.toolUpdatePhyto = "Actualizar información Fitosanitarios";
        this.toolUpdateSigpac = "Actualizar información SigPac";
        this.toolUpdateHash = "Actualizar información Hash";

        /**** Strings de User *****************************************************************************/
        this.userTitle = "CXTierra Admin | Usuarios";
        this.userTablePremiumCoop = "Premium Coop/Técnico";
        this.userTablePremiumOffline = "Premium Offline";
        this.userTablePremiumPhyto = "Premium Fitosanitario";
        this.userTablePremiumAnalysis = "Premium Análisis";
        this.userTablePremiumStock = "Premium Stock";
        this.userTableNotFound = "No se han encontrado usuarios.";
        this.userInfoPageTitle = "Información de usuarios";
        this.userLoadingUsers = "Cargando usuarios";
        this.userManagementPageTitle = "Gestión de usuarios";
        this.userPremiumOfflineDateTitle = "Fecha límite de premium offline";
        this.userPremiumPhytoDateTitle = "Fecha límite de premium fitosanitarios";
        this.userPremiumAnalysisDateTitle = "Fecha límite de premium análisis";
        this.userPremiumStockDateTitle = "Fecha límite de premium stock";
        this.userInvoice = "Facturar Premium";
        this.userKey = "Gestionar licencia de usuario";
        this.userDashboard = "Dashboard de usuario";
        this.userInvoiceCoop = "Cooperativa/técnico";
        this.userInvoiceOffline = "Offline";
        this.userInvoicePhyto = "Fitosanitario";
        this.userInvoiceAnalysis = "Análisis";
        this.userInvoiceStock = "Stock";
        this.userRemoveUserTitle = "Eliminar Usuario";
        this.userDownloadUsers = "Descargar Usuarios";
        this.userAmount2 = " usuarios.";
        this.userSummary = "Resumen usuarios ";
        this.userNSeason = "Hoja explotaciones";
        this.userNCrop = "Hoja cultivos";
        this.userNMachine = "Hoja maquinaria";
        this.userNMessage = "Hoja mensajes";
        this.userNNotification = "Hoja notificaciones";
        this.userNInfection = "Hoja plagas";
        this.userNFertilize = "Hoja fertilizantes";
        this.userNPhytosanitary = "Hoja fitosanitarios";
        this.userNCollect = "Hoja recolectas";
        this.userNWork = "Hoja labores";
        this.userNIrrigation = "Hoja riegos";
        this.userNShepherd = "Hoja pastoreos";
        this.userNNote = "Hoja notas";
    }

    static defaultColor1 = "#333333";
    static defaultColor2 = "#008A9B";
    static defaultColor3 = "#109DC2";

    // Devuelve las credenciales de acceso.
    getCredentials = () => {
        let strings = new Strings();
        return JSON.parse(localStorage.getItem(strings.credentialsKey));
    }
}

export default Strings;