// Clase del objeto KTP.
class KTP {
    // Constructor del objeto KTP.
    constructor(props) {
        this.type = "KTP";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : -1);
            this.idtp = (props.idtp !== "" && props.idtp !== undefined ? props.idtp : "");
            this.enddate = props.enddate;
        } else {
            this.id = -1;
            this.idtp = "";
            this.enddate = "";
        }
    }

    static ID_MANAGER_PREMIUM = 1;
    static ID_OFFLINE_PREMIUM = 2;
    static ID_PHYTOSANITARY_PREMIUM = 3;
    static ID_ANALYSIS_PREMIUM = 4;
    static ID_STOCK_PREMIUM = 5;

    // Obtiene el id del tipo de premium.
    getId = () =>  {
        return this.id;
    }

    // Obtiene el id tipo del tipo de premium.
    getIdtp = () =>  {
        return this.idtp;
    }

    // Obtiene la fecha fin del tipo de premium.
    getEndDate = () => {
        return this.enddate;
    }

    // Establece la fecha fin del tipo de premium.
    setEndDate = (enddate) => {
        this.enddate = enddate;
    }
}

export default KTP;