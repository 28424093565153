// noinspection DuplicatedCode

import React, {Component} from "react";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Input from "../../component/Input";
// noinspection NpmUsedModulesInstalled
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import CustomButton from "../../component/CustomButton";
import {SketchPicker} from "react-color";
import Flatpickr from "react-flatpickr";
import UploadImage from "../../component/UploadImage";
import Strings from "../../../logic/strings/Strings";
import {
    createOrganizationServer, getAsyncOrgLicenseServer, getJobAsync, getJobAsyncResult,getTypeOrganization
} from "../../../logic/functions/ServerPetitions";
import TypeOrganization from "../../../logic/objects/TypeOrganization";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Organization from "../../../logic/objects/Organization";
import KTP from "../../../logic/objects/KTP";
import Key from "../../../logic/objects/Key";
import JobAsync from "../../../logic/objects/JobAsync";

// Clase que contiene el contenido de la página OrganizationManagement.
class OrganizationManagement extends Component {
    // Constructor de clase Organizationmanagement.
    constructor(props) {
        super(props);
        this.state = {orgTypes: [], color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3,
            orgKeyDateB: undefined, orgKeyDateC: undefined,  orgKeyDateD: undefined, orgKeyDateE: undefined, selectedImg: null,
            showOrgModal: false, downloadName: null};
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.orgTitle;
        getTypeOrganization().then(async orgTypesList => {
                const orgTypesJson = await orgTypesList.json();

                if (!orgTypesList.ok){
                    const error = orgTypesList.status;
                    window.location.href = '/';
                    return Promise.reject(error);
                }

                if (orgTypesJson.hasOwnProperty('data')){
                    let orgTypes = [];
                    for (let i in orgTypesJson.data) {
                        orgTypes.push(new TypeOrganization(orgTypesJson.data[i]));
                    }
                    this.setState({orgTypes: orgTypes}, () => {
                        let orgOptions = "";
                        for (let i in this.state.orgTypes){
                            orgOptions += "<option value=" + this.state.orgTypes[i].getId() + " " + (this.state.orgTypes[i].getId() === 1 ? "selected" : "") + ">" + this.state.orgTypes[i].getName() + "</option>";
                        }
                        document.getElementById("creaOrgSubtype").innerHTML = orgOptions;
                        endPreLoad();
                    });
                } else {
                    refreshIfNeeded(orgTypesJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch( error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                endPreLoad();
            });
    }

    // Dibuja la página de gestión de organizaciones.
    render = () => {
        return <div className="container-fluid">
            {/* Page Heading */}
            <PageTitle text={this.props.strings.orgManagementPageTitle}/>

            <div className="row">
                <div className="col-12">
                    {/* Collapsible de crear organización */}
                    <Collapsible triggerId={"collapseCreateOrgCardTrigger"} collapseId={"collapseCreateOrgCard"} cardTitle={this.props.strings.orgCreateTitle} >
                        <div id="orgCreateFormLoader" className="form-loader d-none"/>
                        <form id="orgCreateForm">
                            <div className="row">
                                <FormGroup className="col-6">
                                    <FormLabel>{this.props.strings.tableName}</FormLabel>
                                    <Input id={"creaOrgName"} type={"text"} ph={this.props.strings.orgNamePh} />
                                </FormGroup>
                                <FormGroup className="col-6">
                                    <FormLabel>{this.props.strings.tableOrgAlias}</FormLabel>
                                    <Input id={"creaOrgAlias"} type={"text"} ph={this.props.strings.orgAliasPh} />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-12">
                                    <FormLabel>{this.props.strings.tableOrgSubtype}</FormLabel>
                                    <select id={"creaOrgSubtype"} className="form-control" />
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor1}</FormLabel>
                                    <SketchPicker color={this.state.color1} onChangeComplete={this.handleColor1Change} />
                                </FormGroup>
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor2}</FormLabel>
                                    <SketchPicker color={this.state.color2} onChangeComplete={this.handleColor2Change} />
                                </FormGroup>
                                <FormGroup className="col-4">
                                    <FormLabel>{this.props.strings.tableOrgColor3}</FormLabel>
                                    <SketchPicker color={this.state.color3} onChangeComplete={this.handleColor3Change} />
                                </FormGroup>
                                <FormGroup className="col-3 offset-4 form-group pl-5 mt-3">
                                    <CustomButton additionalClass="btn-primary" parentFunction={this.handleResetColors}>{this.props.strings.tableOrgColorReset}</CustomButton>
                                </FormGroup>
                            </div>
                            <div className="row">
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNl}</FormLabel>
                                    <Input id={"creaOrgNl"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNm}</FormLabel>
                                    <Input id={"creaOrgNm"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNe}</FormLabel>
                                    <Input id={"creaOrgNe"} type={"number"} min={"0"} />
                                </FormGroup>
                                <FormGroup className="col-3">
                                    <FormLabel>{this.props.strings.tableOrgNf}</FormLabel>
                                    <Input id={"creaOrgNf"} type={"number"} min={"0"} />
                                </FormGroup>
                            </div>
                            <label className="d-none mt-3">{this.props.strings.userPremiumOfflineDateTitle}</label>
                            <div className="d-none row m-0">
                                <Flatpickr id="orgKeyDateB" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateB} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateB: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateB}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumPhytoDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateC" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateC} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateC: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateC}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumAnalysisDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateD" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateD} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateD: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateD}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <label className="mt-3">{this.props.strings.userPremiumStockDateTitle}</label>
                            <div className="row m-0">
                                <Flatpickr id="orgKeyDateE" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                           value={this.state.orgKeyDateE} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                           onChange={date => {
                                               this.setState({orgKeyDateE: date});
                                           }} />
                                <div className={"col-1 p-0"}>
                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearOrgKeyDateE}>
                                        <i className="fas fa-trash"/>
                                    </CustomButton>
                                </div>
                            </div>

                            <div id="hideOrgImg" className="d-none row justify-content-center">
                                <div className="col-12 d-flex justify-content-center">
                                    <img id="orgImg" className="load-img-bg" alt="Logo de organización" width={"350px"} />
                                </div>
                                <div className="col-12 d-flex justify-content-center">
                                    <button className="btn btn-danger my-3" onClick={() => this.clearOrgImg()}>
                                        <i className="fas fa-trash" />
                                    </button>
                                </div>
                            </div>

                            <div className="row mt-3 justify-content-center">
                                <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.openModalImg}>
                                    <span className="icon">
                                      <i className="fas fa-upload"/>
                                    </span>
                                    <span className="text">{this.props.strings.loadImgButton}</span>
                                </CustomButton>
                            </div>
                            <div className="row">
                                <div className={"col-2 ml-auto text-right"}>
                                    <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.createOrg}>
                                        <span className="icon">
                                          <i className="fas fa-check"/>
                                        </span>
                                        <span className="text">{this.props.strings.modalPrimaryButton}</span>
                                    </CustomButton>
                                </div>
                            </div>
                        </form>
                    </Collapsible>
                </div>
            </div>

            {/* Modal Org Img */}
            <Modal show={this.state.showOrgModal} size="lg" backdrop="static" onHide={this.cancelOrgImgModal} centered>
                <ModalHeader closeButton>
                    <ModalTitle>
                        {this.props.strings.loadImgButton}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form>
                        <div className="row mt-3 justify-content-center">
                            <UploadImage strings={this.props.strings} initialLoad={false} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.cancelOrgImgModal}/>
                    <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.acceptOrgImgModal}/>
                </ModalFooter>
            </Modal>
            {/* End of Modal org Img */}
        </div>;
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor1Change = (color) => {
        this.setState({color1: color.hex}, () => {
        });
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor2Change = (color) => {
        this.setState({color2: color.hex});
    }

    // Realiza el cambio del color 1 de la organización.
    handleColor3Change = (color) => {
        this.setState({color3: color.hex});
    }

    // Realiza el reseteo de colores de la organización.
    handleResetColors = () => {
        this.setState({color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3});
    }

    // Limpia la fecha de premium offline de key de organización.
    clearOrgKeyDateB = () => {
        this.setState({orgKeyDateB: undefined});
    }

    // Limpia la fecha de premium fitosanitarios de key de organización.
    clearOrgKeyDateC = () => {
        this.setState({orgKeyDateC: undefined});
    }

    // Limpia la fecha de premium análisis de key de organización.
    clearOrgKeyDateD = () => {
        this.setState({orgKeyDateD: undefined});
    }

    // Limpia la fecha de premium stock de key de organización.
    clearOrgKeyDateE = () => {
        this.setState({orgKeyDateE: undefined});
    }

    // Carga el archivo como cadena en base64 para adjuntarla en la organización.
    loadOrgImg = (org) => {
        if (this.state.selectedImg !== null) {
            // noinspection JSUnresolvedReference
            const blobURL = window.URL.createObjectURL(this.state.selectedImg);
            const img = new Image();
            img.src = blobURL;
            img.onload = () => {
                // noinspection JSUnresolvedReference
                window.URL.revokeObjectURL(blobURL);
                let maxSize = 800;
                const canvas = document.createElement('canvas');
                let finalW;
                let finalH;
                if (img.width > img.height) {
                    if (img.width > maxSize) {
                        let reduceFactor = img.width / maxSize;
                        finalW = maxSize;
                        finalH = img.height / reduceFactor;
                    } else {
                        finalW = img.width;
                        finalH = img.height;
                    }
                } else {
                    if (img.height > maxSize) {
                        let reduceFactor = img.height / maxSize;
                        finalH = maxSize;
                        finalW = img.width / reduceFactor;
                    } else {
                        finalW = img.width;
                        finalH = img.height;
                    }
                }
                canvas.width = finalW;
                canvas.height = finalH;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, finalW, finalH);

                canvas.toBlob((blob) => {
                    let reader = new FileReader();
                    reader.onload = (_file) => {
                        this.toDataURL(_file.target.result, (dataUrl) => {
                            org.image = dataUrl.split("base64,")[1];
                        });
                    }
                    reader.readAsDataURL(blob);
                }, 'image/png', 0.7);
            }
        }
    }

    // Convierte la imagen a base64 necesario para guardarse.
    toDataURL = (url, callback) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = function() {
            let reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // Abre el modal de importar imagen de organización.
    openModalImg = () => {
        this.setState({showOrgModal: true});
    }

    // Cierra el modal de importar imagen de organización.
    cancelOrgImgModal = () => {
        if (document.getElementById("uploadImageClear")) {
            document.getElementById("uploadImageClear").click();
        }
        document.getElementById("uploadImageInput").value = null;
        this.setState({showOrgModal: false});
    }

    // Acepta la imagen cargada para la organización y cierra el modal.
    acceptOrgImgModal = () => {
        if (document.getElementById("uploadImageInput").files.length > 0){
            if (FileReader) {
                let fr = new FileReader();
                fr.onload = function () {
                    document.getElementById("orgImg").src = fr.result;
                }
                fr.readAsDataURL(document.getElementById("uploadImageInput").files[0]);
            }
            document.getElementById("hideOrgImg").classList.remove("d-none");
            this.setState({selectedImg: document.getElementById("uploadImageInput").files[0], showOrgModal: false});
        }
    }

    // Limpia la imagen cargada para la organización.
    clearOrgImg = () => {
        this.setState({selectedImg: null})
        document.getElementById("orgImg").src = "";
        document.getElementById("hideOrgImg").classList.add("d-none");
    }

    // Establece los campos a editar en la organización.
    setCreaOrgData = () => {
        let orgSub;
        for (let i in this.state.orgTypes){
            if (this.state.orgTypes[i].getId().toString() === document.getElementById("creaOrgSubtype").value){
                orgSub = new TypeOrganization(this.state.orgTypes[i]);
                break;
            }
        }
        let auxDate;
        let licensePremium = [];
        if (document.getElementById("orgKeyDateB").value !== "") {
            auxDate = document.getElementById("orgKeyDateB").value;
            auxDate += " 00:00:00";
            licensePremium.push(new KTP({idtp: KTP.ID_OFFLINE_PREMIUM, enddate: auxDate}));
        }

        if (document.getElementById("orgKeyDateC").value !== "") {
            auxDate = document.getElementById("orgKeyDateC").value;
            auxDate += " 00:00:00";
            licensePremium.push(new KTP({idtp: KTP.ID_PHYTOSANITARY_PREMIUM, enddate: auxDate}));
        }

        if (document.getElementById("orgKeyDateD").value !== "") {
            auxDate = document.getElementById("orgKeyDateD").value;
            auxDate += " 00:00:00";
            licensePremium.push(new KTP({idtp: KTP.ID_ANALYSIS_PREMIUM, enddate: auxDate}));
        }

        if (document.getElementById("orgKeyDateE").value !== "") {
            auxDate = document.getElementById("orgKeyDateE").value;
            auxDate += " 00:00:00";
            licensePremium.push(new KTP({idtp: KTP.ID_STOCK_PREMIUM, enddate: auxDate}));
        }

        let org = new Organization({
            name: document.getElementById("creaOrgName").value,
            alias: document.getElementById("creaOrgAlias").value,
            subtype: orgSub,
            nl: parseInt(document.getElementById("creaOrgNl").value),
            nm: parseInt(document.getElementById("creaOrgNm").value),
            ne: parseInt(document.getElementById("creaOrgNe").value),
            nf: parseInt(document.getElementById("creaOrgNf").value),
            color1: this.state.color1,
            color2: this.state.color2,
            color3: this.state.color3,
            premium: licensePremium,
        });
        this.loadOrgImg(org);

        return org;
    }

    // Manda la petición de crear la organización al servidor.
    createOrg = () => {
        let orgName = document.getElementById("creaOrgName").value;
        if (orgName.length > 2 && orgName.length <= 200) {
            if (document.getElementById("creaOrgNf").value > 0) {
                if (document.getElementById("creaOrgNl").value >= 0
                    || document.getElementById("creaOrgNm").value >= 0
                    || document.getElementById("creaOrgNe").value >= 0) {
                    if (document.getElementById("orgKeyDateC").value !== ""
                        || document.getElementById("orgKeyDateD").value !== ""
                        || document.getElementById("orgKeyDateE").value !== "") {
                        this.setState({showOrgModal: false}, () => {
                            this.showOrgFormLoader();
                            let json = {};
                            json.data = this.setCreaOrgData();
                            setTimeout(() => {
                                createOrganizationServer(json).then(async orgAsync => {
                                    const orgJson = await orgAsync.json();

                                    if (!orgAsync.ok) {
                                        const error = orgAsync.status;
                                        return Promise.reject(error);
                                    }

                                    if (orgJson.hasOwnProperty('data')) {
                                        let jobAsync = new JobAsync(orgJson.data);
                                        this.getJobAsyncCreateOrg(jobAsync.id, this.props.globals.getMinTimeout(), orgName);
                                    } else {
                                        refreshIfNeeded(orgJson, this.props.hideLoaderModal, this.props.showInfoModal);
                                    }
                                }).catch(error => {
                                    this.clearCreateOrg();
                                    this.hideOrgFormLoader();
                                    this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                                    console.log(error);
                                });
                            }, 500);
                        });
                    } else {
                        this.props.showInfoModal(this.props.strings.orgPremiumModalErrorTitle, this.props.strings.orgPremiumModalErrorBody);
                    }
                } else {
                    this.props.showInfoModal(this.props.strings.orgLicensesModalErrorTitle, this.props.strings.orgLicensesModalErrorBody);
                }
            } else {
                this.props.showInfoModal(this.props.strings.orgNfModalErrorTitle, this.props.strings.orgNfModalErrorBody);
            }
        } else {
            this.props.showInfoModal(this.props.strings.orgNameModalErrorTitle, this.props.strings.orgNameModalErrorBody);
        }
    }

    // Petición asíncrona de crear organización.
    getJobAsyncCreateOrg = (id, timeout, orgName) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.createOrgResponse(jobAnswerJson, orgName);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.props.hideLoaderModal, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncCreateOrg(id, timeout, orgName);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }, timeout);
    }

    // Función a realizar tras la respuesta a la petición asíncrona de crear organización.
    createOrgResponse = (orgJson, orgName) => {
        this.clearCreateOrg();
        this.hideOrgFormLoader();
        this.setState({downloadName: orgName}, () => {
            this.downloadOrg(orgJson.data.id);
        });
    }

    // Muestra el loader de crear organización.
    showOrgFormLoader = () => {
        document.getElementById("orgCreateForm").classList.add("d-none");
        document.getElementById("orgCreateFormLoader").classList.remove("d-none");
    }

    // Oculta el loader de crear organización.
    hideOrgFormLoader = () => {
        document.getElementById("orgCreateFormLoader").classList.add("d-none");
        document.getElementById("orgCreateForm").classList.remove("d-none");
    }

    // Limpia los campos del collapsible de crear organización.
    clearCreateOrg = () => {
        document.getElementById("creaOrgName").value = "";
        document.getElementById("creaOrgAlias").value = "";
        document.getElementById("creaOrgSubtype").value = "1";
        document.getElementById("creaOrgNl").value = "";
        document.getElementById("creaOrgNm").value = "";
        document.getElementById("creaOrgNe").value = "";
        document.getElementById("creaOrgNf").value = "";
        this.clearOrgImg();
        this.setState({color1: Strings.defaultColor1, color2: Strings.defaultColor2, color3: Strings.defaultColor3,
                            orgKeyDateB: undefined, orgKeyDateC: undefined,  orgKeyDateD: undefined, orgKeyDateE: undefined,
                            selectedImg: null, downloadName: null});
    }

    // Descarga el excel con las licencias de la organización.
    downloadOrg = (id) => {
        this.props.showLoaderModal(this.props.strings.downloadingLicenses);
        getAsyncOrgLicenseServer(id).then(async orgAsync => {
            const orgAsyncJson = await orgAsync.json();

            if (!orgAsync.ok){
                const error = orgAsync.status;
                return Promise.reject(error);
            }

            if (orgAsyncJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(orgAsyncJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncDownloadOrgLic(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(orgAsyncJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            this.props.hideLoaderModal();
            console.log(error);
        });
    }

    // Petición asíncrona de descarga de licencias de organización.
    getJobAsyncDownloadOrgLic = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.downloadOrgLic(jobAnswerJson);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.props.hideLoaderModal, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                        });
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncDownloadOrgLic(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        }, timeout);
    }

    // Descarga el excel con las licencias de la organización.
    downloadOrgLic = (orgLicensesJson) => {
        if (orgLicensesJson.hasOwnProperty('data')) {
            let licenses = [];
            for (let i in orgLicensesJson.data) {
                let license = new Key(orgLicensesJson.data[i]);
                licenses.push(license);
            }
            this.downloadOrgLicenses(licenses);
            this.props.hideLoaderModal();
        }
    }

    // Descarga el excel con las licencias de la organización
    downloadOrgLicenses = (licenses) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let dashboardExcelTitle = this.props.strings.licenseDownloadLicensesSheetTitle1 + this.state.downloadName + this.props.strings.excelFormat;
        let dashboardExcel = new ExcelJS.Workbook();

        let worksheet = dashboardExcel.addWorksheet(this.props.strings.licenseDownloadOrgLicensesTitle, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        worksheet.columns = [
            {key: 'id', width: 25},
            {key: 'subtype', width: 25},
            {key: 'key', width: 25},
            {key: 'nseason', width: 25},
            {key: 'username', width: 25},
            {key: 'datetech', width: 25},
            {key: 'datephy', width: 25},
            {key: 'dateana', width: 25},
            {key: 'datestock', width: 25}
        ];
        worksheet.getRow(1).values = [this.props.strings.tableId, this.props.strings.tableLicenseSubtype, this.props.strings.tableKey, this.props.strings.tableNSeason,
            this.props.strings.tableKeyUser, this.props.strings.tablePremiumTech, this.props.strings.tablePremiumPhyto,
            this.props.strings.tablePremiumAnalysis, this.props.strings.tablePremiumStock];
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'cccccc'}
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });

        for (let i in licenses){
            let datephy = "";
            let datetech = "";
            let dateana = "";
            let datestock = "";

            // Licencias de la organización.
            for (let j in licenses[i].premium){
                if (licenses[i].premium[j].idtp === KTP.ID_PHYTOSANITARY_PREMIUM){
                    datephy = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_MANAGER_PREMIUM){
                    datetech = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_ANALYSIS_PREMIUM){
                    dateana = licenses[i].premium[j].enddate.split(" ")[0];
                    continue;
                }
                if (licenses[i].premium[j].idtp === KTP.ID_STOCK_PREMIUM){
                    datestock = licenses[i].premium[j].enddate.split(" ")[0];
                }
            }
            worksheet.addRow([licenses[i].getId(), licenses[i].getSubtype().getName(), licenses[i].getValue(), licenses[i].getNF(),
                (licenses[i].getUser() !== null ? licenses[i].getUser().getVisibleName() : ""), datetech, datephy, dateana, datestock]);
        }

        dashboardExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], {type: this.blobType});
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, dashboardExcelTitle);
        });
    }
}

export default OrganizationManagement;