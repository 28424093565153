import React from 'react';
import ReactDOM from 'react-dom';
// noinspection ES6CheckImport
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./js/ui/pages/login/Login";
import HtmlPage from "./js/ui/pages/HtmlPage";

//const root = ReactDOM.createRoot(document.getElementById("root"));
ReactDOM.render(
    <Router>
        <Route exact path="/">
            <Login />
        </Route>
        <Route path="/dashboard">
            <HtmlPage id={"Dashboard"}/>
        </Route>
        <Route path="/user/userinfo">
            <HtmlPage id={"UserInfo"}/>
        </Route>
        <Route path="/user/usermanagement">
            <HtmlPage id={"UserManagement"}/>
        </Route>
        <Route path="/organization/organizationinfo">
            <HtmlPage id={"OrganizationInfo"}/>
        </Route>
        <Route path="/organization/organizationmanagement">
            <HtmlPage id={"OrganizationManagement"}/>
        </Route>
        <Route path="/license/licensedownload">
            <HtmlPage id={"LicenseDownload"}/>
        </Route>
        <Route path="/message/messageinfo">
            <HtmlPage id={"MessageInfo"}/>
        </Route>
        <Route path="/message/messagemanagement">
            <HtmlPage id={"MessageManagement"}/>
        </Route>
        <Route path="/season/seasonmanagement">
            <HtmlPage id={"SeasonManagement"}/>
        </Route>
        <Route path="/phytosanitary/phytosanitaryinfo">
            <HtmlPage id={"PhytosanitaryInfo"}/>
        </Route>
        <Route path="/phytosanitary/phytosanitarymanagement">
            <HtmlPage id={"PhytosanitaryManagement"}/>
        </Route>
        <Route path="/tool/toolmanagement">
            <HtmlPage id={"ToolManagement"}/>
        </Route>
    </Router>
, document.getElementById("root"));
