import React, {Component} from "react";
import {endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import CustomButton from "../../component/CustomButton";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import {
    getProvinceSeasonList, getTownSeasonList, getJobAsync,
    getJobAsyncResult, getUserListFilter, getUserSeasonPaginated,
    deleteSeasonTeam, getAllSeasonsPaginatedAndFiltered, getRoleList,
    updateSeasonTeam, getAllUserSeasons
} from "../../../logic/functions/ServerPetitions";
import JobAsync from "../../../logic/objects/JobAsync";
import {datetimeToString} from "../../../logic/functions/Basic";
import DashboardTown from "../../../logic/objects/DashboardTown";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import AsyncTypeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import User from "../../../logic/objects/User";
import Season from "../../../logic/objects/Season";
import InfiniteScroll from "react-infinite-scroller";
import {Spinner} from "react-bootstrap";
import SeasonBlock from "../../component/SeasonBlock";
import Role from "../../../logic/objects/Role";
import Control from "../../../logic/objects/Control";

// Clase que contiene la información de la página de administración de temporadas.
class SeasonManagement extends Component{
    // Constructor de clase SeasonManagement.
    // noinspection DuplicatedCode
    constructor(props){
        super(props);
        this.content = '';
        this.contentAllSeasons = '';
        this.contentRoles = '';
        this.contentChangeRole = '';
        this.filteredPetition = null;
        this.state = {showModalConfirmation: false, exitPetition: false, isSeasonManagementLoading: false, seasonManagementOptions: [],
                    page_seasons: 0, page_alls: 0, page_size: 12, page_size_all: 24,
                    allUserSeasons: [], maxSeasonsReached: false, petitionSeasonsEnded: true, removedSeasonId: "",
                    showModalSeasons: false, allSeasonList: [], petitionAllSeasonsEnded: true, maxAllSeasonsReached: false,
                    seasonsFromUser: [], addSeasonsToUser: [], roles: [], showModalRole: false, seasonToChangeRole: undefined};
        this.listaUsuarios = [];
        this.selectedUserMng = "";
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.seasonTitle;
        endPreLoad();
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja la página de gestión de temporadas.
    render = () => {
        if (this.state.petitionSeasonsEnded && this.selectedUserMng !== '') {
            this.content = this.getManageUserSeasonsContent();
        }
        if (this.state.petitionAllSeasonsEnded && this.selectedUserMng !== '') {
            this.contentAllSeasons = this.getAllSeasonsContent();
        }
        if (this.state.roles.length > 0){
            this.contentRoles = this.fillRoleCheck();
        }
        if (this.state.roles.length > 0){
            this.contentChangeRole = this.fillChangeRole();
        }
        let loader = <div className={"row justify-content-center"} key={"loaderdiv"}><Spinner key={"loader"} animation="border" role="status"><span className="sr-only">Loading...</span></Spinner></div>;

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.seasonManagementPageTitle}/>

                <div className="d-none row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por provincias */}
                        <Collapsible triggerId={"collapseDownloadProvinceSeasonCardTrigger"} collapseId={"collapseDownloadProvinceSeasonCard"} cardTitle={this.props.strings.seasonDownloadProvince} >
                            <div id="downloadProvinceSeasonFormLoader" className="form-loader d-none"/>
                            <form id="downloadProvinceSeasonForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.showConfirmationModal(this.props.strings.confirmationModalDownloadTitle, this.props.strings.confirmationModalDownloadBody, this.downloadProvinceSeasonForm2Server)}>
                                                <span className="icon">
                                                  <i className="fas fa-file-excel"/>
                                                </span>
                                                <span className="text">{this.props.strings.downloadButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="d-none row">
                    <div className="col-12">
                        {/* Collapsible de descargar excel por municipios */}
                        <Collapsible triggerId={"collapseDownloadTownSeasonCardTrigger"} collapseId={"collapseDownloadTownSeasonCard"} cardTitle={this.props.strings.seasonDownloadTown} >
                            <div id="downloadTownSeasonFormLoader" className="form-loader d-none"/>
                            <form id="downloadTownSeasonForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.showConfirmationModal(this.props.strings.confirmationModalDownloadTitle, this.props.strings.confirmationModalDownloadBody, this.downloadTownSeasonForm2Server)}>
                                                <span className="icon">
                                                  <i className="fas fa-file-excel"/>
                                                </span>
                                                <span className="text">{this.props.strings.downloadButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de administrar explotaciones de usuario */}
                        <Collapsible triggerId={"collapseManageUserSeasonCardTrigger"} collapseId={"collapseManageUserSeasonCard"} cardTitle={this.props.strings.seasonUserSeasonManagement} >
                            <div id="manageUserSeasonFormLoader" className="form-loader d-none"/>
                            <form id="manageUserSeasonForm">
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.tableUser}</FormLabel>
                                            <AsyncTypeahead id="manageUserSeasonMenu" placeholder={this.props.strings.asyncSearchUserPh} useCache={false} onInputChange={this.resetSeasonManagementOptions}
                                                            ref={(ref) => this._typeaheadUserSeasons = ref}
                                                            isLoading={this.state.isSeasonManagementLoading} minLength={3} onSearch={this.getAutocompleteUserSeasonManagement} onChange={this.seasonManagementFormFill}
                                                            delay={this.props.globals.getAsyncDelay()} filterBy={this.filterFunction} options={this.state.seasonManagementOptions} labelKey="loginname"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{option.loginname}</span>
                                                            )} />
                                        </FormGroup>
                                    </div>
                                    <div id="manageUserSeasonList" className="col-12 d-none">
                                        <div className="col-12 mb-4 d-flex justify-content-center">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.openAddUserSeasonsModal}>
                                                <span className="icon">
                                                  <i className="fas fa-plus"/>
                                                </span>
                                                <span className="text">{this.props.strings.seasonAddUserSeasons}</span>
                                            </CustomButton>
                                            <CustomButton additionalClass={"btn-primary btn-icon-split ml-5"} parentFunction={this.downloadUserSeasons}>
                                                <span className="icon">
                                                  <i className="fas fa-download"/>
                                                </span>
                                                <span className="text">{this.props.strings.seasonDownloadUserSeasons}</span>
                                            </CustomButton>
                                        </div>
                                        <div className="col-12">
                                            <InfiniteScroll
                                                pageStart={this.state.page_seasons}
                                                loadMore={this.getManagementUserSeasonPage}
                                                hasMore={!this.state.maxSeasonsReached}
                                                loader={loader}
                                                threshold={200}>
                                                {this.content}
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                {/* Modal confirmación de descarga */}
                <Modal show={this.state.showModalConfirmation} backdrop="static" onHide={this.resetConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal confirmación de descarga */}

                {/* Modal añadir explotaciones a usuario */}
                <Modal show={this.state.showModalSeasons} onHide={this.hideAddUserSeasonsModal} backdrop="static" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>{this.props.strings.seasonAddUserSeasons}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div id="user_roles" className="ul mb-4">
                            {this.contentRoles}
                        </div>
                        <div className="input-group mb-4">
                            <input id="all_season_filter" type="text" className="form-control" name="search" placeholder="Buscar..." onInput={this.timeOutAllSeasonFiltered} />
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="fa fa-search"/>
                                </span>
                            </div>
                        </div>
                        <ul id="seasonChecklist" className="mb-4">
                            {this.contentAllSeasons}
                        </ul>
                        <div id="all_seasons_loader" className="row justify-content-center mb-4">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <CustomButton id={"load_season_btn"} additionalClass={"btn-primary"} text={this.props.strings.loadMoreButton} parentFunction={this.getAllSeasonPage}/>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.hideAddUserSeasonsModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.confirmAddSeasons}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal añadir explotaciones a usuario */}

                {/* Modal cambiar rol a usuario */}
                <Modal show={this.state.showModalRole} onHide={this.hideChangeUserRoleModal} backdrop="static" centered>
                    <ModalHeader closeButton>
                        <ModalTitle>{this.props.strings.seasonEditRole}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div id="user_change_role" className="ul">
                            {this.contentChangeRole}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.hideChangeUserRoleModal}/>
                        <CustomButton additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.confirmChangeRole}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal cambiar rol a usuario */}
            </div>
        );
    }

    // Abre el modal de confirmación con los argumentos pasados como título, cuerpo y función de retorno.
    showConfirmationModal = (title, content, callback) => {
        this.setState({showModalConfirmation: true});
        // Se obtienen los dos números para la operación de confirmación.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        // Se muestra la información en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            // Se establece el evento onclick del botón de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.confirmDownloadProvinceExcel(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Funcionalidad del botón ok.
    confirmDownloadProvinceExcel = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        // Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        // Se comprueba el resultado y se redirige a la operación o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Oculta el modal de confirmación.
    hideConfirmationModal = () => {
        this.setState({showModalConfirmation: false});
    }

    // Muestra el loader de provincias y oculta su form.
    showProvinceLoader = () => {
        document.getElementById("downloadProvinceSeasonFormLoader").classList.remove("d-none");
        document.getElementById("downloadProvinceSeasonForm").classList.add("d-none");
    }

    // Oculta el loader de provincias y muestra su form.
    hideProvinceLoader = () => {
        document.getElementById("downloadProvinceSeasonFormLoader").classList.add("d-none");
        document.getElementById("downloadProvinceSeasonForm").classList.remove("d-none");
    }

    // Descargar el excel por provincias del servidor.
    downloadProvinceSeasonForm2Server = () => {
        this.showProvinceLoader();
        getProvinceSeasonList().then(async provinceAsync => {
            const provinceAsyncJson = await provinceAsync.json();

            if (!provinceAsync.ok){
                const error = provinceAsync.status;
                return Promise.reject(error);
            }

            if (provinceAsyncJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(provinceAsyncJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncProvinceSeason(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(provinceAsyncJson, this.hideProvinceLoader, this.props.showInfoModal);
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.hideProvinceLoader();
        });
    }

    // Petición asíncrona por provincias.
    getJobAsyncProvinceSeason = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.createProvinceSeasonExcel(jobAnswerJson.data);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideProvinceLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.hideProvinceLoader();
                        });
                        this.hideProvinceLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncProvinceSeason(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideProvinceLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideProvinceLoader();
            });
        }, timeout);
    }

    // Crea el excel descargable de provincias.
    createProvinceSeasonExcel = (body) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let excelProvinceTitle = this.props.strings.seasonProvinceExcelTitle + datetimeToString(new Date())  + this.props.strings.excelFormat;
        let provinceSeasonExcel = new ExcelJS.Workbook();
        // noinspection JSUnresolvedFunction
        provinceSeasonExcel.addWorksheet(this.props.strings.osigrisExcel, { views: [{ activeCell: 'A1', showGridLines: true }] });
        let worksheet = provinceSeasonExcel.getWorksheet(1);
        worksheet.columns = [
            { key: 'province', width: 15 },
            { key: 'sigpac', width: 15 },
            { key: 'crop', width: 15 },
            { key: 'infection', width: 15 },
            { key: 'fertilize', width: 15 },
            { key: 'phytosanitary', width: 15 },
            { key: 'work', width: 15 },
            { key: 'collect', width: 15 },
            { key: 'shepherd', width: 15 },
            { key: 'state', width: 15 },
        ];
        worksheet.getRow(1).values = [this.props.strings.seasonExcelProvinceCode, this.props.strings.seasonExcelNSigpac,
            this.props.strings.seasonExcelNCrop, this.props.strings.seasonExcelNInfection,
            this.props.strings.seasonExcelNFertilize, this.props.strings.seasonExcelNPhyto,
            this.props.strings.seasonExcelNWork, this.props.strings.seasonExcelNCollect,
            this.props.strings.seasonExcelNShepherd, this.props.strings.seasonExcelNState];
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'cccccc' }
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });
        for (let i in body){
            let dashboardProvince = new DashboardTown(body[i]);
            worksheet.addRow([dashboardProvince.province, dashboardProvince.farm, dashboardProvince.infoCropAmount(),
                dashboardProvince.infoInfectionAmount(), dashboardProvince.infoFertilizeAmount(), dashboardProvince.infoPhytosanitaryAmount(),
                dashboardProvince.infoWorkAmount(), dashboardProvince.infoCollectAmount(), dashboardProvince.infoShepherdAmount(),
                dashboardProvince.infoStateAmount()]);
        }
        provinceSeasonExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], { type: this.blobType });
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, excelProvinceTitle);
        });
    }

    // Muestra el loader de municipios y oculta su form.
    showTownLoader = () => {
        document.getElementById("downloadTownSeasonFormLoader").classList.remove("d-none");
        document.getElementById("downloadTownSeasonForm").classList.add("d-none");
    }

    // Oculta el loader de municipios y muestra su form.
    hideTownLoader = () => {
        document.getElementById("downloadTownSeasonFormLoader").classList.add("d-none");
        document.getElementById("downloadTownSeasonForm").classList.remove("d-none");
    }

    // Descargar el excel por municipios del servidor
    downloadTownSeasonForm2Server = () => {
        this.showTownLoader();
        getTownSeasonList().then(async townAsync => {
            const townAsyncJson = await townAsync.json();

            if (!townAsync.ok){
                const error = townAsync.status;
                return Promise.reject(error);
            }

            if (townAsyncJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(townAsyncJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncTownSeason(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(townAsyncJson, this.hideTownLoader, this.props.showInfoModal);
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.hideTownLoader();
        });
    }

    // Petición asíncrona por municipios.
    getJobAsyncTownSeason = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResult(id).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.createTownSeasonExcel(jobAnswerJson.data);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideTownLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.hideTownLoader();
                        });
                        this.hideTownLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncTownSeason(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideTownLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideTownLoader();
            });
        }, timeout);
    }

    // Crea el excel descargable de municipios.
    createTownSeasonExcel = (body) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let excelTownTitle = this.props.strings.seasonTownExcelTitle + datetimeToString(new Date())  + this.props.strings.excelFormat;
        let townSeasonExcel = new ExcelJS.Workbook();
        // noinspection JSUnresolvedFunction
        townSeasonExcel.addWorksheet(this.props.strings.osigrisExcel, { views: [{ activeCell: 'A1', showGridLines: true }] });
        let worksheet = townSeasonExcel.getWorksheet(1);
        worksheet.columns = [
            { key: 'ine', width: 15 },
            { key: 'sigpac', width: 15 },
            { key: 'crop', width: 15 },
            { key: 'infection', width: 15 },
            { key: 'fertilize', width: 15 },
            { key: 'phytosanitary', width: 15 },
            { key: 'work', width: 15 },
            { key: 'collect', width: 15 },
            { key: 'shepherd', width: 15 },
            { key: 'state', width: 15 },
        ];
        worksheet.getRow(1).values = [this.props.strings.seasonExcelINE, this.props.strings.seasonExcelNSigpac,
            this.props.strings.seasonExcelNCrop, this.props.strings.seasonExcelNInfection,
            this.props.strings.seasonExcelNFertilize, this.props.strings.seasonExcelNPhyto,
            this.props.strings.seasonExcelNWork, this.props.strings.seasonExcelNCollect,
            this.props.strings.seasonExcelNShepherd, this.props.strings.seasonExcelNState];
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'cccccc' }
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });
        for (let i in body){
            let dashboardTown = new DashboardTown(body[i]);
            worksheet.addRow([dashboardTown.town, dashboardTown.farm, dashboardTown.infoCropAmount(),
                dashboardTown.infoInfectionAmount(), dashboardTown.infoFertilizeAmount(), dashboardTown.infoPhytosanitaryAmount(),
                dashboardTown.infoWorkAmount(), dashboardTown.infoCollectAmount(), dashboardTown.infoShepherdAmount(),
                dashboardTown.infoStateAmount()]);
        }
        townSeasonExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], { type: this.blobType });
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, excelTownTitle);
        });
    }

    // Realiza la petición de usuarios con el texto introducido en el input de administrar explotaciones de usuario.
    getAutocompleteUserSeasonManagement = (query) => {
        this.selectedUserMng = "";
        this.listaUsuarios = [];
        document.getElementById("manageUserSeasonList").classList.add("d-none");
        this.setState({isSeasonManagementLoading: true, allUserSeasons: [], removedSeasonId: "",
            addSeasonsToUser: [], seasonsFromUser: []});
        getUserListFilter(query, false).then(async userList => {
            const userListJson = await userList.json();

            if (!userList.ok){
                const error = userList.status;
                return Promise.reject(error);
            }

            if (userListJson.hasOwnProperty('data') && userListJson.data.length > 0){
                let options = [];
                this.listaUsuarios = [];
                for (let i in userListJson.data){
                    const user = new User(userListJson.data[i]);
                    this.listaUsuarios.push(user);
                    options.push({id: user.getId(), loginname: user.getLoginname(), email: user.getEmail(),
                        name: user.getName(), surname: user.getSurname()});
                }
                this.setState({seasonManagementOptions: options});
            } else {
                refreshIfNeeded(userListJson, null, this.props.showInfoModal);
            }
            this.setState({isSeasonManagementLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isSeasonManagementLoading: false});
        });
    }

    // Establece el usuario buscado en administrar explotaciones de usuario y muestra el resto de elementos.
    seasonManagementFormFill = (items) => {
        this.selectedUserMng = "";
        for (let i in this.listaUsuarios){
            if (items.length > 0) {
                if (items[0].id === this.listaUsuarios[i].id) {
                    this.selectedUserMng = new User(this.listaUsuarios[i]);
                    break;
                }
            }
        }

        if (this.selectedUserMng !== ""){
            document.getElementById("manageUserSeasonList").classList.add("d-none");
            document.getElementById("manageUserSeasonFormLoader").classList.remove("d-none");
            this.contentAllSeasons = '';
            this.setState({seasonPetitionEnded: false, page_seasons: 0, page_alls: 0, allUserSeasons: []}, () => {
                // Pide las explotaciones del usuario de forma paginada.
                getUserSeasonPaginated(this.selectedUserMng.id.toString(), this.state.page_seasons, this.state.page_size).then(async seasonList => {
                    const seasonListJson = await seasonList.json();

                    let reached = parseInt(seasonList.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(seasonList.headers.get("content-range").split(" ")[2]);
                    this.setState({maxSeasonsReached: reached});

                    if (!seasonList.ok){
                        const error = seasonList.status;
                        return Promise.reject(error);
                    }

                    if (seasonListJson.hasOwnProperty('data')){
                        let newSeasonArray = [];
                        for (let i in seasonListJson.data){
                            newSeasonArray.push(new Season(seasonListJson.data[i]));
                        }
                        let newAllSeason = this.state.allUserSeasons.concat(newSeasonArray);
                        this.setState({allUserSeasons: newAllSeason, seasonPetitionEnded: true});
                    } else {
                        refreshIfNeeded(seasonListJson, null, this.props.showInfoModal);
                    }
                    document.getElementById("manageUserSeasonFormLoader").classList.add("d-none");
                    document.getElementById("manageUserSeasonList").classList.remove("d-none");
                }).catch(error => {
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Resetea las opciones de usuario para editar explotaciones.
    resetSeasonManagementOptions = () => {
        this.setState({seasonManagementOptions: []});
    }

    // Realiza el filtrado de usuarios por loginname, email, nombre o apellidos.
    filterFunction = (item, props) => {
        return item.loginname.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.email.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.name.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.surname.toString().toLowerCase().includes(props.text.toString().toLowerCase())
    }

    // Rellena las temporadas en las que está incluído el usuario.
    getManageUserSeasonsContent = () => {
        let content = [];
        if (this.state.petitionSeasonsEnded){
            for (let i in this.state.allUserSeasons){
                content.push(<SeasonBlock key={this.selectedUserMng.getId() + "-s" + this.state.allUserSeasons[i].getId()}
                              id={this.state.allUserSeasons[i].getId()} btnIcon={"fas fa-trash"} btnTxt={this.props.strings.removeButton}
                              value={this.state.allUserSeasons[i].getName()} btnType={"btn-danger btn-icon-split"} btnF={this.removeUserSeason}
                              yearTxt={this.props.strings.seasonYear} year={this.state.allUserSeasons[i].getYear()}
                              btnIcon2={"fas fa-pencil-alt"} btnTxt2={this.props.strings.seasonEditRole}
                              btnType2={"btn-primary btn-icon-split mr-3"} btnF2={this.openChangeRoleModal} />);
            }
        }
        return content;
    }

    // Carga la siguiente página de temporadas del usuario seleccionado.
    getManagementUserSeasonPage = () => {
        if (this.selectedUserMng !== "" && this.state.seasonPetitionEnded && !this.state.maxSeasonsReached) {
            let page = this.state.page_seasons + 1;
            this.setState({page_seasons: page, seasonPetitionEnded: false}, () => {
                // Pide las explotaciones del usuario de forma paginada.
                getUserSeasonPaginated(this.selectedUserMng.id.toString(), this.state.page_seasons, this.state.page_size).then(async seasonList => {
                    const seasonListJson = await seasonList.json();

                    let reached = parseInt(seasonList.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(seasonList.headers.get("content-range").split(" ")[2]);
                    this.setState({maxSeasonsReached: reached});

                    if (!seasonList.ok) {
                        const error = seasonList.status;
                        return Promise.reject(error);
                    }

                    if (seasonListJson.hasOwnProperty('data')) {
                        let newSeasonArray = [];
                        for (let i in seasonListJson.data) {
                            newSeasonArray.push(new Season(seasonListJson.data[i]));
                        }
                        let newAllSeason = this.state.allUserSeasons.concat(newSeasonArray);
                        this.setState({allUserSeasons: newAllSeason, seasonPetitionEnded: true});
                    } else {
                        refreshIfNeeded(seasonListJson, null, this.props.showInfoModal);
                    }
                }).catch(error => {
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Elimina al usuario seleccionado de la temporada elegida y manda la petición para actualizarlo.
    removeUserSeason = (seasonId) => {
        if (this.selectedUserMng !== "") {
            this.props.showLoaderModal(this.props.strings.removingSeason);
            let team = [];
            let auxDelTeam = [];
            for (let i in this.state.allUserSeasons) {
                if (this.state.allUserSeasons[i].getId() === seasonId) {
                    team = this.state.allUserSeasons[i].getTeam();

                    for (let j in team) {
                        if (team[j].user.getId() === this.selectedUserMng.getId()){
                            auxDelTeam.push(team[j]);
                            break;
                        }
                    }
                }
            }
            let json = {}
            json.data = auxDelTeam;
            this.setState({removedSeasonId: seasonId}, () => {
                deleteSeasonTeam(seasonId, json).then(async response => {
                    const responseJson = await response.json();

                    if (!response.ok) {
                        const error = response.status;
                        return Promise.reject(error);
                    }

                    if (responseJson.hasOwnProperty('data')) {
                        let newAllSeasons = this.state.allUserSeasons;
                        for (let i in newAllSeasons){
                            if (newAllSeasons[i].getId() === this.state.removedSeasonId){
                                newAllSeasons.splice(parseInt(i), 1);
                                break;
                            }
                        }
                        this.setState({allUserSeasons: newAllSeasons, removedSeasonId: ""}, () => {
                            this.props.hideLoaderModal();
                        });
                    } else {
                        refreshIfNeeded(responseJson, this.props.hideLoaderModal, this.props.showInfoModal);
                    }
                }).catch(error => {
                    this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Carga roles si son necesarios y abre el modal de añadir nuevas explotaciones a usuario.
    openAddUserSeasonsModal = () => {
        this.contentAllSeasons = '';
        if (this.state.roles.length <= 0) {
            this.props.showLoaderModal(this.props.strings.loadingRoles);
            getRoleList().then(async roleList => {
                const roleListJson = await roleList.json();

                if (!roleList.ok) {
                    const error = roleList.status;
                    return Promise.reject(error);
                }

                if (roleListJson.hasOwnProperty('data')) {
                    let auxArr = [];
                    for (let i in roleListJson.data) {
                        auxArr.push(new Role(roleListJson.data[i]));
                    }
                    this.setState({roles: auxArr}, () => {
                        this.props.hideLoaderModal();
                        this.getFirstSeasonPage();
                    });
                } else {
                    refreshIfNeeded(roleListJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        } else {
            this.getFirstSeasonPage();
        }
    }

    // Pide la primera página de las temporadas al abrir el modal.
    getFirstSeasonPage = () => {
        setTimeout(() => {
            document.getElementById("all_seasons_loader").classList.remove("d-none");
        }, 100);
        this.setState({petitionAllSeasonsEnded: false, page_alls: 0}, () => {
            getAllSeasonsPaginatedAndFiltered("", this.state.page_alls, this.state.page_size_all).then(async allSeasons => {
                const allSeasonsJson = await allSeasons.json();

                let reached = parseInt(allSeasons.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(allSeasons.headers.get("content-range").split(" ")[2]);
                this.setState({maxAllSeasonsReached: reached});
                if (reached) {
                    document.getElementById("load_season_btn").classList.add("d-none");
                } else {
                    document.getElementById("load_season_btn").classList.remove("d-none");
                }

                if (!allSeasons.ok) {
                    const error = allSeasons.status;
                    return Promise.reject(error);
                }

                if (allSeasonsJson.hasOwnProperty('data')) {
                    let newSeasons = [];
                    let auxSeasonsFromUser = [];
                    for (let i in allSeasonsJson.data) {
                        let auxSeason = new Season(allSeasonsJson.data[i]);
                        newSeasons.push(auxSeason);
                        for (let j in auxSeason.team) {
                            if (auxSeason.team[j].user.getId() === this.selectedUserMng.getId()) {
                                auxSeasonsFromUser.push(auxSeason.getId());
                                break;
                            }
                        }
                    }
                    let allSeasons = this.state.allSeasonList.concat(newSeasons);
                    let newSeasonsFromUser = this.state.seasonsFromUser.concat(auxSeasonsFromUser);
                    this.setState({allSeasonList: allSeasons, seasonsFromUser: newSeasonsFromUser, petitionAllSeasonsEnded: true});
                } else {
                    refreshIfNeeded(allSeasonsJson, null, this.props.showInfoModal);
                }
                document.getElementById("all_seasons_loader").classList.add("d-none");
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
            this.setState({showModalSeasons: true});
        });
    }

    // Rellena la checklist con los posibles roles del usuario.
    fillRoleCheck = () => {
        let content = [];

        let licenseId = (this.selectedUserMng !== "" ? this.selectedUserMng.getKey().getSubtype().getId().toString() : "-1");
        let allowedRoles = [];
        switch(licenseId){
            case '1':
            case '3':
                allowedRoles.push('1');
                break;
            case '2':
            case '6':
                allowedRoles.push('4');
                allowedRoles.push('5');
                break;
            case '4':
                allowedRoles.push('2');
                allowedRoles.push('3');
                break;
            case '5':
                 allowedRoles.push('6');
                break;
            default:
                allowedRoles.push('4');
                allowedRoles.push('5');
                break;
        }

        for (let i in this.state.roles){
            content.push(<div className="form-check" key={"role" + this.state.roles[i].getId()}>
                    <input className="form-check-input" name="role" type="radio" id={"role" + this.state.roles[i].getId()} value={this.state.roles[i].getId()} defaultChecked={(this.state.roles[i].getId() === '5')} disabled={(!allowedRoles.includes(this.state.roles[i].getId().toString()))} />
                    <label className="form-check-label" htmlFor={"role" + this.state.roles[i].getId()}> {this.state.roles[i].getName()} </label>
                </div>);
        }
        return content
    }

    // Oculta el modal de añadir/eliminar explotaciones de usuario.
    hideAddUserSeasonsModal = () => {
        this.setState({addSeasonsToUser: [], seasonsFromUser: [], allSeasonList: [],
            petitionAllSeasonsEnded: true, maxAllSeasonsReached: false, showModalSeasons: false,
            page_alls: 0});
        document.getElementById("load_season_btn").classList.remove("d-none");
    }

    // Rellena la checklist con las temporadas del servidor de forma paginada.
    getAllSeasonsContent = () => {
        let content = [];
        if (this.state.petitionAllSeasonsEnded){
            for (let i in this.state.allSeasonList){
                let seasonId = this.state.allSeasonList[i].getId();
                let checked = false;
                if (this.state.addSeasonsToUser.includes(seasonId) || this.state.seasonsFromUser.includes(seasonId)){
                    checked = true;
                } else {
                    for (let j in this.state.allSeasonList[i].team) {
                        if (this.state.allSeasonList[i].team[j].user.getId() === this.selectedUserMng.getId()) {
                            checked = true;
                            break;
                        }
                    }
                }
                content.push(<li key={this.selectedUserMng.getId() + "cb" + this.state.allSeasonList[i].getId()}>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id={"cb" + seasonId} defaultChecked={checked} disabled={this.state.seasonsFromUser.includes(seasonId)} onClick={() => {this.addSeasonFromUser(seasonId)}} />
                        <label className="form-check-label" htmlFor={"cb" + seasonId}>
                            {this.state.allSeasonList[i].getName() + " (" + this.state.allSeasonList[i].getYear() + ")"}
                        </label>
                    </div>
                </li>);
            }
        }
        return content;
    }

    // Obtiene la siguiente página de todas las temporadas paginadas.
    getAllSeasonPage = () => {
        setTimeout(() => {
            document.getElementById("all_seasons_loader").classList.remove("d-none");
        }, 100);
        if (this.selectedUserMng !== "" && this.state.petitionAllSeasonsEnded && !this.state.maxAllSeasonsReached) {
            let query = document.getElementById("all_season_filter").value;
            let newPage = this.state.page_alls + 1;
            this.setState({page_alls: newPage, petitionAllSeasonsEnded: false}, () => {
                getAllSeasonsPaginatedAndFiltered(query, this.state.page_alls, this.state.page_size_all).then(async allSeasons => {
                    const allSeasonsJson = await allSeasons.json();

                    let reached = parseInt(allSeasons.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(allSeasons.headers.get("content-range").split(" ")[2]);
                    this.setState({maxAllSeasonsReached: reached});
                    if (reached){
                        document.getElementById("load_season_btn").classList.add("d-none");
                    } else {
                        document.getElementById("load_season_btn").classList.remove("d-none");
                    }

                    if (!allSeasons.ok) {
                        const error = allSeasons.status;
                        return Promise.reject(error);
                    }

                    if (allSeasonsJson.hasOwnProperty('data')) {
                        let newSeasons = [];
                        let auxSeasonsFromUser = [];
                        for (let i in allSeasonsJson.data) {
                            let auxSeason = new Season(allSeasonsJson.data[i]);
                            newSeasons.push(auxSeason);
                            for (let j in auxSeason.team) {
                                if (auxSeason.team[j].user.getId() === this.selectedUserMng.getId()) {
                                    auxSeasonsFromUser.push(auxSeason.getId());
                                    break;
                                }
                            }
                        }
                        let allSeasons = this.state.allSeasonList.concat(newSeasons);
                        let newSeasonsFromUser = this.state.seasonsFromUser.concat(auxSeasonsFromUser);
                        this.setState({allSeasonList: allSeasons, seasonsFromUser: newSeasonsFromUser, petitionAllSeasonsEnded: true});
                    } else {
                        refreshIfNeeded(allSeasonsJson, null, this.props.showInfoModal);
                    }
                    document.getElementById("all_seasons_loader").classList.add("d-none");
                }).catch(error => {
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Establece un timeout para la petición de filtrado de explotaciones.
    timeOutAllSeasonFiltered = () => {
        if (this.filteredPetition !== null){
            clearTimeout(this.filteredPetition);
            this.filteredPetition = null;
        }
        this.filteredPetition = setTimeout(() => {
            this.getAllSeasonPageFiltered();
        }, 300);
    }

    // Resetea la página de todas las temporadas filtradas y paginadas y realiza la petición.
    getAllSeasonPageFiltered = () => {
        document.getElementById("all_seasons_loader").classList.remove("d-none");
        this.contentAllSeasons = '';
        if (this.selectedUserMng !== "" && this.state.petitionAllSeasonsEnded) {
            let query = document.getElementById("all_season_filter").value;
            this.setState({page_alls: 0, petitionAllSeasonsEnded: false, allSeasonList: [], maxAllSeasonsReached: false}, () => {
                getAllSeasonsPaginatedAndFiltered(query, this.state.page_alls, this.state.page_size_all).then(async allSeasons => {
                    const allSeasonsJson = await allSeasons.json();

                    let reached = parseInt(allSeasons.headers.get("content-range").split(" ")[1].split("-")[1]) >= parseInt(allSeasons.headers.get("content-range").split(" ")[2]);
                    this.setState({maxAllSeasonsReached: reached});
                    if (reached){
                        document.getElementById("load_season_btn").classList.add("d-none");
                    } else {
                        document.getElementById("load_season_btn").classList.remove("d-none");
                    }

                    if (!allSeasons.ok) {
                        const error = allSeasons.status;
                        return Promise.reject(error);
                    }

                    if (allSeasonsJson.hasOwnProperty('data')) {
                        let newSeasons = [];
                        let auxSeasonsFromUser = [];
                        for (let i in allSeasonsJson.data) {
                             let auxSeason = new Season(allSeasonsJson.data[i]);
                            newSeasons.push(auxSeason);
                            for (let j in auxSeason.team) {
                                if (auxSeason.team[j].user.getId() === this.selectedUserMng.getId()) {
                                    auxSeasonsFromUser.push(auxSeason.getId());
                                    break;
                                }
                            }
                        }
                        this.setState({allSeasonList: newSeasons, seasonsFromUser: auxSeasonsFromUser,  petitionAllSeasonsEnded: true});
                    } else {
                        refreshIfNeeded(allSeasonsJson, null, this.props.showInfoModal);
                    }
                    document.getElementById("all_seasons_loader").classList.add("d-none");
                }).catch(error => {
                    this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                    console.log(error);
                });
            });
        }
    }

    // Añade la explotación al array de explotaciones para añadir a las del usuario.
    addSeasonFromUser = (seasonId) => {
        if (this.state.addSeasonsToUser.includes(seasonId)) {
            let auxArr = this.state.addSeasonsToUser;
            auxArr.splice(auxArr.indexOf(seasonId), 1);
            this.setState({addSeasonsToUser: auxArr});
        } else {
            let auxArr = this.state.addSeasonsToUser;
            auxArr.push(seasonId);
            this.setState({addSeasonsToUser: auxArr});
        }
    }

    // Confirma el añadir o eliminar temporadas de usuario.
    confirmAddSeasons = () => {
        let promises = [];
        let roles = document.getElementsByName("role");
        let role;
        this.setState({showModalSeasons: false}, () => {
            this.props.showLoaderModal(this.props.strings.updatingUserSeasons);
            for (let i in roles){
                if (roles[i].checked){
                    for (let j in this.state.roles){
                        if (this.state.roles[j].getId() === roles[i].value){
                            role = this.state.roles[j];
                            break;
                        }
                    }
                    break;
                }
            }
            for (let i in this.state.addSeasonsToUser){
                let control = [new Control({role: role, idseason: this.state.addSeasonsToUser[i], user: this.selectedUserMng})];
                let json = {};
                json.data = control;
                promises.push(updateSeasonTeam(this.state.addSeasonsToUser[i], json));
            }
            Promise.all(promises).then(() => {
                this.selectedUserMng = '';
                document.getElementById("manageUserSeasonList").classList.add("d-none");
                this._typeaheadUserSeasons.clear();
                this.setState({addSeasonsToUser: [], seasonsFromUser: [], allSeasonList: [],
                    petitionAllSeasonsEnded: true, maxAllSeasonsReached: false, page_alls: 0, page_seasons: 0,
                    allUserSeasons: [], maxSeasonsReached: false}, () => {
                    this.props.hideLoaderModal();
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                });
            }).catch(error => {
                this.props.hideLoaderModal();
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
            });
        });
    }

    // Descarga un excel con la información de explotaciones del usuario seleccionado.
    downloadUserSeasons = () => {
        this.props.showLoaderModal(this.props.strings.downloadingUserSeasons);
        this.setState({seasonPetitionEnded: false, allUserSeasons: []}, () => {
            // Pide las explotaciones del usuario de forma paginada.
            getAllUserSeasons(this.selectedUserMng.id.toString()).then(async seasonList => {
                const seasonListJson = await seasonList.json();

                this.setState({maxSeasonsReached: true});

                if (!seasonList.ok){
                    const error = seasonList.status;
                    return Promise.reject(error);
                }

                if (seasonListJson.hasOwnProperty('data')){
                    let newSeasonArray = [];
                    for (let i in seasonListJson.data){
                        newSeasonArray.push(new Season(seasonListJson.data[i]));
                    }
                    this.setState({allUserSeasons: newSeasonArray, seasonPetitionEnded: true}, () => {
                        this.createUserSeasonExcel(this.state.allUserSeasons);
                    });
                } else {
                    refreshIfNeeded(seasonListJson, null, this.props.showInfoModal);
                }
                document.getElementById("manageUserSeasonFormLoader").classList.add("d-none");
                document.getElementById("manageUserSeasonList").classList.remove("d-none");
            }).catch(error => {
                this.props.hideLoaderModal();
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        });
    }

    // Crea el excel descargable de municipios.
    createUserSeasonExcel = (body) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let excelTownTitle = this.props.strings.seasonUserExcelTitle + this.selectedUserMng.getVisibleName() + " " + datetimeToString(new Date())  + this.props.strings.excelFormat;
        let userSeasonExcel = new ExcelJS.Workbook();
        // noinspection JSUnresolvedFunction
        userSeasonExcel.addWorksheet(this.props.strings.osigrisExcel, { views: [{ activeCell: 'A1', showGridLines: true }] });
        let worksheet = userSeasonExcel.getWorksheet(1);
        worksheet.columns = [
            { key: 'season', width: 35 },
            { key: 'year', width: 20 },
            { key: 'farmid', width: 25 },
        ];
        worksheet.getRow(1).values = [this.props.strings.seasonName, this.props.strings.seasonYear,
            this.props.strings.seasonId];
        ['A1', 'B1', 'C1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'cccccc' }
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });
        let included = [];
        for (let i in body){
            if (!included.includes(body[i].getFarmId())) {
                worksheet.addRow([body[i].getName(), body[i].getYear(), body[i].getFarmId()]);
                included.push(body[i].getFarmId());
            }
        }
        userSeasonExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], { type: this.blobType });
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, excelTownTitle);
        });
        this.props.hideLoaderModal();
    }

    // Rellena la checklist de cambio de rol de usuario.
    fillChangeRole = () => {
        let content = [];
        let roleId = '5';

        let licenseId = (this.selectedUserMng !== "" ? this.selectedUserMng.getKey().getSubtype().getId().toString() : "-1");
        let allowedRoles = [];
        switch(licenseId){
            case '1':
            case '3':
                allowedRoles.push('1');
                break;
            case '2':
            case '6':
                allowedRoles.push('4');
                allowedRoles.push('5');
                break;
            case '4':
                allowedRoles.push('2');
                allowedRoles.push('3');
                break;
            case '5':
                 allowedRoles.push('6');
                break;
            default:
                allowedRoles.push('4');
                allowedRoles.push('5');
                break;
        }

        if (this.state.showModalRole) {
            for (let j in this.state.seasonToChangeRole.getTeam()) {
                if (this.state.seasonToChangeRole.getTeam()[j].getUser().getId() === this.selectedUserMng.getId()) {
                    roleId = this.state.seasonToChangeRole.getTeam()[j].getRole().getId();
                    break;
                }
            }
            for (let i in this.state.roles) {
                content.push(<div className="form-check" key={"changerole" + this.state.roles[i].getId()}>
                    <input className="form-check-input" name="changerole" type="radio"
                           id={"changerole" + this.state.roles[i].getId()} value={this.state.roles[i].getId()}
                           defaultChecked={this.state.roles[i].getId() === roleId} disabled={(!allowedRoles.includes(this.state.roles[i].getId().toString()))} />
                    <label className="form-check-label"
                           htmlFor={"changerole" + this.state.roles[i].getId()}> {this.state.roles[i].getName()} </label>
                </div>);
            }
        }
        return content;
    }

    // Establece la season donde cambiar el rol y abre el modal de roles.
    openChangeRoleModal = (seasonId) => {
        this.props.showLoaderModal(this.props.strings.loaderLoading);
        let auxSeason;
        for (let i in this.state.allUserSeasons){
            if (this.state.allUserSeasons[i].getId() === seasonId){
                auxSeason = new Season(this.state.allUserSeasons[i]);
                break;
            }
        }
        getRoleList().then(async roleList => {
            const roleListJson = await roleList.json();

            if (!roleList.ok) {
                const error = roleList.status;
                return Promise.reject(error);
            }

            if (roleListJson.hasOwnProperty('data')) {
                let auxArr = [];
                for (let i in roleListJson.data) {
                    auxArr.push(new Role(roleListJson.data[i]));
                }
                this.props.hideLoaderModal();
                this.setState({roles: auxArr, showModalRole: true, seasonToChangeRole: auxSeason});
            } else {
                refreshIfNeeded(roleListJson, this.props.hideLoaderModal, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
        });
    }

    // Oculta el modal de cambiar rol de usuario.
    hideChangeUserRoleModal = () => {
        this.setState({showModalRole: false, seasonToChangeRole: undefined});
    }

    // Edita el rol de usuario en la temporada seleccionada.
    confirmChangeRole = () => {
        let roles = document.getElementsByName("changerole");
        let role;
        for (let i in roles) {
            if (roles[i].checked) {
                for (let j in this.state.roles) {
                    if (this.state.roles[j].getId() === roles[i].value) {
                        role = new Role(this.state.roles[j]);
                        break;
                    }
                }
                break;
            }
        }
        this.setState({showModalRole: false}, () => {
            this.props.showLoaderModal(this.props.strings.updatingUserSeasons);
            for (let i in this.state.seasonToChangeRole.getTeam()){
                if (this.state.seasonToChangeRole.getTeam()[i].getUser().getId() === this.selectedUserMng.getId()){
                    this.state.seasonToChangeRole.getTeam()[i].setRole(role);
                    break;
                }
            }

            let json = {};
            json.data = this.state.seasonToChangeRole.getTeam();
            updateSeasonTeam(this.state.seasonToChangeRole.getId(), json).then(async teamAsync => {
                const teamJson = await teamAsync.json();

                if (!teamAsync.ok) {
                    const error = teamAsync.status;
                    return Promise.reject(error);
                }

                if (teamJson.hasOwnProperty('data') && teamJson.data.hasOwnProperty('result') && teamJson.data['result'] === 'ok') {
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                    this.props.hideLoaderModal();
                } else {
                    refreshIfNeeded(teamJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
                this.selectedUserMng = "";
                this._typeaheadUserSeasons.clear();
                document.getElementById("manageUserSeasonList").classList.add("d-none");
                this.setState({seasonToChangeRole: undefined});
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
                this.props.hideLoaderModal();
                this.selectedUserMng = "";
                this._typeaheadUserSeasons.clear();
                document.getElementById("manageUserSeasonList").classList.add("d-none");
            });
        });
    }
}

export default SeasonManagement;